import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { apiurl } from '../../utils/constants';
import { log } from '../../utils/functions';
import { useSelector } from 'react-redux';


export const AddSoliPosi = ({ cent, onUpdatePosi,onClose }) => {

	const { role, mail, iden:iuser } = useSelector((state) => state.user);
	const [posi, setPosi] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
	
	useEffect(() => {
		listPosi();
	}, []);

    const listPosi = async () => {
        try {
            const response = await fetch(apiurl+'queryAux', {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ user:mail, query: `SELECT iden,name FROM sys__posi WHERE iden NOT IN (SELECT posi FROM req__assi WHERE role='soli' AND cent='${cent}')` })
            });
            
            if (response.ok) {
                const data = await response.json();
				const returnData = data.map((posi) => {
					return { value: posi.iden, label: posi.name };
				});
                setPosi(returnData);
            } else {
                console.error('Error al obtener los usuarios');
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    }

    const handleCreate = async () => {

        try{
            const response = await fetch(apiurl+'queryAux', {
                method: 'POST',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ user:mail, query: `INSERT INTO req__assi (cent, role, posi) VALUES ('${cent}', 'soli', '${selectedValue}')` })
            });
			if(response.ok){
				log('mod', 'cent', cent, mail, iuser, `add requester ${selectedValue}`);
				onUpdatePosi();
				onClose([false,0]);
			}
        }catch(error){
            console.log('Error en la solicitud:',error);
        }

    }

    const customStyles = {
		control: (provided) => ({
			...provided,
			fontWeight: '500',
			fontSize: '16px',
		}),
		option: (provided, state) => ({
			...provided,
			color: state.isSelected ? 'white' : 'black',
			fontWeight: '500',
			fontSize: '16px',
		}),
	};

	return (
		<form>
			<orm-modal>
			<dialog open>
			<h2>Seleccione un cargo</h2>
            
				<table>
					<tbody>
					<tr>
						<td>
                        { <Select
								options={posi}
								onChange={(selectedOption) => setSelectedValue(selectedOption.value)}
								value={posi.label}
								styles={customStyles}
							/> }</td>
						</tr>
					</tbody>
					<tfoot>
					<tr>
						<td>
							<input type="button" value="Cancelar" onClick={ () => onClose([false,0])} />
							<input type="button" value="Aceptar" onClick={handleCreate}/>
						</td>
					</tr>
					</tfoot>
				</table>
			</dialog>
			</orm-modal>
		</form>
    )
}

export default AddSoliPosi;
