import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { apiurl } from '../../utils/constants';
import { postData, sendMail, log } from '../../utils/functions';

function DeclSolp({ solp, onClose, onRefresh, totalCost }) {

	const [data, setData] = useState([]);
	const [reas, setReas] = useState('');
	const { iden, name, mail, sapDB } = useSelector((state) => state.user);

	const declineSolp = async () => {
		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ 
					user:mail,
					query: `UPDATE req__soli SET rejected=now(), reason='${reas}', requested=null, requester=null WHERE iden=${solp}`,
				})
			});

			if (response.ok) {
				try {
					log('mod', 'soli', solp, mail, iden, `rejected via web`);
					const response2 = await fetch(apiurl+'queryAux', {
						method: 'POST',
						credentials: 'include',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ 
							user:mail,
							query: `INSERT INTO req__post (note,soli,publ,auth,mess) VALUES (0,${solp},now(),${iden},'${reas}');`,
						})
					});
		
					if (response2.ok) {
						searchApprovers(data[0].code_cent);
					} else {
						console.error('Error declineSolp');
					}
				} catch (error) {
					console.error('Error en la solicitud declineSolp:', error);
				}
			} else {
				console.error('Error declineSolp');
			}
		} catch (error) {
			console.error('Error en la solicitud declineSolp:', error);
		}
	}

	const loadData = async () => {

		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ 
					user:mail,
					table: 'req__soli soli',
					inner: "LEFT JOIN req__cent cent ON soli.cent = cent.code",
					where: ` AND soli.iden = ${solp}`,
					order: '1',
					field: `cent.name name_cent, cent.code code_cent, MD5(CONCAT('BailacORM',iden)) share`
				})
			});

			if (response.ok) {
				const data = await response.json();
				setData(data);
			} else {
				console.error('Error al obtener los CC');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}

	}

	useEffect(() => {
		loadData();
	}, []);

	const searchApprovers = async (cc) => {
		try {
			const response = await fetch(apiurl + 'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ 
					user:mail,
					query: `SELECT CASE WHEN CAST("${totalCost}" AS UNSIGNED INTEGER) < ord1 THEN 1 WHEN CAST("${totalCost}" AS UNSIGNED INTEGER) < ord2 THEN 2 ELSE 3 END AS ord FROM req__tier`
				})
			});

			if (response.ok) {

				const data1 = await response.json();
				const ord = 'ord' + data1[0].ord;

				const response2 = await fetch(apiurl, {
					method: 'POST',
					credentials: 'include',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ 
						user:mail,
						table: 'req__cent cent',
						inner: `LEFT JOIN sys__link link ON cent.${ord}=link.posi LEFT JOIN sys__user user ON link.user=user.iden`,
						where: ` AND cent.code=${cc} AND user.mail IS NOT NULL`,
						order: '1',
						field: `user.mail`
					})
				});

				if (response2.ok) {
					const data2 = await response2.json();
					const to = data2.map((item) => item.mail);
					
					const currentUrl = window.location.origin;
					const mensaje=`Se ha rechazado la solicitud de pedido ${solp} por el siguiente motivo:<br><br>${reas}<br><br>Puede ver la solicitud aquí: ${currentUrl}?sp=${data[0].share}`;
					sendMail(to, `La solicitud de pedido ${solp} ha sido rechazada.`, mensaje)
					onClose();
					onRefresh();
				} else {
					console.error('Error al obtener los CC');
				}
			} else {
				console.error('Error al obtener los CC');
			}

		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	};


	return (
		<form>
			<orm-modal>
				<dialog open>
					<h2>Rechazando solicitud de pedido</h2>
					<table>
						<tbody>
							<tr>
								<td>Razón:</td>
								<td><textarea onChange={ (e) => setReas(e.target.value)  } ></textarea></td>
							</tr>
						</tbody>
					</table>
					<table>
						<tfoot>
							<tr>
								<td></td>
								<td>
									<input type="button" value="Cancelar" onClick={onClose} />
									<input type="button" value="Rechazar" onClick={ declineSolp } />
								</td>
							</tr>
						</tfoot>
					</table>
				</dialog>
			</orm-modal>
		</form>
	);

}

export default DeclSolp;