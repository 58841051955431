import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { apiurl } from '../../utils/constants';
import { log } from '../../utils/functions';
import { useSelector } from 'react-redux';

function CreateCCDialog({ onUpdateCCList,onClose }) {

	const [cod, setCod] = useState('');
	const [name, setName] = useState('');
	const [soli, setSoli] = useState('');
	const [ord1, setOrd1] = useState('');
	const [ord2, setOrd2] = useState('');
	const [ord3, setOrd3] = useState('');
	const [pur1, setPur1] = useState('');
	const [pur2, setPur2] = useState('');
	const [pur3, setPur3] = useState('');
	const [users, setUsers] = useState([]);
	const [posi, setPosi] = useState([]);
	const { mail, iden: iuser } = useSelector((state) => state.user);

	const [errors, setErrors] = useState({ cod: false, name: false, soli: false, ord1: false,ord2: false, ord3: false,pur1: false,pur2: false, pur3: false });
	const [data, setData] = useState([]);

	const listPosi = async () => {

		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail, table: 'sys__posi',order: 'name'})
			});
			
			if (response.ok) {
				const data = await response.json();
				const returnData = data.map((posi) => {
					return { value: posi.iden, label: posi.name };
				});
				setPosi(returnData);
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}

	}

	const handleCreateCC = async () => {
		const newErrors = {};
		const numCod = parseInt(cod, 10);
		if (isNaN(numCod) || numCod <= 0) newErrors.cod = true;
		if (name === '') newErrors.name = true;
		if (soli === '') newErrors.soli = true;
		if (ord1 === '') newErrors.ord1 = true;
		if (ord2 === '') newErrors.ord2 = true;
		if (ord3 === '') newErrors.ord3 = true;
		if (pur1 === '') newErrors.pur1 = true;
		if (pur2 === '') newErrors.pur2 = true;
		if (pur3 === '') newErrors.pur3 = true;
		setErrors(newErrors);

		if (Object.values(newErrors).some(error => error)) {
			return;
		}
		try {
			const response = await fetch(apiurl+'tableINSE', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail,  
					table: 'req__cent', 
					fiels: `code, name, soli, ord1, ord2, ord3, pur1, pur2, pur3`, 
					vals: `'${cod}','${name}','${soli}','${ord1}','${ord2}','${ord3}','${pur1}','${pur2}','${pur3}'`,
					exist: `code='${cod}'`
				}),
			});

			if (response.ok) {
				const responseData = await response.json();
				setData(responseData);
				if (responseData[0].error !== 1) {
					log('add', 'cent', responseData[0].lid, mail, iuser );
					onClose();
					onUpdateCCList(); 
				}else console.log(responseData[0].msg);
			} else {
				throw new Error('Error en la solicitud POST');
			}
		} catch (error) {
			console.error('Error al crear el usuario:', error);
		}
	};

	const UserList = async () => {
		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail, table: 'sys__user',order: 'name'})
			});
			
			if (response.ok) {
				const data = await response.json();
				setUsers(data);
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	};

	const handleChange = (selectedOption) => {
		setOrd1(selectedOption); // Actualiza el estado con la opción seleccionada
	  };

	useEffect(() => {
		UserList();
		listPosi();
	}, []);
	
	const customStyles = {
		control: (provided) => ({
			...provided,
			fontWeight: '500',
			fontSize: '16px',
		}),
		option: (provided, state) => ({
			...provided,
			color: state.isSelected ? 'white' : 'black',
			fontWeight: '500',
			fontSize: '16px',
		}),
	};

	return (
		<form>
			<orm-modal>
			<dialog open>
			<h2>Creando centro de costo</h2>
				<table>
					<thead><tr><td><h3>Identificación</h3></td></tr></thead>
					<tbody>
						<tr>
							<td>Código:</td>
							<td><input type="text" value={cod} onChange={(e) => setCod(e.target.value)} className={errors.cod || data[0]?.error === 1 ? 'error' : ''} /></td>
						</tr>
						<tr>
							<td>Nombre:</td>
							<td><input type="text" value={name} onChange={(e) => setName(e.target.value)} className={errors.name || data[0]?.error === 1 ? 'error' : ''} /></td>
						</tr>
					</tbody>
					<thead><tr><td><h3>Creación Solped</h3></td></tr></thead>
					<tbody>
						<tr>
							<td>Creador:</td>
							<td>{ <Select options={posi} styles={customStyles} onChange={(selectedOption) => setSoli(selectedOption.value)} value={posi.find(posi => posi.value === soli)} /> }</td>
						</tr>
					</tbody>
					<thead><tr><td><h3>Autorización Solped</h3></td></tr></thead>
					<tbody>
						<tr>
							<td>Autorizador nivel 1:</td>
							<td>{ <Select options={posi} styles={customStyles} onChange={(selectedOption) => setOrd1(selectedOption.value)} value={posi.find(posi => posi.value === ord1)} /> }</td>
						</tr>
						<tr>
							<td>Autorizador nivel 2:</td>
							<td>{ <Select options={posi} styles={customStyles} onChange={(selectedOption) => setOrd2(selectedOption.value)} value={posi.find(posi => posi.value === ord2)} /> }</td>
						</tr>
						<tr>
							<td>Autorizador nivel 3:</td>
							<td>{ <Select options={posi} styles={customStyles} onChange={(selectedOption) => setOrd3(selectedOption.value)} value={posi.find(posi => posi.value === ord3)} /> }</td>
						</tr>
					</tbody>
					<thead><tr><td><h3>Autorización O.C.</h3></td></tr></thead>
					<tbody>
						<tr>
							<td>Autorizador nivel 1:</td>
							<td>{ <Select options={posi} styles={customStyles} onChange={(selectedOption) => setPur1(selectedOption.value)} value={posi.find(posi => posi.value === pur1)} /> }</td>
						</tr>
						<tr>
							<td>Autorizador nivel 2:</td>
							<td>{ <Select options={posi} styles={customStyles} onChange={(selectedOption) => setPur2(selectedOption.value)} value={posi.find(posi => posi.value === pur2)} /> }</td>
						</tr>
						<tr>
							<td>Autorizador nivel 3:</td>
							<td>{ <Select options={posi} styles={customStyles} onChange={(selectedOption) => setPur3(selectedOption.value)} value={posi.find(posi => posi.value === pur3)} /> }</td>
						</tr>
					</tbody>
				</table>
				<table>
					<tfoot>
					<tr>
						<td></td>
						<td>
							<input type="button" value="Cancelar" onClick={onClose} />
							<input type="button" value="Aceptar" onClick={handleCreateCC}/>
						</td>
					</tr>
					</tfoot>
				</table>
			</dialog>
			</orm-modal>
		</form>
	);
}

export default CreateCCDialog;