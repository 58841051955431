import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import CreateSolpArtcDialog from './creaSolpArtc';
import CreateSolpNote from './creaSolpNote';
import { format } from 'date-fns';
import CreateSolpComm from './creaSolpComm';
import CreateSolpLink from './creaSolpLink';
import AWS from 'aws-sdk';
import { apiurl } from '../../utils/constants';
import { isService, log } from '../../utils/functions';
import PublSolp from './publSolp';
import AproSolp from './aproSolp';
import DeclSolp from './declSolp';
import ModUserDialog from '../cc/editCC';
import { Tooltip } from 'react-tooltip'


function ListSolpDeta({ onMenuItemClick, iden, docu, filtr }) {

	const [solped, setSolped] = useState([]);
	const [ware, setWare] = useState([]);
	const [store, setStore] = useState({});
	const [loadSAP, setLoadSAP] = useState('');
	const [entry, setEntry] = useState(0);
	const [solpedLink, setSolpedLink] = useState([]);
	const [solpedNote, setSolpedNote] = useState([]);
	const [solpedComm, setSolpedComm] = useState([]);
	const maxCharSolpedComm = 255;
	const [total,setTotal] = useState(0);
	const [missware, setMissware] = useState(0); // Bodegas faltantes
	const [serviceCostZero, setServiceInCero] = useState(0); // Bodegas faltantes
	const { iden: iuser, role, mail, sapDB } = useSelector((state) => state.user);
	const [cent, setCent] = useState({});
	const [userEdit, setUserEdit] = useState(0);
	const [userAprob, setUserAprob] = useState(0); // quien es el aprobador que necesita la solped en el estado actual
	const [userApprLvl, setUserApprLvl] = useState({});
	const [userReqAppr, setUserReqAppr] = useState({ userList: [] }); // aprobador adicional
	const [OCstat, setOCstat] = useState([]);
	const [Itemstat, setItemstat] = useState([]);
	const [Solpedstat, setSolpedstat] = useState([]);
	const [isModDialog, setIsModDialog] = useState(false);
	const [userORD1, setUserORD1] = useState([]);
	const [userORD2, setUserORD2] = useState([]);
	const [userORD3, setUserORD3] = useState([]);
	const [isORD, setIsORD] = useState(0);

	const inputAmou = useRef(null);
	const inputText = useRef(null);
	const inputCost = useRef(null);
	const inputFixe = useRef(null);

	const sapPostQuery = async () => {
		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail, 
					query: `SELECT * FROM req__orde`
				}),
				credentials: 'include',
			});
	
			if (response.ok) {
				const data = await response.json();
				let entryStat={};
				let ocList={};

				data.forEach(arr => {
					entryStat[arr.entr]=( entryStat[arr.entr]==='O' || arr.stat==='O' ? 'Comprando' : 'Comprado');
					if (!ocList[arr.entr])ocList[arr.entr] = {};
					ocList[arr.entr][arr.docu]={ 'DocDate': arr.sent.substring(0, 10), 'DocumentStatus':arr.stat }
				});

				setSolpedstat(entryStat);
				setOCstat(ocList);
				// setLoadSAP('');
			} else {
				console.error('Error sapPostQuery');
				setLoadSAP('No se pudo obtener');
			}
		} catch (error) {
			console.error('Error:', error);
			return null;
		}
	}

	const listWare = async () => {
		try {
			// setLoadSAP('Obteniendo...');
			const response = await fetch(apiurl + 'queryAux', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify({ user:mail, query: `SELECT code FROM inv__ware` }),
			});
	
			if (response.ok) {
				const data = await response.json();
				setWare([{ code: ''}, ...data]);
			} else {
				console.error('Error listWare');
			}
		} catch (error) {
			console.error('Error:', error);
			return null;
		}
	}

	const listItemInStor = async () => {
		try {
			const response = await fetch(apiurl + 'queryAux', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify({ user:mail, query: `SELECT ware,item,hand FROM inv__stor` }),
			});
	
			if (response.ok) {
				const data = await response.json();

                const newStore = {};
				data.forEach((row) => {
                    const { ware, item, hand } = row;

                    if (!newStore[item]) {
                        newStore[item] = {};
                    }

                    newStore[item][ware] = hand;
                });
				setStore(newStore);

			} else {
				console.error('Error listWare');
			}
		} catch (error) {
			console.error('Error:', error);
			return null;
		}
	}

	const onCloneSolp = async () => {

		try{

			const response = await fetch(apiurl+'tableCLONE', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					userMail:mail,
					solp:iden, 
					user:iuser
				})
			});
			if (response.ok) {
				const data = await response.json();
				if(data.lid){
					log('add','soli',data.lid,mail,iuser,`clone solp ${iden} to ${data.lid}`);
					onMenuItemClick('listSolpDeta',data.lid);
				}
			} else {
				console.error('Error al obtener los usuarios');
			}


		}catch(error){
			console.error('Error en la solicitud:', error);
		}
		
	}

	const loadSolpLink = async (iden) => {
		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					user:mail,
					table: 'req__link link',order: 'link.publ',
					inner: `INNER JOIN sys__user user ON link.auth=user.iden`,
					where: `and link.soli=${iden}`,
					field: `link.*, user.name auth_name`})
			});
			if (response.ok) {
				const data = await response.json();
				setSolpedLink(data);
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}

	const loadSolpNote = async (iden) => {
		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					user:mail,
					table: 'req__post',
					order: 'req__post.iden', 
					where: ` and soli=${iden} AND note=1`,
					inner: `INNER JOIN sys__user user ON req__post.auth=user.iden`,
					field: ` req__post.*, user.name auth_name `})
			});
			if (response.ok) {
				const data = await response.json();
				setSolpedNote(data[0]);
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}

	const loadSolpComm = async (iden) => {
		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					user:mail,
					table: 'req__post',
					order: 'req__post.iden', 
					where: ` and soli=${iden} AND note=0`,
					inner: `INNER JOIN sys__user user ON req__post.auth=user.iden`,
					field: ` req__post.*, user.name auth_name `})
			});
			if (response.ok) {
				const data = await response.json();
				setSolpedComm(data);
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}

	const loadSolpDeta = async (iden) => {  
		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					user:mail,
					query: ` SELECT ritem.text, ritem.fixe ,soli.entry, soli.cent, soli.docu ,iitem.code, iitem.name name_item, ritem.amou, iitem.unit, 
							 ritem.ware, store.hand store_hand, iitem.hand item_hand, ritem.cost,
							 ritem.iden ritem_iden,ritem.pend,soli.approved,soli.approver, 
							 CASE
								WHEN TIMESTAMPDIFF(MINUTE, approving, NOW()) > 5 THEN NULL
								ELSE approving
							 END AS approving
							 , soli.requested, soli.requester, cent.name name_cent, 
							 user.name name_user, soli.clos, soli.submitter,
							 (SELECT COUNT(1) FROM req__assi WHERE role='soli' AND cent=soli.cent AND posi IN (SELECT posi FROM sys__link WHERE user=${iuser})) isSoli,
							 (SELECT name FROM sys__user WHERE iden=soli.approver) name_user_approver
							 FROM req__soli soli
							 	LEFT JOIN req__item ritem ON soli.iden=ritem.soli
								LEFT JOIN inv__item iitem ON ritem.item=iitem.code 
								LEFT JOIN inv__stor store ON iitem.code=store.item AND store.ware=iitem.ware
								LEFT JOIN req__cent cent ON cent.code=soli.cent
								LEFT JOIN sys__user user ON soli.requester=user.iden
							 WHERE 1 and soli.iden=${iden}
							 ORDER BY iitem.name`
					})
			});
			
			if (response.ok) {
				const data = await response.json();
				setEntry(data[0]?.entry ??0);
				sapPostQuery();
				setSolped(data);
				setCent({ code: data[0]?.cent, name: data[0]?.name_cent });


				if(data[0]?.entry){
					let newDataItemStat = {};
					data.forEach(row => {
						if(row.entry){
							if (!newDataItemStat[row.entry])newDataItemStat[row.entry] = {};
							newDataItemStat[row.entry][row.code] = row.pend;
						}
					});
					setItemstat(newDataItemStat);
				}
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
		
	}

	const reqAppr = async (del=null) => {
		try {

			let response;

			if(del){
				response = await fetch(apiurl+'queryAux', {
					method: 'POST',credentials: 'include',headers: {'Content-Type': 'application/json'},
					body: JSON.stringify({user:mail,query: `DELETE FROM req__appr WHERE soli=${iden} AND user=${del}`})
				});
			}else{
				response = await fetch(apiurl+'queryAux', {
					method: 'POST',credentials: 'include',headers: {'Content-Type': 'application/json'},
					body: JSON.stringify({user:mail,query: `SELECT user, name FROM req__appr INNER JOIN sys__user ON req__appr.user=sys__user.iden WHERE soli=${iden}`})
				});
			}

			if (response.ok) {
				const data = await response.json();
				let userList = {};
				if(del) log('mod', 'soli', iden, mail, iuser, `del req__appr`);
				else userList = data.map(duser => ({ iden: duser.user, name: duser.name }));
				setUserReqAppr({ userList });
			} else console.error('Error al obtener los req__appr');

		} catch (error) {
			console.error('Error al obtener los req__appr');
		}
	}

	const editUser = async (iden, iuser) => {

		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					user:mail,
					query: `SELECT iden,cent, CONCAT('ord',(SELECT CASE WHEN totalCost < ord1 THEN 1 WHEN totalCost < ord2 THEN 2 ELSE 3 END AS ord FROM req__tier)) ord
							FROM (
							SELECT 
								soli.iden,
								soli.cent,
								SUM(ritem.amou * ritem.cost) AS totalCost
							FROM req__soli soli
							LEFT JOIN req__item ritem ON soli.iden = ritem.soli AND soli.iden=${iden}
							LEFT JOIN inv__item iitem ON ritem.item = iitem.code
							LEFT JOIN inv__stor stor ON ritem.item = stor.item AND stor.ware = iitem.ware
							) as tbl`
				})
			});
			if (response.ok) {

				const data = await response.json();
				const ord = data[0].ord;

				const response2 = await fetch(apiurl+'queryAux', {
					method: 'POST',
					credentials: 'include',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify({
						user:mail,
						query: ` SELECT COUNT(1) as valid FROM req__soli 
									WHERE iden=${iden} 
									AND ( requester=${iuser} 
											OR submitter=${iuser}
											OR approver=${iuser} 
											OR ${iuser} in ( SELECT user FROM sys__link WHERE posi=( SELECT ${ord} FROM req__cent WHERE code=req__soli.cent ) ) 
										)`
					})
				});
				
				if (response2.ok) {
					const data2 = await response2.json();
					setUserEdit(data2[0].valid);
				} else {
					console.error('Error al obtener los CC');
				}
			} else {
				console.error('Error al obtener los CC');
			}

		} catch (error) {
			console.error('Error en la solicitud:', error);
		}

	};

	const aprobUser = async (iden, iuser) => {
		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					user:mail,
					query: `SELECT 
								iden,
								cent, 
								CONCAT('ord',(SELECT CASE WHEN totalCost < ord1 THEN 1 WHEN totalCost < ord2 THEN 2 ELSE 3 END AS ord FROM req__tier)) ord,
								(SELECT user FROM req__appr WHERE soli=${iden} AND user=${iuser}) as appr,
								(SELECT approver FROM req__auth WHERE soli=${iden} AND level=1 limit 1) AS hasLvl1,
								(SELECT approver FROM req__auth WHERE soli=${iden} AND level=2 limit 1) AS hasLvl2,
								(SELECT approver FROM req__auth WHERE soli=${iden} AND level=3 limit 1) AS hasLvl3,
								(SELECT name FROM req__auth INNER JOIN sys__user ON approver=sys__user.iden WHERE soli=${iden} AND level=1 limit 1) AS nameLvl1,
								(SELECT name FROM req__auth INNER JOIN sys__user ON approver=sys__user.iden WHERE soli=${iden} AND level=2 limit 1) AS nameLvl2,
								(SELECT name FROM req__auth INNER JOIN sys__user ON approver=sys__user.iden WHERE soli=${iden} AND level=3 limit 1) AS nameLvl3,
								posi_ord1, user_ord1, posi_ord2, user_ord2, posi_ord3, user_ord3
							FROM (
							SELECT 
								soli.iden,
								soli.cent,
								(SELECT SUM(amou*cost) FROM req__item WHERE soli=${iden}) AS totalCost,
								posiORD1.name posi_ord1, JSON_ARRAYAGG( JSON_OBJECT('name_user', userORD1.name) ) as user_ord1,
								posiORD2.name posi_ord2, JSON_ARRAYAGG( JSON_OBJECT('name_user', userORD2.name) ) as user_ord2,
								posiORD3.name posi_ord3, JSON_ARRAYAGG( JSON_OBJECT('name_user', userORD3.name) ) as user_ord3
							FROM req__soli soli
							LEFT JOIN req__cent cent ON soli.cent = cent.code
							LEFT JOIN sys__posi posiORD1 ON cent.ord1 = posiORD1.iden
							LEFT JOIN sys__link linkORD1 ON linkORD1.posi = posiORD1.iden
							LEFT JOIN sys__user userORD1 ON linkORD1.user = userORD1.iden
							LEFT JOIN sys__posi posiORD2 ON cent.ord2 = posiORD2.iden
							LEFT JOIN sys__link linkORD2 ON linkORD2.posi = posiORD2.iden
							LEFT JOIN sys__user userORD2 ON linkORD2.user = userORD2.iden
							LEFT JOIN sys__posi posiORD3 ON cent.ord3 = posiORD3.iden
							LEFT JOIN sys__link linkORD3 ON linkORD3.posi = posiORD3.iden
							LEFT JOIN sys__user userORD3 ON linkORD3.user = userORD3.iden
							WHERE soli.iden=${iden}
							) as tbl`
							
				})
			});
			
			
			if (response.ok) {
				const data = await response.json();
				setIsORD(data[0].ord);
				setUserORD1([...new Set(JSON.parse(data[0].user_ord1).map(user => user.name_user).filter(name => name !== null))]);
				setUserORD2([...new Set(JSON.parse(data[0].user_ord2).map(user => user.name_user).filter(name => name !== null))]);
				setUserORD3([...new Set(JSON.parse(data[0].user_ord3).map(user => user.name_user).filter(name => name !== null))]);

				setUserApprLvl({ 
					nameLvl1:data[0].nameLvl1, nameLvl2:data[0].nameLvl2, nameLvl3:data[0].nameLvl3,
					posiORD1:data[0].posi_ord1, posiORD2:data[0].posi_ord2, posiORD3:data[0].posi_ord3});

				const response2 = await fetch(apiurl+'queryAux', {
					method: 'POST',
					credentials: 'include',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify({
						user:mail,
						query: `SELECT ${iuser} user,
									(SELECT link.posi FROM sys__link link WHERE link.posi = cent.ord1 AND user = ${iuser}) AS ord1,
									(SELECT link.posi FROM sys__link link WHERE link.posi = cent.ord2 AND user = ${iuser}) AS ord2,
									(SELECT link.posi FROM sys__link link WHERE link.posi = cent.ord3 AND user = ${iuser}) AS ord3
								FROM req__soli soli
								INNER JOIN req__cent cent ON cent.code = soli.cent
								WHERE soli.iden = ${iden};`
					})
				});
				
				if (response2.ok) {
					const data2 = await response2.json();
					let shouldSetUserAprob = false;
					
					for (let i = 0; i < data2.length; i++) {
						if ((!data[0].appr && data2[i]?.user && data2[i].ord1 && !data[0].hasLvl1) 
							|| (data[0].hasLvl1 && !data[0].hasLvl2 && data2[i]?.ord2) 
							|| (data[0].hasLvl2 && !data[0].hasLvl3 && data2[i]?.ord3)){
							shouldSetUserAprob = true;
							break;
						}
					}

					if (!shouldSetUserAprob && iuser === data[0].appr && !data[0].hasLvl1) shouldSetUserAprob = true;
					setUserAprob(shouldSetUserAprob ? iuser : 0);

				} else {
					console.error('Error al obtener los CC');
				}
			} else {
				console.error('Error al obtener los CC');
			}

		} catch (error) {
			console.error('Error en la solicitud:', error);
		}

	};

	useEffect(() => {
		listWare();
		listItemInStor();
		reqAppr();
		loadSolpDeta(iden);
		loadSolpNote(iden);
		loadSolpComm(iden);
		loadSolpLink(iden);
		editUser(iden, iuser);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		aprobUser(iden, iuser);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userReqAppr])
	

	const fCurr = (value) => {
		return new Intl.NumberFormat('es-CL', {
			currency: 'CLP',
		}).format(value);
	};
	
	useEffect(() => {
		let calculatedTotalCost = 0;
		let missing = 0;
		let serviceInZero = 0;
		solped.forEach((solped) => {
		  const itemCost = solped.amou * solped.cost;
		  calculatedTotalCost += itemCost;
		  if(isService(solped.code) && itemCost === 0) serviceInZero++;
		  if(!solped.ware)missing++;
		});
		setTotal(calculatedTotalCost);
		setMissware(missing);
		setServiceInCero(serviceInZero);
		///
		if (solped.some(item => item.isEditing)) {
			inputAmou.current?.focus();
		}
		if (solped.some(item => item.isEditingText)) {
			inputText.current?.focus();
		}
		if (solped.some(item => item.isEditingCost)) {
			inputCost.current?.focus();
		}
		if (solped.some(item => item.isEditingFixe)) {
			inputFixe.current?.focus();
		}
	}, [solped]);
	
	const [isSetIsSolpArtc, setIsSolpArtc] = useState(false);
	const [isSetIsSolpNote, setIsSolpNote] = useState(false);
	const [isSetIsSolpComm, setIsSolpComm] = useState(false);
	const [isSetIsSolpLink, setIsSolpLink] = useState(false);
	const [isSetIsSolpPubl, setIsSolpPubl] = useState(false);
	const [isSetIsSolpApro, setIsSolpApro] = useState(false);
	const [isSetIsSolpDecl, setIsSolpDecl] = useState(false);

	// Activar edición de la cantidad de articulos
	const handleEditQuantity = (article) => {
		const updatedSolped = solped.map((item) => {
		  if (item.ritem_iden === article.ritem_iden) {
			return {
			  ...item,
				isEditing: true,
				editingQuantity: item.amou.toString(), // Inicializa el valor del input con la cantidad actual
			};
		  }
		  return item;
		});
		setSolped(updatedSolped);
	};

	// Activar edición de la cantidad de articulos
	const handleEdit = (article,field) => {
		const updatedSolped = solped.map((item) => {
			if (item.ritem_iden === article.ritem_iden) {
				if(field==='text'){
					return {
						...item,
						isEditingText: true,
						editingText: item.text, // Inicializa el valor del input con la cantidad actual
					};
				}else if(field==='ware'){
					return {
						...item,
						isEditingWare: true,
						editingWare: item.ware, // Inicializa el valor del input con la cantidad actual
					};
				}else if(field==='cost'){
					return {
						...item,
						isEditingCost: true,
						editingCost: item.cost, // Inicializa el valor del input con la cantidad actual
					};
				}else{
					return {
						...item,
						isEditingFixe: true,
						editingFixe: item.fixe, // Inicializa el valor del input con la cantidad actual
					};
				}
			}
			return item;
		});
		setSolped(updatedSolped);
	};

	const handleQuantityChange = (article, newQuantity) => {
		const updatedSolped = solped.map((item) => {
			if (item.ritem_iden === article.ritem_iden) {
				if(!newQuantity){
					if (window.confirm('¿Eliminar este artículo de la solicitud?')){
						deleteReg(article.ritem_iden,'req__item');
						log('mod', 'soli', iden, mail, iuser, `del item ${iden}`);
						return null;
					}
				}else{
					return {  ...item,amou: newQuantity,editingQuantity: newQuantity };
				}
			}
			return item;
		}).filter(item => item !== null);
		setSolped(updatedSolped);
	};


	const handleCostChange = (article, newCost) => {
		const updateCost = solped.map((item) => {
			if (item.ritem_iden === article.ritem_iden) {
				return { ...item, cost: newCost, editingCost: newCost };
			}
			return item;
		});
		setSolped(updateCost);
	}

	const handleWareChange = (article, newWare) =>{
		const updateWare = solped.map((item) => {
			if (item.ritem_iden === article.ritem_iden) {
				updateReg(article.ritem_iden, 'req__item', `ware='${newWare}'`);
				log('mod', 'soli', iden, mail, iuser, `mod ware article:${article.ritem_iden}`);
	  			return { ...item, ware: newWare, editingWare: newWare, isEditingWare: false };
			}
			return item;
		});

		setSolped(updateWare);


	}

	const handleChange = (article, newVal, field) => {
		const updatedSolped = solped.map((item) => {
			if (item.ritem_iden === article.ritem_iden) {
				if(field==='text'){
					return {  ...item,text: newVal,editingText: newVal };
				}else{
					return {  ...item,fixe: newVal,editingFixe: newVal };
				}
			}
			return item;
		});
		setSolped(updatedSolped);
	};
	
	const handleFinishEditQuantity = (article, field='') => {
		const updatedSolped = solped.map((item) => {
		  if (item.ritem_iden === article.ritem_iden) {
			if(field==='text'){
				updateReg(article.ritem_iden, 'req__item', `text='${item.text}'`);
				log('mod', 'soli', iden, mail, iuser, `edit text article:${article.ritem_iden}`);
				return { ...item, isEditingText: false};
			}else if (field==='cost') {
				updateReg(article.ritem_iden, 'req__item', `cost='${item.cost}'`);
				log('mod', 'soli', iden, mail, iuser, `edit cost article:${article.ritem_iden}`);
				return { ...item, isEditingCost: false };
			}else if(field==='fixe'){
				updateReg(article.ritem_iden, 'req__item', `fixe='${item.fixe}'`);
				log('mod', 'soli', iden, mail, iuser, `edit fixe article:${article.ritem_iden}`);
				return { ...item, isEditingFixe: false};
			}else if (field === 'amou') {
				updateReg(article.ritem_iden, 'req__item', `amou=${item.amou}`);
				log('mod', 'soli', iden, mail, iuser, `edit amou article:${article.ritem_iden}`);
				return { ...item, isEditing: false};
			}
		  }
		  return item;
		});
		setSolped(updatedSolped);
	};

	const deleteReg = async (ritem_iden, table) => {
		try {
			const response = await fetch(apiurl+'tableDEL', {
				method: 'POST',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					user:mail, 
					table: table,
					where: `iden=${ritem_iden}`,
				}),
			});
	  
			if (response.ok) {
				// loadSolpDeta(iden);
				loadSolpComm(iden);
				loadSolpLink(iden);
			} else console.error('Error al eliminar el artículo en el servidor');
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	};

	const updateReg = async (ritem_iden, table, fields) => {
		try {
			
			const response = await fetch(apiurl+'tablePUT', {
				method: 'POST',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					user:mail, 
					table: table,
					fields: fields,
					iden: `iden=${ritem_iden}`,
				}),
			});
	  
			if (response.ok) {
			} else console.error('Error al actualizar el artículo en el servidor');
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}

	// Activar edición de la nota
	const handleEditNote = (note) => {
		if (solpedNote.iden === note.iden) {
			solpedNote.isEditing = true;
			setSolpedNote({ ...solpedNote });
		}
	};

	const handleNoteChange = (note, newMessage) => {

		if (solpedNote.iden === note.iden) {
			if (newMessage.length <= maxCharSolpedComm){
				if(!newMessage.trim()){
					if (window.confirm('¿Eliminar esta nota?')){
						log('mod', 'soli', iden, mail, iuser, `del note ${iden}`);
						deleteReg(note.iden,'req__post');
						setSolpedNote(null);
					}
				}else{
					log('mod', 'soli', iden, mail, iuser, `mod note ${iden}`);
					updateReg(note.iden, 'req__post', `publ=now(), note=1 , mess='${newMessage}' `);
					solpedNote.mess = newMessage;
					solpedNote.publ = new Date();
					setSolpedNote({ ...solpedNote });
				}
			}else{
				alert(`SAP no permite más de ${maxCharSolpedComm} caracteres.`);
			}
		}
	}

	const handleNoteBlur = (note) => {
		solpedNote.isEditing = false;
		setSolpedNote({ ...solpedNote });
	};

	const handleEditComment = (comment) => {

		const updatedComments = solpedComm.map((item) => {
			if (item.iden === comment.iden && ( item.auth === iuser || role === '3' )) {
				return {
					...item,
					isEditing: true,
				};
			}
			return item;
		});
		setSolpedComm(updatedComments);
	};

	const handleCommChange = (comment, newComm) => {
		const updatedSolped = solpedComm.map((comm) => {
			if (comm.iden === comment.iden) {
				if(!newComm){
					if (window.confirm('¿Eliminar este comentario?')){
						log('mod', 'soli', iden, mail, iuser, `del mess ${iden}`);
						deleteReg(comm.iden,'req__post');
					}
				}else{
					log('mod', 'soli', iden, mail, iuser, `mod mess ${iden}`);
					updateReg(comm.iden,'req__post', `publ=now(), note=0 , mess='${newComm}' `);
					return {  ...comm,mess: newComm, publ: new Date() };
				}
			}
			return comm;
		});
		setSolpedComm(updatedSolped);
	};

	const handleCommBlur = (comment) => {
		const updatedSolped = solpedComm.map((comm) => {
			if (comm.iden === comment.iden) {
				return { ...comm, isEditing: false };
			}
			return comm;
		});
		setSolpedComm(updatedSolped);
	};

	const handleLinkDel = (link, url)	=> {
		if (window.confirm('¿Eliminar este adjunto?')){
			if (containsS3Domain(url)) {
				deleteFileFromS3(extractPathAfterAmazonaws(url));
			}else {
				deleteReg(link, 'req__link');
			}
			log('mod', 'soli', iden, mail, iuser, `del link ${iden}`);
		}
	};

	const containsS3Domain = (url) => {
		return url.includes('https://bailacorm.s3.sa-east-1.amazonaws.com');
	};

	const deleteFileFromS3 = async (path) => {
		try {
			const response = await fetch(apiurl + 'deleteFileToS3', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					user: mail,
					sapDB: sapDB,
					solp: iden,
					path: path
				})
			});
			if (response.ok) {
				loadSolpLink(iden);
			}
			
		}catch(err){
			console.log(err);
		}
	};

	function extractPathAfterAmazonaws(url) {
		const match = url.match(/amazonaws\.com\/(.*)/);
		if (match && match.length > 1) {
			return match[1];
		}
		return null; // Si no se encuentra la cadena "amazonaws.com/", devuelve null.
	}

	function isAllDetailsDone(solped) {

		if (Array.isArray(solped)){
			return solped.every(detail => (
				Itemstat[detail.entry] &&
				(Itemstat[detail.entry][detail.code] === null || Itemstat[detail.entry][detail.code] === 0)
			));
		}else{
			return false;
		}
	}

	const DelSolp = async() => {
		if (window.confirm('¿Eliminar este borrador?')) {
			try {
				await fetch(apiurl + 'queryAux', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						user: mail,
						query: `DELETE FROM req__post WHERE soli=${iden}`
					}),
				});

				await deleteFileFromS3('');

				await fetch(apiurl + 'queryAux', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						user: mail,
						query: `DELETE FROM req__link WHERE soli=${iden}`
					}),
				});

				await fetch(apiurl + 'queryAux', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						user: mail,
						query: `DELETE FROM req__item WHERE soli=${iden}`
					}),
				});
				
				await fetch(apiurl + 'queryAux', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						user: mail,
						query: `DELETE FROM req__soli WHERE iden=${iden}`
					}),
				});

				log('mod', 'soli', iden, mail, iuser, `del solp ${iden}`);
				onMenuItemClick('listSolp');

			} catch (error) {
				console.log(error);				
			}
		}

	}

	const rejeSolp = async () => {
		if (window.confirm('Se eliminarán las autorizaciones de todos los aprobadores de esta solicitud y volverá al estado “Borrador”.\n¿Cancelar esta solicitud?')) {
			try {
				
				const response = await fetch(apiurl + 'queryAux', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						user: mail,
						query: `DELETE FROM req__auth WHERE soli=${iden};`
					}),
				});
				if(response.ok){

					const response2 = await fetch(apiurl + 'queryAux', {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({
							user: mail,
							query: `UPDATE req__soli SET requester=NULL, requested=NULL WHERE iden=${iden};`
						}),
					})
					if(response2.ok){
						log('mod', 'soli', iden, mail, iuser, `cancelled via web`);
						onMenuItemClick('listSolp');
					}
				}

			} catch (error) {
				
			}
		}
	}

	return (
		<>
		<form onSubmit={(e) => e.preventDefault()}>
			<style jsx="true">{`
				.itemTable .ellipsis{
					max-width: 500px;
				}
				.itemTable tbody td:where(:last-child, :nth-child(3)){
					text-align: right;
				}
			`}</style>

			<main>
				{
					loadSAP && entry ? 
					(<h1 data-icon=''>
						<span> Solicitud de {solped?.[0]?.docu?'compra':'pedido'} <span className={solped?.[0]?.docu?'':'disabled'}>{solped?.[0]?.docu??iden} </span> </span>
						<mark>{ loadSAP }</mark>
					</h1>) : 
						(<h1 className={solped?.[0]?.clos ? '' : isAllDetailsDone(solped) && entry ? 'blu' : (Solpedstat[entry] ? (Solpedstat[entry] === 'Comprando' ? 'yel' : 'gre') : solped?.[0]?.approver ? 'ora' : (solped?.[0]?.requester ? 'red' : '')) } data-icon=''>
						<span>Solicitud de {solped?.[0]?.docu?'compra':'pedido'} <span className={solped?.[0]?.docu?'':'disabled'}>{solped?.[0]?.docu??iden}</span></span>
						<mark>{solped ?.[0]?.clos ? 'Cerrado' : (isAllDetailsDone(solped) && entry ? 'Recibido' : Solpedstat[entry] ? Solpedstat[entry] : solped?.[0]?.approver ? 'Aprobado' : (solped?.[0]?.requester ? 'Solicitado' : 'Ingresado')) }</mark>
						
					</h1>)
				}
				<nav>
					<section>
						<h2><div>Solicitado</div><div>{solped?.[0]?.requested ? format(new Date(solped[0].requested), 'dd/MM/yyyy') : ''}</div></h2>
						{
							solped?.[0]?.requested ? 
							(
								<>
									<fieldset>
										<legend>Solicitante</legend>
										<a href="#" onClick={(e) => e.preventDefault()}>{ solped[0].name_user }</a>
									</fieldset>
									<fieldset>
										<legend>Centro de costos</legend>
										<a href="#" onClick={(e) => e.preventDefault()}>{ solped[0].name_cent }</a>
									</fieldset>
								</>
							):(
								<fieldset>
									<input type="button" value="Publicar solicitud" onClick={() => { setIsSolpPubl(true) }} disabled={cent.code? false : true} />
									<input type="button" value="Eliminar borrador" onClick={() => { DelSolp() }} disabled={cent.code ? false : true} />
								</fieldset>
							)
						}
					</section>
					<section>
						<h2><div>Aprobado</div><div>{solped?.[0]?.approved ? format(new Date(solped[0].approved), 'dd/MM/yyyy') : ''}</div></h2>
							{solped?.[0]?.submitter === iuser && !solped?.[0]?.approver ?
								<>
									<fieldset>
										<legend>Aprobador adicional</legend>
										{
											userReqAppr?.userList[0] ?
												<div className='deletable' onClick={() => { if (window.confirm('Se eliminará este aprobador adicional\n¿Está seguro?')) { reqAppr(userReqAppr?.userList[0].iden) } }}> <a href="#" onClick={(e) => e.preventDefault()}> {userReqAppr?.userList[0].name} </a> </div> :
												<input type="button" value="Agregar aprobador" onClick={() => setIsModDialog(true)} />
										}
									</fieldset>
								</>
								: <></>}
						{
							
							userApprLvl.posiORD1 ? 
							(
								<>
									<fieldset>
										<legend>Aprobadores</legend>
										<table><tbody>
											<tr>
												<td style={{ fontWeight: 'bold' }}>Nivel 1</td>
												<td data-tooltip-id="Tt1" data-tooltip-html={!userApprLvl.nameLvl1 ? userORD1.map(user => `${user}<br />`).join('') : "" } className='ellipsis approver'>
													{ userApprLvl.nameLvl1 ? <a href="#" onClick={(e) => e.preventDefault()}>{userApprLvl.nameLvl1}</a> : userApprLvl.posiORD1}
												</td>
												<td> <figure className={ userApprLvl.nameLvl1 ? 'blu' : '' } >{ !userApprLvl.nameLvl1 ? "" : "" }</figure> </td>
											</tr>
											{ isORD === 'ord2' || isORD === 'ord3' ? 
												(
													<tr>
														<td style={{ fontWeight: 'bold' }}>Nivel 2</td>
														<td data-tooltip-id="Tt2" data-tooltip-html={ userApprLvl.nameLvl1 && !userApprLvl.nameLvl2 && (isORD === 'ord2' || isORD === 'ord3') ? userORD2.map(user => `${user}<br />`).join('') : "" } className={`ellipsis approver ${!userApprLvl.nameLvl1 ? 'disabled' : ''}`}>
															{!userApprLvl.nameLvl1 ? 'Esperando nivel anterior' : (userApprLvl.nameLvl2 ? <a href="#" onClick={(e) => e.preventDefault()}>{userApprLvl.nameLvl2}</a> : userApprLvl.posiORD2) }
														</td>
														<td className={!userApprLvl.nameLvl1 ? 'disabled' : '' }> 
															<figure className={ userApprLvl.nameLvl2 ? 'blu' : '' }>{ !userApprLvl.nameLvl1 ? "" : ( !userApprLvl.nameLvl2 ? "" : "" ) }</figure> 
														</td>
													</tr>
												):(<></>) 
											}
											{ isORD === 'ord3' ?
												(
													<tr>
														<td style={{ fontWeight: 'bold' }}>Nivel 3</td>
														<td data-tooltip-id="Tt3" data-tooltip-html={userApprLvl.nameLvl2 && !userApprLvl.nameLvl3 && (isORD === 'ord3') ? userORD3.map(user => `${user}<br />`).join('') : "" } className={`ellipsis approver ${!userApprLvl.nameLvl2 ? 'disabled' : ''}`}>
															{!userApprLvl.nameLvl2 ? 'Esperando nivel anterior' : (userApprLvl.nameLvl3 ? <a href="#" onClick={(e) => e.preventDefault()}>{userApprLvl.nameLvl3}</a> : userApprLvl.posiORD3)}
														</td>
														<td className={!userApprLvl.nameLvl2 ? 'disabled' : ''}> 
															<figure className={userApprLvl.nameLvl3 ? 'blu' : ''}>{!userApprLvl.nameLvl2 ? "" : (!userApprLvl.nameLvl3 ? "" : "")}</figure> 
														</td>
													</tr>
												): (<></>)
											}
										</tbody></table>
										<Tooltip id="Tt1" />
										<Tooltip id="Tt2" />
										<Tooltip id="Tt3" />
									</fieldset>
								</>
							) : (<></>)
						}{
								
								!solped?.[0]?.approver ? (
								<fieldset>
									{
										userAprob ? (
										<>
												<input type="button" value={solped[0].approving ? 'En proceso de aprobación' : 'Aprobar solicitud'} onClick={() => { setIsSolpApro(true) }}
												disabled={
													solped &&
														solped[0] &&
														solped[0].requested &&
														userAprob && !solped[0].approving ? false : 'disabled'
												} />
											<input type="button" value="Rechazar solicitud" onClick={() => { setIsSolpDecl(true) }}
												disabled={
													solped &&
														solped[0] &&
														solped[0].requested &&
														userAprob ? false : 'disabled'
												} />
										</>
										) : (<></>)
									}
									{
										solped[0]?.requester === iuser ? (<input type="button" value="Cancelar solicitud" onClick={() => rejeSolp() } />) : (<></>)
									}
								</fieldset>
							): (<></>)

						}
						
					</section>
					<section>
						<h2><div>Ordenes de compra</div><div></div></h2>
						<fieldset>
							{

								loadSAP && entry ? (<legend>{loadSAP}</legend>) : (
									OCstat[entry] ? (
										<table>
											<tbody>
												{
												Object.entries(OCstat[entry]).map(([Entry, stat]) => (
													<tr key={Entry}>
														<td> <figure className={stat.DocumentStatus === 'O' ? "red" : "gre"}></figure> OC {Entry} </td>
														<td> { stat.DocDate } </td>
														<td><a href="#" onClick={(e) => e.preventDefault()}><figure onClick={ () => onMenuItemClick('listOcDeta',Entry,iden) }></figure></a></td>
													</tr>
												))
												}
											</tbody>
										</table>
									) : (!OCstat[entry] || Object.keys(OCstat[entry]).length === 0) && ( <input type="button" value="Crear orden de compra" disabled /> )
								)
								
							}
						</fieldset>
					</section>
					<article className='scrollx'>
						{
							solped.length === 0 ? 
							( <p>No hay artículos.</p> ) : 
							(<table className='itemTable'>
								<thead>
									<tr>
										<td>Código</td>
										<td className='ellipsis'>Descripción</td>
										<td>Precio</td>
										<td>Pendiente</td>
										<td>Almacén</td>
										<td>Stock</td>
										<td>Costo</td>
										<td>Texto libre</td>
										<td>Activo fijo</td>
									</tr>
								</thead>
								<tbody>
								{
									solped && solped.map(solped => (
										
										solped.code ? (<tr key={solped.ritem_iden}>
											<td>{solped.code}</td>
											<td className='ellipsis'>{solped.name_item}</td>
											<td className={` ellipsis ${solped.isEditingCost ? 'editing' : (isService(solped.code) && (userAprob || userEdit) && !solped?.approver ? 'editable nofloat' : '')}`}
												onClick={isService(solped.code) && (userAprob || userEdit) ? () => handleEdit(solped, 'cost') : null}>
												{
													solped.isEditingCost
														? (<input ref={inputCost} type="number" value={solped.editingCost ?? ''} onBlur={() => handleFinishEditQuantity(solped, 'cost')} onChange={(e) => handleCostChange(solped, e.target.value)} />)
														: (<> {fCurr(solped.cost)} CLP </>)
												}
											</td>
											{
												solped?.approver ?  
												(
													<td className={ !solped.pend ? 'done' : ''}>
														{solped.pend+'/'+solped.amou}
													</td>
												):
													(<td className={` ${ solped.isEditing ? 'editing' : ((userAprob||userEdit) ? 'editable nofloat' : '') } `} onClick={(userAprob||userEdit) ? () => handleEditQuantity(solped) : null}>
													{
														solped.isEditing 
														? (<input ref={inputAmou} type="search" value={solped.editingQuantity} onBlur={() => handleFinishEditQuantity(solped,'amou')} onChange={(e) => handleQuantityChange(solped, e.target.value) } /> ) 
														: (<>{solped.amou}</>)
													}
													</td>
												)
											
											}
											<td className={` ellipsis ${solped.isEditingWare ? 'editing' : (( userAprob||userEdit) && !solped?.approver ? 'editable nofloat' : '')}`} onClick={(userAprob||userEdit) ? () => handleEdit(solped,'ware') : null}>
											
												{ solped.isEditingWare
												  ? (<select onBlur={() => handleFinishEditQuantity(solped,'ware')}
												  	onChange={(e) => handleWareChange(solped, e.target.value)} value={solped.ware} >
														{ware.map((item) => (
															<option key={item.code} value={item.code}>
															{item.code}
															</option>
														))}
													</select>)
												  : solped.ware
												}
												{
													solped.ware?
													<span className={ solped.item_hand < solped.amou? 'red': solped.hand < solped.amou? 'yel':'' }> {'('+(store?.[solped.code]?.[solped.ware]??0)+')'}</span>
													: ''
												}
											</td>
											<td>
												<span className={ solped.item_hand < solped.amou? 'red':'' } > {solped.item_hand} {solped.item_hand < solped.amou ? `(-${solped.amou - solped.item_hand})` : ''}</span>
											</td>

											<td>{ (<> {fCurr(solped.amou * solped.cost)} CLP </>) }</td>

											<td className={` ellipsis ${solped.isEditingText ? 'editing' : ((userAprob||userEdit) ? 'editable nofloat' : '')}`} onClick={(userAprob||userEdit) ? () => handleEdit(solped,'text') : null}>
											{
												solped.isEditingText
												? (<input ref={inputText} type="search" value={solped.editingText??''} onBlur={() => handleFinishEditQuantity(solped,'text')} onChange={(e) => handleChange(solped, e.target.value, 'text') } /> ) 
												: (<> {solped.text} </>)
											}
											</td>
											<td className={` ellipsis ${solped.isEditingFixe ? 'editing' : ((userAprob||userEdit) ? 'editable nofloat' : '')}`} onClick={(userAprob||userEdit) ? () => handleEdit(solped,'fixe') : null}>
											{
												solped.isEditingFixe
												? (<input ref={inputFixe} type="search" value={solped.editingFixe??''} onBlur={() => handleFinishEditQuantity(solped,'fixe')} onChange={(e) => handleChange(solped, e.target.value, 'fixe') } /> ) 
												: (<> {solped.fixe} </>)
											}
											</td>
										</tr>) : (<></>)
									) ) 
								}
								
								</tbody>
							</table>)
						}
					</article>
					<h2>
							{(userAprob||userEdit) && !solped?.[0]?.approver ? (<input type="button" onClick={() => setIsSolpArtc(true)} value="Agregar artículo" />) : (<div></div>) }
						<div>{fCurr(total)} CLP</div>
					</h2>
					<section>
							<h2>Comentarios {`${solpedNote?.mess && solpedNote.isEditing ? `(${solpedNote.mess.length}/${maxCharSolpedComm})` : ''}`}</h2>
						<fieldset>
						{solpedNote ? 
							(
								solpedNote.isEditing ? (
									<textarea value={solpedNote.mess} onChange={(e) => handleNoteChange(solpedNote, e.target.value)} onBlur={() => handleNoteBlur(solpedNote)} />
								) : (
									<><blockquote className={` ${(userAprob||userEdit)? 'editable' : null}`} onClick={(userAprob||userEdit)? () => handleEditNote(solpedNote) : null} >{ solpedNote.mess }</blockquote>
										<footer>
											Por <cite>{ solpedNote.auth_name }</cite>
											<dt>{ solpedNote.publ?format(new Date(solpedNote.publ), 'dd/MM/yyyy HH:mm'):'' }</dt>
										</footer>
									</>
								)
									) : (userAprob||userEdit)? (<input type="button" onClick={() => setIsSolpNote(true)} value="Agregar comentario" /> ) : null
						}
						</fieldset>
					</section>
					<section>
						<h2>Conversación interna</h2>
						<fieldset>
						{solpedComm.map((comment) => (
							<div key={comment.iden} className="comment">
								{   comment.isEditing ?( 
										<textarea value={comment.mess} onChange={(e) => handleCommChange(comment, e.target.value)} onBlur={() => handleCommBlur(comment)} /> ) 
									: (
									<>
										<blockquote className={ comment.auth===iuser ? 'editable':'' } onClick={ comment.auth===iuser ? () => handleEditComment(comment): null }>{comment.mess}</blockquote>
										<footer> Por <cite>{comment.auth_name}</cite> <dt>{comment.publ ? format(new Date(comment.publ), 'dd/MM/yyyy HH:mm') : ''}</dt> </footer>
									</>)
									
								}
							</div>
						))}
								{(userAprob||userEdit)?(<input type="button" onClick={() => setIsSolpComm(true)} value="Agregar mensaje" />):null }
						</fieldset>
					</section>
					<section>
						<h2>Adjuntos</h2>
						<fieldset>
						{
							solpedLink.map((link) => (
								<div key={link.iden}>  
									<blockquote>
										<a href={link.file} target="_blank" rel="noreferrer">{link.file.substring(link.file.lastIndexOf('/', link.file.length - 2) + 1).replace(/\/$/, '') }</a>
										<span className={(userAprob||userEdit)? 'deletable' : ''} onClick={(userAprob||userEdit)? () => handleLinkDel(link.iden, link.file) : null}></span>
									</blockquote>
									<footer>
										Por <cite>{link.auth_name}</cite>
										<dt>{ format(new Date(link.publ), 'dd/MM/yyyy HH:mm') }</dt>
									</footer>
								</div> ))

						}
								{(userAprob||userEdit)?(<input type="button" value="Agregar adjunto" onClick={ ()=> setIsSolpLink(true)} />):null }
						</fieldset>
					</section>
				</nav>
			</main>
			<aside>
				<article>
					<h2>Acciones</h2>
					<fieldset>
						<input type="button" onClick={ () => onMenuItemClick('docuSolp',iden) } value="Ver documento" />
						<input type="button" onClick={ () => onCloneSolp() } value="Duplicar solicitud" disabled={userAprob || (solped[0] && solped[0].isSoli) ? false : 'disabled'}/>
						<input type="button" onClick={() => onMenuItemClick('listSolp', null, null, filtr) } value="Volver a solicitudes" />
					</fieldset>
				</article>
			</aside>
			</form>
			{isSetIsSolpArtc && <CreateSolpArtcDialog iden={iden} cent={cent.code} onLoadSolpDeta={loadSolpDeta} onClose={() => setIsSolpArtc(false)} /> }
			{isSetIsSolpNote && <CreateSolpNote solp={iden} onLoadSolpNote={loadSolpNote} onClose={() => setIsSolpNote(false)} /> }
			{isSetIsSolpComm && <CreateSolpComm solp={iden} onLoadSolpComm={loadSolpComm} onClose={() => setIsSolpComm(false)} /> }
			{isSetIsSolpLink && <CreateSolpLink solp={iden} onLoadSolpLink={ loadSolpLink } onClose={() => setIsSolpLink(false)} /> }
			{isSetIsSolpPubl && <PublSolp solp={iden} onClose={() => setIsSolpPubl(false)} onRefresh={() => onMenuItemClick('listSolp')} totalCost={fCurr(total)} cent={cent} missware={missware} serviceCostZero={serviceCostZero} /> }
			{isSetIsSolpApro && <AproSolp solp={iden} onClose={() => setIsSolpApro(false)} onRefresh={() => onMenuItemClick('listSolp')} totalCost={total} cent={cent} missware={missware} serviceCostZero={serviceCostZero} /> }
			{isSetIsSolpDecl && <DeclSolp solp={iden} onClose={() => setIsSolpDecl(false)} onRefresh={() => onMenuItemClick('listSolp') } totalCost={fCurr(total)} /> }
			{isModDialog && <ModUserDialog solp={iden} onUpdateCCList={null} onEditCC={null} onClose={() => setIsModDialog(false)} onListUser={userReqAppr} reloadSolp={reqAppr} /> }

		   
			</>
		);

}

export default ListSolpDeta;