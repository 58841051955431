import React, { useEffect, useState } from 'react';
import { apiurl } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';

function DocuSolp({ onMenuItemClick, iden }) {

	const [solp, setSolp] = useState('');
	const [docu, setDocu] = useState('');
	const { mail,comp  } = useSelector((state) => state.user);

	const getSolp = async () => {  

		const currentUrl = window.location.origin; 
	
		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					user:mail,
					query: `SELECT MD5(CONCAT('BailacORM',iden)) share, entry, docu FROM req__soli WHERE iden = ${iden}`
				})
			});
			if (response.ok) {
				const data = await response.json();
				setSolp(`${currentUrl}?comp=${comp}&dsp=${data[0].share}`);
				setDocu(data[0].docu);
			}
		} catch (error) {
			console.log(error);
		}
	
	}

	const handlePrint = () => {
		const iframe = document.getElementById('iframe_solp');
		iframe.contentWindow.focus();
		iframe.contentWindow.print();
	}

	const handlePDF = () => {
	   
	};
	
	const copylink=(link)=>{
		navigator.clipboard.writeText(`${link}`);
		document.getElementById('copyLabel').innerHTML = 'Enlace copiado';
		setTimeout(() => {
			document.getElementById('copyLabel').innerHTML = 'Copiar enlace';
		}, 2000);
	}

	useEffect(() => {
		getSolp();
	}, [] );

	return(
		<form>
			<main>
				<h1 data-icon=''>Solicitud de {docu?'compra':'pedido'} <span className={docu?'':'disabled'}>{docu??iden}</span></h1>
				<nav>
					<article>
						<iframe name='iframe_solp' id='iframe_solp' src={`${solp}`} title='' allowFullScreen />
					</article>
				</nav>
			</main>
			<aside>
				<article>
					<h2>Acciones</h2>
					<fieldset>
						<input type="button" onClick={ () => onMenuItemClick('listSolpDeta',iden) } value="Ver datos" />
						<div onClick={() =>copylink(solp)} >
							<button onClick={ (e)=>e.preventDefault() }></button> <label id='copyLabel'>Copiar enlace</label>
						</div>
						<div onClick={ ()=> window.open(solp, '_blank') }>
							<button onClick={ (e)=>e.preventDefault() }></button> <label>Abrir en pestaña</label>
						</div>
						{/* <div onClick={handlePDF}>
							<button onClick={(e) => e.preventDefault()}></button> <label>Exportar a PDF</label>
						</div> */}
						<div onClick={handlePrint}>
							<button onClick={ (e)=>e.preventDefault() }></button> <label>Imprimir</label>
						</div>
						<input type="button" onClick={ () => onMenuItemClick('listSolp') } value="Volver a solicitudes" />
					</fieldset>
				</article>
			</aside>
		</form>
	);

}
export default DocuSolp;