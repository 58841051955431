import React, { useEffect, useState } from 'react';
import { apiurl } from '../../utils/constants';
import { log } from '../../utils/functions';
import { useSelector } from 'react-redux';

function ModUserDialog({ onUpdateCCList,onEditCC,onClose, onListUser=null, onSetListUser=null, idenPosi=null, onSetOneUser=null, solp=null, reloadSolp=null }) {

	const [users, setUsers] = useState([]);
	const [editUserId, setEditUserId] = useState(null);
	const [filterName, setFilterName] = useState('');
	const [errors, setErrors] = useState({ UserId: false });
	const { mail, iden: iuser } = useSelector((state) => state.user);

	const UserList = async () => {
		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({user:mail ,table: 'sys__user',order: 'mail'})
			});
			
			if (response.ok) {
				const data = await response.json();

				const usersWithRole = data.map(user => {
					let roleText, colorCls = '';
					switch (user.role) {
						case -1:
							roleText = 'Inactivo';
							colorCls = '';
							break;
						case 0:
							roleText = 'Pendiente';
							colorCls = 'blu';
							break;
						case 1:
							roleText = 'Usuario';
							colorCls = 'gre';
							break;
						case 2:
							roleText = 'Reportador';
							colorCls = 'yel';
							break;
						case 3:
							roleText = 'Admin';
							colorCls = 'red';
							break;
					}
					return { ...user,roleText: roleText, colorCls: colorCls };
				});

				setUsers(usersWithRole); // Actualizar el estado con los datos recibidos
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	};

	useEffect(() => {
		UserList();
	}, []);

	
	const handleCreateUser = async () => {

		if(solp){
			try {
				const response = await fetch(apiurl+'queryAux', {
					method: 'POST',credentials: 'include',headers: {'Content-Type': 'application/json'},
					body: JSON.stringify({user:mail,query: `INSERT INTO req__appr VALUES (${solp},${editUserId})`})
				});

				if (response.ok) {
					log('mod', 'soli', solp, mail, iuser, `add req__appr ${editUserId}`);
					reloadSolp();
					onClose();
				} else {
					console.error('Error al insertar usuario');
				}
			
			} catch (error) {
				console.error('Error al insertar usuario: ', error);
			}
		}
		else if(editUserId){
			if(onSetOneUser){
				const user = users.find(user => user.iden === editUserId);
				onSetOneUser({
					iden_user: user.iden,
					name_user: user.name,
				});
				onClose();
			}else if(onSetListUser){
				const user = users.find(user => user.iden === editUserId);
				if (idenPosi && user.iden) insertUserInPosi(idenPosi, user.iden);
				onSetListUser((prevUserData) => ({
					...prevUserData,
					userList: [...prevUserData.userList, user],
				}));
				onClose();
			}else{
			
				const   newErrors = {};
				if (editUserId === null) newErrors.UserId = true;

				setErrors(newErrors);

				if (Object.values(newErrors).some(error => error)) {
					return;
				}

				try {
					const response = await fetch(apiurl+'tablePUT', {
						method: 'POST',
						credentials: 'include',
						headers: {'Content-Type': 'application/json'},
						body: JSON.stringify({user:mail ,table: 'req__cent', fields:`${onEditCC.field}='${editUserId}'`, iden: ` code='${onEditCC.cc}'`})
					});
					
					if (response.ok) {
						const responseData = await response.json();
						if(!responseData.error){
							onClose();
							onUpdateCCList(); 
						}
					} else {
						console.error('Error al obtener los usuarios');
					}
				} catch (error) {
					console.error('Error en la solicitud:', error);
				}
			}
		}else{
			alert('Por favor, seleccione un usuario.');
		}
		
	}
	
	const insertUserInPosi = async (posi,user) => {

		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',credentials: 'include',headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail,query: `INSERT INTO sys__link VALUES (${posi},${user})`})
			});

			if (response.ok) {
				log('add', 'posi', posi, mail, iuser, `add ${user}`);
				return true;
			}
		} catch (error) {
			console.error('Error al insertar usuario: ', error);
			return false;
		}
	}

	const filteredUsers = users.filter(user => {
		const nameMatches = user.name.toLowerCase().includes(filterName.toLowerCase()) || user.mail.toLowerCase().includes(filterName.toLowerCase());
		const notInOnListUser = !onListUser || !Array.isArray(onListUser.userList) || !onListUser.userList.some(existingUser => existingUser.iden === user.iden);
		return nameMatches && notInOnListUser;
	});

	return (
		<form>
			<orm-modal>
			<dialog open>
				<h2>Seleccione un usuario</h2>
				<table>
					<tbody>
					<tr>
						<td>Filtro:</td>
						<td><input type="search" placeholder="Nombre o email" value={filterName} onChange={e => setFilterName(e.target.value)} /></td>
					</tr>
					</tbody>
				</table>
				<div className="scroll">
				<table>
					<tbody>
				{filteredUsers.map((user) => (
					<tr key={user.iden} className={editUserId === user.iden ? 'selected' : ''} onClick={() => setEditUserId(user.iden)}>
						<td>
							<figure className='avatar' style={{ backgroundImage: `url(${user.avat})` }}></figure>
						</td>
						<td>
							<div>{user.name}</div>
							<a>{user.mail}</a>
						</td>
						<td className={`state ${user.colorCls}`}>
							<mark>{user.roleText}</mark>
						</td>
					</tr>
				))}
					</tbody>
				</table>
				</div>
				<table>
					<tfoot>
					<tr>
						<td></td>
						<td>
							<input type="button" value="Cancelar" onClick={onClose} />
							<input type="button" value="Aceptar" onClick={handleCreateUser}/>
						</td>
					</tr>
					</tfoot>
				</table>
			</dialog>
			</orm-modal>
		</form>
	);

}

export default ModUserDialog;