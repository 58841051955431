import React, { useEffect,useState } from 'react';
import ModUserDialog from '../cc/editCC';
import { apiurl } from '../../utils/constants';
import { log } from '../../utils/functions';
import { useSelector } from 'react-redux';

function ModPosi({ iden, onListPosi,onClose }) {

	const [user, setUser] = useState({ userList: [] });
	const [userDB, setUserDB] = useState({ userList: [] });
	const [name, setName] = useState('');
	const [errors, setErrors] = useState({ name: false });
	const [listUserDialog,setListUserDialog] = useState(false);
	const { iden: iuser,mail } = useSelector((state) => state.user);

	const handlerDel = async (userId) => {
		const updatedUser = { ...user };
		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					query: `DELETE FROM sys__link WHERE posi=${iden} AND user=${userId}`,
					user:mail
				})
			})

			if (response.ok) {
				updatedUser.userList = updatedUser.userList.filter((u) => u.iden !== userId);
				log('del', 'posi', iden, mail, iuser, `del ${userId}`);
				setUser(updatedUser);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const ListPosi = async () => {
		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					table: `sys__posi posi`,
					inner: `LEFT JOIN sys__link link ON link.posi=posi.iden LEFT JOIN sys__user user ON user.iden=link.user`,
					field: `posi.iden iden_posi, posi.name name_posi,
							JSON_ARRAYAGG( JSON_OBJECT( 'iden_user', user.iden , 'name_user', user.name, 'avat_user', user.avat, 'mail_user', user.mail ) ) AS details`,
					where: ` AND posi.iden=${iden}`,
					group: "GROUP BY posi.iden, posi.name",
					order: ` posi.name ASC`, user:mail
				})
			});
			
			if (response.ok) {
				const data = await response.json();
				const result = data.map(row => ({
				  iden_posi: row.iden_posi,
				  name_posi: row.name_posi,
				  details: JSON.parse(row.details),
				}));

				setName(result[0].name_posi);
		  
				// Mapear los detalles y crear userList
				const userList = result.map(posi => posi.details.map(detail => ({
				  iden: detail.iden_user,
				  name: detail.name_user,
				  mail: detail.mail_user,
				  avat: detail.avat_user,
				}))).flat();
		  
				setUser({ userList });
				setUserDB({ userList });
		  
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}

	}

	const onCreatePosi = async () => {  

		const newErrors = {};
		if (name === '') newErrors.name = true;  
		setErrors(newErrors);

		if (Object.values(newErrors).some(error => error)) {
			return;
		}

		try{
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ 
					query: ` SELECT COUNT(1) as existe FROM sys__posi WHERE iden<>${iden} AND name='${name}' `, 
					user:mail
				}),
			});

			if (response.ok) {
				const data = await response.json();
				if(!data[0].existe){
					const response1 = await fetch(apiurl+'tablePUT', {
						method: 'POST',
						credentials: 'include',
						headers: {'Content-Type': 'application/json'},
						body: JSON.stringify({ 
							table: 'sys__posi', 
							fields: `name='${name}'`, 
							iden: `iden=${iden}`, 
							user:mail
						}),
					});

					if(response1.ok){
						// const data1 = await response1.json();
						log('mod', 'posi', iden, mail, iuser, `name=${name}`);
						onListPosi();
						onClose();
					}
						
				}else{
					const newErrors = {};
					newErrors.name = true;  
					setErrors(newErrors);
				}
			}
			
		} catch (error) {
			console.error('Error al crear el cargo:', error);
		}

	}

	useEffect(() => {
		ListPosi();
	}, []);
	

	return (
		<>
		<form>
			<orm-modal>
			<dialog open>
				<h2>Editando cargo</h2>
				<table>
					<tbody>
						<tr>
							<td>Título:</td>
							<td><input type="text" value={name} onChange={(e) => setName(e.target.value)} className={errors.name ? 'error' : ''}/></td>
						</tr>
					</tbody>
				</table>
				<fieldset>
					<div>Usuarios:</div>
					<div className="scroll">
						<table>
							<tbody>
							{
								user.userList.map((user) => (
									user.iden && (<tr key={user.iden} onClick={() => { if (window.confirm("¿Eliminar este usuario del cargo?")) { handlerDel(user.iden);} } }>
										<td>
											<figure className='avatar' style={{ backgroundImage: `url(${user.avat})` }}></figure>
										</td>
										<td>
											<div className="deletable" >{user.name}</div>
											<a>{user.mail}</a>
										</td>
									</tr>)
								))
							}
							</tbody>
						</table>
					</div>
					<input type="button" value="Agregar usuarios" onClick={ ()=>setListUserDialog(true) } />
				</fieldset>
				<table>
					<tfoot>
						<tr>
							<td colSpan="2">
								<input type="button" value="Cancelar" onClick={onClose} />
								<input type="button" value="Aceptar" onClick={onCreatePosi} />
							</td>
						</tr>
					</tfoot>
				</table>
				
			</dialog>
			</orm-modal>
		</form>
			{listUserDialog && <ModUserDialog onUpdateCCList={null} onEditCC={null} onClose={() => setListUserDialog(false)} onListUser={user} idenPosi={iden} onSetListUser={setUser} /> }
		</>
	);

}

export default ModPosi;