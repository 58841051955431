import React, { useEffect, useState } from 'react';
import Paginator from '../paginator';
import { useSelector } from 'react-redux';
import CreaPosi from './creaPosi';
import ModPosi from './modPosi';
import { apiurl } from '../../utils/constants';
import ModUserDialog from '../cc/editCC';

function ListPosi() {
	const [posi, setPosi] = useState([]);
	const [asig, setAsig] = useState([]);
	const [user,setUser] = useState({ iden_user: null, name_user: '' });
	const [listCC, setListCC] = useState([]);

	const [isCreaDialog, setIsCreaDialog] = useState(false);
	const [isModDialog, setIsModDialog] = useState(false);
	const [editPosi, setEditPosi] = useState(0);

	const [filterYo, setFilterYo] = useState(false);
	const [filterTitle, setFilterTitle] = useState('');
	const [filterCC, setFilterCC] = useState('');
	const [listUserDialog,setListUserDialog] = useState(false);

	const { mail, role } = useSelector((state) => state.user);
	
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const [order, setOrder] = useState('asc'); // Puede ser 'asc' o 'desc'
	const [orderBy, setOrderBy] = useState('name_posi'); // Puede ser 'name' o 'role'
	const [sortedAndFilteredPosi, setSortedAndFilteredPosi] = useState([]);
	const [paginatedPosi, setPaginatedPosi] = useState([]);
	const [totalFiltered, setTotalFiltered] = useState(0);
	

	const loadListPosi = async () => {

		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					table: `sys__posi posi`,
					inner: `LEFT JOIN sys__link link ON link.posi=posi.iden LEFT JOIN sys__user user ON user.iden=link.user`,
					field: `posi.iden iden_posi, posi.name name_posi,
					JSON_ARRAYAGG( JSON_OBJECT( 'iden_user', user.iden,'user_name', user.name,'mail', user.mail ) ) AS details`,
					group: "GROUP BY posi.iden, posi.name",
					order: ` posi.name`, user:mail
				})
			});
			
			if (response.ok) {
				const data = await response.json();
				const result = data.map(row => ({
						iden_posi: row.iden_posi,
						name_posi: row.name_posi,
						details: JSON.parse(row.details),
				}));

				setPosi(result); // Actualizar el estado con los datos recibidos
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	};

	const loadListAsign = async () => {

		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					query: `
							SELECT link.iden, c.code, link.name,verb,c.name FROM req__cent c
							INNER JOIN req__assi assi ON c.code=assi.cent AND assi.role='soli'
							LEFT JOIN (SELECT *,'Solicita SP' verb FROM sys__posi) link ON assi.posi=link.iden
							UNION -- ALL
							SELECT link.iden, c.code, link.name,verb,c.name FROM req__cent c
							LEFT JOIN (SELECT *,'Aprueba SP' verb FROM sys__posi) link ON c.ord1=link.iden
							UNION -- ALL
							SELECT link.iden, c.code, link.name,verb,c.name FROM req__cent c
							LEFT JOIN (SELECT *,'Aprueba SP' verb FROM sys__posi) link ON c.ord2=link.iden
							UNION -- ALL
							SELECT link.iden, c.code, link.name,verb,c.name FROM req__cent c
							LEFT JOIN (SELECT *,'Aprueba SP' verb FROM sys__posi) link ON c.ord3=link.iden
							UNION -- ALL
							SELECT link.iden, c.code, link.name,verb,c.name FROM req__cent c
							LEFT JOIN (SELECT *,'Aprueba OC' verb FROM sys__posi) link ON c.pur1=link.iden
							UNION -- ALL
							SELECT link.iden, c.code, link.name,verb,c.name FROM req__cent c
							LEFT JOIN (SELECT *,'Aprueba OC' verb FROM sys__posi) link ON c.pur2=link.iden
							UNION -- ALL
							SELECT link.iden, c.code, link.name,verb,c.name FROM req__cent c
							LEFT JOIN (SELECT *,'Aprueba OC' verb FROM sys__posi) link ON c.pur3=link.iden
							ORDER BY 1, 2, 3`,
					resu:1, user:mail
				})
			});
			
			if (response.ok) {
				const data = await response.json();
				const result = data.map(row => ({
					iden_posi: row.iden,
					name_posi: row.name,
					key: row.iden+'-'+row.code+'-'+row.verb,
					verb: row.verb,
				}));
				setAsig(result); // Actualizar el estado con los datos recibidos
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}


	};

	const listFiltCC = async () => {
		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					table: `req__cent cent`,
					order: ` name`, user:mail
				})
			});
			
			if (response.ok) {
				const data = await response.json();
				const result = data.map(row => ({
						code: row.code,
						name: row.name,
				}));

				setListCC(result); // Actualizar el estado con los datos recibidos
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const sortComparator = (a, b) => {
		if (order === 'asc') {
			return a[orderBy].localeCompare(b[orderBy]);
		} else {
			return b[orderBy].localeCompare(a[orderBy]);
		}
	};


	useEffect(() => {
		loadListPosi();
		loadListAsign();
		listFiltCC();
	}, []);

	useEffect(() => {
		setCurrentPage(1);
	}, [user]);
	


   useEffect(() => {
	   const filteredPosi = posi.filter(posi => {
		   const titleMatches = posi.name_posi.toLowerCase().includes(filterTitle.toLowerCase());
		   const userMatches = user.iden_user ? posi.details.some((detail) => detail.iden_user === user.iden_user) : true;
		   const userIsYo = filterYo && posi.details.some((detail) => detail.mail && detail.mail.toLowerCase().includes(mail.toLowerCase()));

		   // Agregar el filtro ccMatches para filtrar por asig.name_posi igual a filterCC
		   const ccMatches = filterCC ? asig.some((asigItem) => asigItem.name_posi === filterCC && asigItem.iden_posi === posi.iden_posi) : true;

		   return titleMatches && ccMatches && (filterYo ? userIsYo && userMatches : userMatches);
	   });


	   const startIndex = (currentPage - 1) * itemsPerPage;
	   const endIndex = startIndex + itemsPerPage;

	   
	   setPaginatedPosi(filteredPosi.sort(sortComparator).slice(startIndex, endIndex));
	   setSortedAndFilteredPosi(filteredPosi);


   }, [posi, filterTitle, user.iden_user, filterCC, filterYo, currentPage, order, orderBy]);
   
   useEffect(() => {
	   setTotalFiltered(sortedAndFilteredPosi.length);
   },[sortedAndFilteredPosi])

	
	return (
		<>
		<form>
			<main>
			<h1 data-icon=''>Cargos</h1>
				<nav>
					<article>
						<table>
							<thead>
								<tr>
								<td onClick={ () => handleRequestSort('name_posi')}>Título 
									{
									orderBy === 'name_posi' && (
										<orm-icon>{order === 'asc' ? '':''}</orm-icon>
									)
									}
									</td>
									<td>Asignaciones</td>
									<td>Usuarios</td>
								</tr>
							</thead>
							<tbody>
							{
								
								paginatedPosi.map(posi => (
									<tr key={posi.iden_posi} onClick={ () => { if (role === 3) { setEditPosi(posi.iden_posi);setIsModDialog(true)} } }>
										<td>{posi.name_posi}</td>
										<td>
										{
											asig.filter(asig => asig.iden_posi === posi.iden_posi).map(asig => (
												<div key={asig.key} ><b>{asig.verb}</b> en <b>{asig.name_posi}</b></div>
											))
										}

										</td>
										<td>
										{
											posi.details.map(detail => (
												<div key={posi.iden_posi+'-'+detail.iden_user} >{detail.user_name}</div>
											))
										}
										</td>
									</tr>
								))
							}
							</tbody>
						</table>
					</article>
				</nav>
				<nav>
					<article><Paginator
						totalItems={totalFiltered}
						itemsPerPage={itemsPerPage}
						currentPage={currentPage}
						onPageChange={setCurrentPage}
					/>
					</article>
				</nav>
			</main>
			<aside>
			{role === 3 && (
				<article>
					<h2>Acciones</h2>
					<fieldset>
						<input type="button"  value="Crear cargo" onClick={() => setIsCreaDialog(true)} />
					</fieldset>
				</article>
			)}
				<article>
					<h2>Atajos</h2>
					<fieldset>
						<a onClick={() => {setFilterYo(!filterYo); setCurrentPage(1); } }>Yo</a>
					</fieldset>
				</article>
				<article>
					<h2>Filtros</h2>
					<fieldset>
						<legend>Título</legend>
						<input type="search" placeholder="Título" onChange={e => {setFilterTitle(e.target.value); setCurrentPage(1) } }/>
					</fieldset>
					<fieldset>
						<input type="search" placeholder="Centro de costos" list="cent" value={filterCC} onChange={e => {setFilterCC(e.target.value); setCurrentPage(1) } } />
						<datalist id="cent">
							{
								listCC.map(cc => (
									<option key={cc.code} value={cc.name} />
								))
							}
						</datalist>
					</fieldset>
					<fieldset>
						<legend>Usuarios</legend>
						<input type="text" placeholder="Nombre" value={user.name_user} className="picker user clear" onClick={ ()=>setListUserDialog(true) } readOnly />
						<orm-icon class="red" onClick={ ()=>setUser({iden_user: null,name_user: ''}) }></orm-icon>
					</fieldset>
				</article>
			</aside>
		</form>
		{isCreaDialog && <CreaPosi onListPosi={loadListPosi} onClose={() => setIsCreaDialog(false)}  /> }
		{isModDialog && <ModPosi iden={editPosi} onListPosi={loadListPosi} onClose={() => setIsModDialog(false)}  /> }
		{listUserDialog && <ModUserDialog onUpdateCCList={null} onEditCC={null} onClose={() => setListUserDialog(false)} onListUser={null} onSetListUser={null} onSetOneUser={setUser}  /> }
		</>
	);
}



export default ListPosi;

