
const hostname = window.location.hostname;
const port = window.location.port;
const protocol = window.location.protocol;
const fronurl = `${protocol}//${hostname}`;
const backurl = `${protocol}//${hostname}/orm/`;
const apiurl = `${protocol}//${hostname}/orm/api/`;

const wsurl = 'wss://' + hostname + '/ws/item';

export { fronurl, backurl, apiurl, wsurl };