import React, { useEffect, useState } from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { useCookies } from 'react-cookie';
import { gapi } from 'gapi-script';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from './store/userSlice';
import { Helmet } from 'react-helmet';

import './styles/fonts.css';
import HomePage from './components/home';
import ListUser from './components/user/listUser';
import ListCC from './components/cc/listCC';
import ListSolp from './components/solped/listSolp';
import ListSolpDeta from './components/solped/listSolpDeta';
import ListocDeta from './components/solped/listOcDeta';
import ListPosi from './components/posi/listPosi';
import ListInve from './components/inve/listInve';
import ListTrac from './components/trac/listTrac';
import ListStat from './components/stat/listStat';
import DSP from './components/dsp';
import DocuSolp from './components/solped/docuSolp';

import { postData, sendMail, log, approveSolped  } from './utils/functions';
import { apiurl } from './utils/constants';
const { fetchData } = require('./utils/functions');

const clientId = "208652130630-v5uud3qoi2obo31o3qgj33qfreuik6rl.apps.googleusercontent.com";

function App() {
	const [isGapiLoaded, setIsGapiLoaded] = useState(false);
	const [isSignedIn, setIsSignedIn] = useState(false);
	const [isRole, setIsRole] = useState(0);
	const [cookies, setCookie, removeCookie] = useCookies();
	const dispatch = useDispatch();
	const { iden, mail, name, avat, back, dark, comp } = useSelector((state) => state.user);

	const urlParams = new URLSearchParams(window.location.search);
	const dspParam = urlParams.get('dsp');
	const approve = urlParams.get('approve');
	const orm = urlParams.get('orm');
	if(!dspParam){
		import('./styles/App.css');
		import('./styles/light.css');
		import('./styles/dark.css');
	}

	useEffect(() => {

		if(!approve && !orm){
			async function start() {
				try {
					// sendBD();
					
					await gapi.auth2.init({
						clientId, scope: '' //al pasar scope: '', estás indicando que no estás solicitando ningún permiso específico adicional aparte del acceso básico de autenticación.
					});

				} catch (error) {
					console.error("Error al inicializar gapi:", error);
				}
			}
			gapi.load('client:auth2', start);
			setIsGapiLoaded(true);
		}
	}, []);


	const onSuccess = async (res) => {
		try {
			if (res.profileObj) {

				const response = await fetch(apiurl+'login', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					credentials: 'include',
					body: JSON.stringify({ mail: res.profileObj.email, role: 0, name: res.profileObj.name, avat: res.profileObj.imageUrl }),
				});

				if (response.ok) {
					
					setIsSignedIn(true);
					const data = await response.json();

					let queryUser=`SELECT user.iden, user.mail, user.role, user.name, user.avat, user.dark, user.back, user.comp, db.sapDB FROM sys__user user INNER JOIN sys__db db ON db.iden=user.comp WHERE mail='${res.profileObj.email}'`;
					const apiData = await fetchData('queryAux', queryUser, res.profileObj.email);
					if (apiData) { //Usuario registrado
						setIsRole(apiData[0].role);
						if (apiData[0].role > 0) {
							dispatch(setUserData({ iden: apiData[0].iden, mail: res.profileObj.email, name: res.profileObj.name, avat: res.profileObj.imageUrl, back: apiData[0].back, dark: apiData[0].dark, role: apiData[0].role, comp: data.user.comp, sapDB: apiData[0].sapDB }));
						} else {
							dispatch(setUserData({ iden: apiData[0].iden, mail: res.profileObj.email, name: res.profileObj.name, avat: res.profileObj.imageUrl, back: '', dark: '', role: apiData[0].role, comp: data.user.comp, sapDB: apiData[0].sapDB }));
						}
					}else {
						const apiDataIns = await fetchData('queryAux', `INSERT INTO sys__user (mail, role, name, avat) VALUES ('${res.profileObj.email}',0,'${res.profileObj.name}','${res.profileObj.imageUrl}') ; `, res.profileObj.email);
						const apiDataSel = await fetchData('queryAux', queryUser, res.profileObj.email);
						if(apiDataSel){ //Usuario registrado
							setIsRole(apiDataSel[0].role);
							if (apiDataSel[0].role > 0) {
								dispatch(setUserData({ iden: apiDataSel[0].iden, mail: res.profileObj.email, name: res.profileObj.name, avat: res.profileObj.imageUrl, back: apiDataSel[0].back, dark: apiDataSel[0].dark, role: apiDataSel[0].role, comp: data.user.comp, sapDB: apiDataSel[0].sapDB }));
							} else {
								dispatch(setUserData({ iden: apiDataSel[0].iden, mail: res.profileObj.email, name: res.profileObj.name, avat: res.profileObj.imageUrl, back: '', dark: '', role: apiDataSel[0].role, comp: data.user.comp, sapDB: apiDataSel[0].sapDB }));
							}
						}else{
							console.log(`Error al registrar usuario`);
						}
					}
				} else console.log('Error API');
			}
		} catch (error) {
			setIsSignedIn(false);
			console.log('Deslogueado');
		}
	};

	const onFailure = (error) => {
		console.log('Error en Login.error :', error);
	};

	const handleLogout = () => {
		Object.keys(cookies).forEach(cookieName => {
			removeCookie(cookieName);
		});
		const auth2 = gapi.auth2.getAuthInstance();
		if (auth2 != null) {
			auth2.signOut().then(function () {
				console.log('Usuario desconectado de Google.');
			});
		}
		window.location.href = '/';
	};

	if (!isGapiLoaded && (!approve && !orm)) {
		return <div>Cargando...</div>;
	}

	return (
		<div className="App">
			{
				(!approve && !orm) ? (isSignedIn ? (<Menu isRole={isRole} handleLogout={handleLogout} state={{ iden, mail, name, avat, back, dark, comp }} />) : (<Home onSuccess={onSuccess} onFailure={onFailure} />))
					: (<Approve approve={approve} orm={orm} />
				)
			}
		</div>
	);
}

function Approve({ approve, orm }){

	const [solp, setSolp] = useState('');
	const [iden, setIden] = useState('');
	const [share, setShare] = useState(0);
	const [status, setStatus] = useState(100);


	const loadUser = async () => {
		try {
			const response = await fetch(apiurl + 'getMail', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					iden_md5: approve
				})
			});

			if (response.ok) {
				const data = await response.json();
				setIden(data.iden);
				loadSolped(data.iden, data.mail, data.sapDB);
			} else {
				setStatus(-1);
				console.error('Error al obtener los getMail');
			}
		} catch (error) {
			setStatus(-2);
			console.error('Error en la solicitud:', error);
		}
	}

	const loadSolped = async (iden, mail, sapDB) => {
		try {
			const response = await fetch(apiurl + 'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					user: mail,
					query: `SELECT iden solp, MD5(CONCAT('BailacORM',iden)) share, IF(approver IS NULL, 0, 1) status  FROM req__soli WHERE MD5(CONCAT('orm',iden)) = '${orm}' `
				})
			});

			if (response.ok) {
				const data = await response.json();
				setSolp(data[0].solp);
				setShare(data[0].share);
				if(data[0].status) setStatus(1);
				else setStatus(await approveSolped(data[0].solp, iden, mail, sapDB, 'mail')); // status 1 error - status 2 aprobado - status 3 no tiene permisos

			} else {
				setStatus(-3);
				console.error('Error al obtener los CC');
			}
		} catch (error) {
			setStatus(-4);
			console.error('Error en la solicitud:', error);
		}
	}
 
	useEffect(() => {
		loadUser();
	}, [])

	return (
		<form>
			<Helmet>
				<html lang="es" translate="no" />
				<body className={'light-mode'} translate="no" />
			</Helmet>
			{<dialog open>
				<div className={status === 2 ? "gre" : status< 1 ? "red" : "blu" }>
					<p data-icon={ status === 2 ? ( "") : status < 1 ? ("") : ("") }> 
						{status === 2 ? (<div><div>{`Solicitud ${solp} aprobada.`}</div><br></br><div>Puede ver la solicitud completa aquí: <a href={`${window.location.origin}?sp=${share}`}>{`${window.location.origin}?sp=${share}`}</a></div></div>) 
							: status === 100 ? (<div>Autorizando...</div>) 
								: (<div>
									<div>Error al aprobar esta solicitud</div>
									<br></br>
									<div>{`Código : ${status}.`}</div>
								</div>)
						}
					</p>
				</div>
			</dialog>}
		</form>
	)
}

function Home({ onSuccess, onFailure }) {
	return (
		<form>
			<Helmet>
				<html lang="es" translate="no" />
				<body className={'light-mode'} translate="no" />
			</Helmet>
			<main className='home'>
				<div className='logo'></div>
				<div>
					<p>Bienvenido!</p>
					<p>Este sitio está en construcción, sin embargo, si tiene una cuenta Bailacthor puede ingresar para ver los avances.</p>
				</div>
				<div id="signInButton">
					<GoogleLogin clientId={clientId} buttonText="Iniciar sesión" onSuccess={onSuccess} onFailure={onFailure} cookiePolicy={'single_host_origin'} isSignedIn={true} prompt="select_account" />
				</div>
			</main>
		</form>
	);
}

function Menu({ isRole, handleLogout, state }) {

	const imagePaths = ['bg0.png', 'bg1.png', 'bg2.png', 'bg3.png', 'bg4.png'];
	const images = imagePaths.map(path => require(`./img/${path}`));
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [statSAP, setStatSap] = useState(0);
	const dispatch = useDispatch();
	const { iden, mail, name, avat, back, dark, comp, sapDB } = useSelector((state) => state.user);

	const [selectedComponent, setSelectedComponent] = useState([]);
	const [share, setShare] = useState(0);
	const [changeComp, setChangeComp] = useState(0);

	const urlParams = new URLSearchParams(window.location.search);
	const spParam = urlParams.get('sp');
	const dspParam = urlParams.get('dsp');
	const compParam = urlParams.get('comp');

	const messageComponent =
		isRole === 0 ? (<main><nav><article className='blu'> <h2>Pendiente</h2> <p data-icon=''>Ya estás en la lista de espera, te avisaremos cuando tu cuenta sea aprobada.</p> </article> </nav></main>)
			: isRole === -1 ? (<main><nav><article className='red'><h2>Inactivo</h2><p data-icon=''>Tu cuenta ha sido desactivada, contacta con el administrador para reactivarla.</p></article></nav></main>)
				: (<></>);

	const handleMenuItemClick = (component, iden = null, solped=null, filtr=null) => {
		setSelectedComponent({ component, iden, solped, filtr });
	};

	const setSelectComp = async (val) => {

		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ query: `UPDATE sys__user SET comp=${val} WHERE mail='${mail}' `, user:mail }),
			});
	
			if (response.ok) {
				
				let sapDB_aux='';
				switch (val) {
					case "0": sapDB_aux="CLTSTTHORINGENIERIA"; break;
					case "1": sapDB_aux="CLPRDTHORINGENIERIA"; break;
					case "2": sapDB_aux="CLPRDTHORSERVYASESORIAS"; break;
					case "3": sapDB_aux="CLPRDTHORTRANSPYLOGISTICA"; break;
				}

				dispatch(setUserData({ iden, role: isRole, mail, name, avat, back, dark, comp: val, sapDB: sapDB_aux }));
				handleMenuItemClick(null);
				window.location.reload();

			} else {
				throw new Error('Error en la solicitud POST');
			}
		} catch (error) {
			console.log('error al updatear la DB ',error);
		}
	}

	const openDialog = () => {
		setDialogOpen(true);
	};

	const closeDialog = () => {
		setDialogOpen(false);
	};

	const changeImage = async (index) => {

		setSelectedImageIndex(index);
		const response = await fetch(apiurl+'tablePUT', {
			method: 'POST',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ table: 'sys__user', iden: `mail='${mail}'`, fields: `back='${imagePaths[index]}'`, user:mail }),
		});

		if (response.ok) {
			const data = await response.json();
			dispatch(setUserData({ iden: iden, role: isRole, mail: mail, name: name, avat: avat, back: imagePaths[index], dark, comp, sapDB }));
		} else {
			throw new Error('Error en la solicitud POST');
		}

	};

	const handleCBX = async (event) => {

		const cbx = (event.target.checked) ? 1 : 0;

		const response = await fetch(apiurl+'tablePUT', {
			method: 'POST',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ table: 'sys__user', iden: `mail='${mail}'`, fields: `dark='${cbx}'`, user:mail }),
		});

		if (response.ok) {
			const data = await response.json();
			dispatch(setUserData({ iden: iden, role: isRole, mail: mail, name: name, avat: avat, back: back, dark: cbx, comp, sapDB }));
		} else {
			throw new Error('Error en la solicitud POST');
		}
	};

	const getComponent = () => {
		switch (selectedComponent.component) {
			case 'listUser':
				return <ListUser key={Math.floor(Math.random() * 10000)} />;
			case 'listPosi':
				return <ListPosi key={Math.floor(Math.random() * 10000)} />;
			case 'listCC':
				return <ListCC key={Math.floor(Math.random() * 10000)} />;
			case 'listInve':
				return <ListInve key={Math.floor(Math.random() * 10000)} />
			case 'listSolp':
				return <ListSolp onMenuItemClick={handleMenuItemClick} key={Math.floor(Math.random() * 10000)} filtr={selectedComponent.filtr}/>;
			case 'listSolpDeta':
				return <ListSolpDeta onMenuItemClick={handleMenuItemClick} iden={selectedComponent.iden} docu={selectedComponent.docu} key={Math.floor(Math.random() * 10000)} filtr={selectedComponent.filtr} />;
			case 'docuSolp':
				return <DocuSolp onMenuItemClick={handleMenuItemClick} iden={selectedComponent.iden} key={Math.floor(Math.random() * 10000)} />;
			case 'listOcDeta':
				return <ListocDeta onMenuItemClick={handleMenuItemClick} oc={selectedComponent.iden} solped={selectedComponent.solped} key={Math.floor(Math.random() * 10000)} />;
			case 'listTrac':
				return <ListTrac key={Math.floor(Math.random() * 10000)} />
			case 'listStat':
				return <ListStat key={Math.floor(Math.random() * 10000)} />
			default:
				return <HomePage setSelectedComponent={setSelectedComponent} />;
		}
	};


	const statusSAP = async () => {
		try {
			const response = await fetch(apiurl + 'sapLoginWeb', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					sapDB: sapDB
				})
			});

			if (response.ok) {
				const datat = await response.json();
				setStatSap(datat.token);
			}

		} catch (error) {
			console.error('Error en la solicitud statusSAP:', error);
		}
	}

	const backgroundImage = back ? `url(${require(`./img/${back}`)})` : `url(${images[selectedImageIndex]})`;
	const styleString = `background-image: ${backgroundImage};`;

	const getMD5 = async (spParam) => {
		const response = await fetch(apiurl+'getMD5', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ spParam:spParam, user:mail }),
		});

		if (response.ok) {
			const data = await response.json();
			if (data.iden)handleMenuItemClick('listSolpDeta', data.iden);
		} else {
			throw new Error('Error en la solicitud POST');
		}
	}


	useEffect(() => {
		if (spParam && mail)getMD5(spParam,'sp');
	}, [mail]);

	useEffect(() => {
		statusSAP();
	}, [sapDB])
	

	return (
		<>
		  { dspParam ? ( <DSP dsp={dspParam} comp={compParam}  /> ) : 
		  (<>
				<Helmet>
					<html lang="es" translate="no" />
					<body className={dark ? 'dark-mode' : 'light-mode'} style={styleString} translate="no" />
				</Helmet>
				<header>
					<h1 data-icon='' onClick={() => handleMenuItemClick(null)}></h1>
					<h1 data-icon="."><figure style={{ backgroundImage: `url(${avat})` }}> </figure>{name}</h1>
					<h1 data-icon=''>
						<select onChange={(e) => setSelectComp(e.target.value)} value={comp?comp:0}>
							<option value="0">Sandbox</option>
							<option value="1">TIAN</option>
							<option value="2">TSAAN</option>
							<option value="3">TLOG</option>
						</select>
					</h1>
					<h1 data-icon='' onClick={openDialog}>Preferencias</h1>
					<h1 data-icon='' onClick={() => handleMenuItemClick('listUser')}>Usuarios</h1>
					<h1 data-icon='' onClick={() => handleMenuItemClick('listPosi')}>Cargos</h1>
					<h1 data-icon='' onClick={() => handleMenuItemClick('listCC')}>Centros de costo</h1>
					<h1 data-icon='' onClick={() => handleMenuItemClick('listInve')}>Inventario</h1>
					<h1 data-icon='' onClick={() => handleMenuItemClick('listSolp')}>Solicitudes</h1>
					<h1 data-icon='' onClick={() => handleMenuItemClick('listTrac')}>Seguimiento</h1>
					<h1 data-icon='' onClick={() => handleMenuItemClick('listStat')}>Estadísticas</h1>
					<h1 data-icon='' onClick={handleLogout}>Salir</h1>
					<footer className={ statSAP ? 'gre':'red' }>
							<h1 data-icon=''>SAP {statSAP ? 'Online' : 'Offline'}</h1>
					</footer>
				</header>
				{dialogOpen && (
				<orm-modal>
					<dialog open>
						<h2>Preferencias</h2>
						<table>
							<thead>
								<tr>
									<td><orm-icon></orm-icon> Modo oscuro</td>
									<td><input type="checkbox" onChange={handleCBX} checked={dark} /></td>
								</tr>
							</thead>
						</table>
						<table>
							<tbody>
								<tr>
									<td colSpan="3"><orm-icon></orm-icon> Fondo</td>
								</tr>
								<tr>
									{images.map((image, index) => (
										index < 3 && (<td key={index}><img className="pref_bg" src={images[index]} onClick={() => changeImage(index)} /></td>)
									))}
								</tr>
								<tr>
									{images.map((image, index) => (
										index >= 3 && (<td key={index}><img className="pref_bg" src={images[index]} onClick={() => changeImage(index)} /></td>)
									))}
								</tr>
							</tbody>
						</table>
						<table>
							<tfoot>
								<tr>
									<td></td>
									<td><input type="button" value="Aceptar" onClick={closeDialog} /></td>
								</tr>
							</tfoot>
						</table>
					</dialog>
				</orm-modal>
				)}
			  {!isRole ? messageComponent : getComponent(selectedComponent)}
			</>)
		}
		</>
	  );
}

export default App;