import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Paginator from '../paginator';
import { useSelector } from 'react-redux';
import CreateSolpDialog from './creaSolp';
import { apiurl } from '../../utils/constants';
import ModUserDialog from '../cc/editCC';
import { fCurr, setFiltDate } from '../../utils/functions';

function ListSolp({ onMenuItemClick, filtr }) {

	const { iden: user, role, mail } = useSelector((state) => state.user);

	const [solped, setSolped] = useState([]);
	const [filteredSolped, setFilteredSolped] = useState([]);
	const [paginatedSolp, setPaginatedSolp] = useState([]);
	const [cc, setCC] = useState([]);
	const [autorized,setAutorized] = useState([]);

	const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;

	//Atajos
	const [filtDraf, setFiltDraf] = useState(false);
	const [filtMySolp, setFiltMySolp] = useState(false);
	const [filtMySolpPend, setFiltMySolpPend] = useState(false);
	const [filtReqMyAppr, setFiltReqMyAppr] = useState(false);
	const [filtMyAppr, setFiltMyAppr] = useState(false);
	
	//Filtros
	const [filtCode, setFiltCode] = useState('');
	const [filtCC, setFiltCC] = useState('');
	const [filtStat, setFiltStat] = useState(false);
	const [filtRequ, setFiltRequ] = useState({ iden_user: null, name_user: '' });
	const [filtRequDateStart, setFiltRequDateStart] = useState(false);
	const [filtRequDateEnd, setFiltRequDateEnd] = useState(false);
	const [filtAppr, setFiltAppr] = useState(false);
	const [filtApprDateStart, setFiltApprDateStart] = useState(false);
	const [filtApprDateEnd, setFiltApprDateEnd] = useState(false);
	const [filtUrge,setFiltUrge] = useState(false);

	const [listUserDialog, setListUserDialog] = useState(false);
	const [listUserDialog2, setListUser2Dialog] = useState(false);

	const [totalFiltered, setTotalFiltered] = useState(0);


	const loadSolped = async () => { 

		try {

			let where='';
			if(role<3){
				/*
				// Consulta original
				where = `AND (
							submitter=${user} 
							OR (SELECT COUNT(1) FROM req__assi assi INNER JOIN sys__link link ON assi.posi=link.posi AND link.user=${user} WHERE role='soli' AND assi.cent=soli.cent )
							OR (SELECT COUNT(1) FROM sys__link WHERE user=${user} AND posi=cent.ord1)
							OR (SELECT COUNT(1) FROM sys__link WHERE user=${user} AND posi=cent.ord2)
							OR (SELECT COUNT(1) FROM sys__link WHERE user=${user} AND posi=cent.ord3)
							OR (SELECT COUNT(1) FROM sys__link WHERE user=${user} AND posi=cent.pur1)
							OR (SELECT COUNT(1) FROM sys__link WHERE user=${user} AND posi=cent.pur2)
							OR (SELECT COUNT(1) FROM sys__link WHERE user=${user} AND posi=cent.pur3)
							OR appr.user = ${user}
						)`;
				*/
				// Propuesta de GPT-4o
				where = `AND (
					submitter=${user} 
					OR EXISTS (SELECT 1 FROM req__assi assi INNER JOIN sys__link link ON assi.posi=link.posi AND link.user=${user} WHERE role='soli' AND assi.cent=soli.cent)
					OR EXISTS (SELECT 1 FROM sys__link WHERE user=${user} AND posi IN (cent.ord1, cent.ord2, cent.ord3, cent.pur1, cent.pur2, cent.pur3))
					OR appr.user = ${user}
				)`;
			}

			const response = await fetch(apiurl + 'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					user: mail,
					query: `
						SELECT
						soli.iden,
						JSON_ARRAYAGG(
							JSON_OBJECT('ritem_iden', ritem.iden,'item_code', iitem.code,'item_name', iitem.name,'unit', iitem.unit,'amou', ritem.amou,'pend', ritem.pend,'cost', ritem.cost,'hand', iitem.hand)
						) AS details,
						JSON_ARRAYAGG(
							JSON_OBJECT('entr', orde.entr, 'docu', orde.docu, 'sent', orde.sent, 'stat', orde.stat)
						) AS ordes,
						soli.cent, cent.name cc_name,entry,soli.docu,urge,
						SUM(ritem.amou * ritem.cost) AS totalValue,
						requester,req.name req_name,requested,
						submitter, submitted, sub.name sub_name,
						soli.approver,soli.approved,
						rejected,
						clos,
						(SELECT IF(SUM(ritem.amou * ritem.cost)<=ord1, 'ord1', IF(SUM(ritem.amou * ritem.cost)<=ord2, 'ord2', 'ord3') ) FROM req__tier) AS ord,
						appr.user appr,
						auth.hasLvl1 AS hasLvl1,
						auth.hasLvl2 AS hasLvl2,
						auth.hasLvl3 AS hasLvl3,
						ord1.user AS ord1,
						ord2.user AS ord2,
						ord3.user AS ord3
					FROM req__soli soli
					LEFT JOIN req__item ritem ON soli.iden = ritem.soli
					LEFT JOIN inv__item iitem ON ritem.item = iitem.code
					LEFT JOIN inv__stor stor ON ritem.item = stor.item AND stor.ware = iitem.ware
					LEFT JOIN sys__user req ON soli.requester = req.iden
					LEFT JOIN sys__user sub ON soli.submitter = sub.iden
					LEFT JOIN req__cent cent ON soli.cent = cent.code
					LEFT JOIN req__appr appr ON soli.iden = appr.soli
					LEFT JOIN req__orde orde ON soli.entry = orde.entr

					LEFT JOIN (
						SELECT soli, MAX(IF(level=1,approver,NULL)) hasLvl1, MAX(IF(level=2,approver,NULL)) hasLvl2, MAX(IF(level=3,approver,NULL)) hasLvl3 FROM req__auth GROUP BY soli
					) auth ON soli.iden=auth.soli

					LEFT JOIN (SELECT user, posi FROM sys__link WHERE user=${user}) AS ord1 ON ord1.posi=cent.ord1
					LEFT JOIN (SELECT user, posi FROM sys__link WHERE user=${user}) AS ord2 ON ord2.posi=cent.ord2
					LEFT JOIN (SELECT user, posi FROM sys__link WHERE user=${user}) AS ord3 ON ord3.posi=cent.ord3

					WHERE ( (requester IS NULL AND submitter=${user}) OR requester IS NOT NULL OR submitter=${user}) ${where}
					GROUP BY soli.iden, soli.cent
					ORDER BY soli.iden DESC;
					`
				})
			});
			

			if (response.ok) {

				const data = await response.json();
				const result = data.map(row => ({
					iden: row.iden,
					details: JSON.parse(row.details),
					ordes: JSON.parse(row.ordes),
					cent: row.cent,
					cc_name: row.cc_name,
					entry: row.entry,
					docu: row.docu,
					urge: row.urge,
					totalValue: row.totalValue,
					appr: row.appr,
					requester: row.requester,
					req_name: row.req_name,
					requested: row.requested,
					submitter: row.submitter,
					submitted: row.submitted,
					sub_name: row.sub_name,
					approver: row.approver,
					approved: row.approved,
					rejected: row.rejected,
					clos: row.clos,
					ord: row.ord,
					hasLvl1: row.hasLvl1,
					hasLvl2: row.hasLvl2,
					hasLvl3: row.hasLvl3,
					isApprOrd1: row.ord1,
					isApprOrd2: row.ord2,
					isApprOrd3: row.ord3,
				}));

				const solpedWithUniqueDetails = result.map(solp => {
					const uniqueDetailsSet = new Set();
					const uniqueDetails = solp.details.filter(detail => {
						if (!uniqueDetailsSet.has(detail.ritem_iden)) {
							uniqueDetailsSet.add(detail.ritem_iden);
							return true;
						}
						return false;
					});

					return { ...solp, details: uniqueDetails };
				});

				const solpedWithUniqueOrdes = solpedWithUniqueDetails.map(solp => {
					const uniqueDocuSet = new Set();
					const uniqueOrdes = solp.ordes.filter(order => {
						if (!uniqueDocuSet.has(order.docu)) {
							uniqueDocuSet.add(order.docu);
							return true;
						}
						return false;
					});

					return { ...solp,ordes: uniqueOrdes};
				});

				const evalStat = (solp) => {
					if (solp.rejected) return 'Rechazado';
					if (!solp.requester) return 'Borrador';
					else if (solp.clos) return 'Cerrado';
					else if (solp.details.every(detail => detail.pend===0)) return 'Recibido';
					else if (solp.ordes.some(orde => orde.stat === 'O')) return 'Comprando';
					else if (solp.ordes.every(orde => orde.stat === 'C')) return 'Comprado';
					else if (solp.approver) return 'Aprobado';
					else if (solp.requested) return 'Solicitado';
				};

				const solpedWithEstado = solpedWithUniqueOrdes.map(solp => ({
					...solp,
					stat: evalStat(solp),
				}));

				setSolped(solpedWithEstado);
				if (typeof filtr === 'string'){
					if (filtr === 'draf') setFiltDraf(true);
					if (filtr === 'publ') setFiltMySolpPend(true);
					if (filtr === 'appr') setFiltReqMyAppr(true);
				} else if (typeof filtr === 'object' && filtr !== null) {

					if (filtr.filtDraf) setFiltDraf(filtr.filtDraf); 
					if (filtr.filtMySolp) setFiltMySolp(filtr.filtMySolp); 
					if (filtr.filtReqMyAppr) setFiltReqMyAppr(filtr.filtReqMyAppr); 
					if (filtr.filtMyAppr) setFiltMyAppr(filtr.filtMyAppr);
					if (filtr.filtCode) setFiltCode(filtr.filtCode);
					if (filtr.filtCC) setFiltCC(filtr.filtCC);
					if (filtr.filtStat) setFiltStat(filtr.filtStat);
					if (filtr.filtUrge) setFiltUrge(filtr.filtUrge);
					if (filtr.filtRequ) setFiltRequ(filtr.filtRequ);
					if (filtr.filtAppr) setFiltAppr(filtr.filtAppr);
					if (filtr.filtRequDateStart) setFiltRequDateStart(filtr.filtRequDateStart);
					if (filtr.filtRequDateEnd) setFiltRequDateEnd(filtr.filtRequDateEnd);
					if (filtr.filtApprDateStart) setFiltApprDateStart(filtr.filtApprDateStart);
					if (filtr.filtApprDateEnd) setFiltApprDateEnd(filtr.filtApprDateEnd);
					if (filtr.currentPage) setCurrentPage(filtr.currentPage);
				}

			}
			
		} catch (error) {
			console.log('Error en loadSolped ', error);
		}

	};

	const loadListCC = async () => {

		try {
			const response = await fetch(apiurl + 'queryAux', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					user: mail,
					query: `SELECT code, name,
								EXISTS(SELECT 1 FROM req__assi assi
									LEFT JOIN sys__link link ON assi.posi = link.posi
									WHERE req__cent.code = assi.cent AND link.user = ${user}) valid
							FROM req__cent;` }),
				credentials: 'include'
			});

			if (response.ok) {
				const data = await response.json();

				// Almacena todos los CC del sistema con code y name en setCC
				const formattedData = data.map(item => ({ code: item.code, name: item.name }));
				setCC(formattedData);

				// Almacena solo los CC a los que el usuario tiene acceso
				const authorizedCodes = data.filter(item => item.valid === 1).map(item => item.code);
				setAutorized(authorizedCodes);

			} else {
				console.error('Error al obtener los CC');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}

	}

	useEffect(() => {
		loadListCC();
		loadSolped();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const filteredData = solped.filter(solp => {

			const submitterMatches = !filtDraf || (solp.submitter === user && solp.requester === null && solp.rejected === null);
			const mySolpMatches = !filtMySolp || (solp.submitter === user);
			const mySolpMatchesPend = !filtMySolpPend || (solp.submitter === user && solp.approver === null);
			const reqMyApprMatches = !filtReqMyAppr || solp.requester && !solp.clos && (
				(solp.ord === 'ord1' && !solp.hasLvl1 && !solp.approver && (solp.isApprOrd1 === user || solp.appr === user)) ||
				(solp.ord === 'ord2' && !solp.hasLvl2 && !solp.approver && solp.isApprOrd2 === user) ||
				(solp.ord === 'ord3' && !solp.hasLvl3 && !solp.approver && solp.isApprOrd3 === user));
			const myApprMatches = !filtMyAppr || (solp.hasLvl1 === user || solp.hasLvl2 === user || solp.hasLvl3 === user);

			const codeMatches = !filtCode || (solp.iden.toString().includes(filtCode) || (solp.docu && solp.docu.toString().includes(filtCode)));
			const ccMatches = !filtCC || (solp.cc_name === filtCC);
			const statMatches = !filtStat || (solp.stat === filtStat);
			const requMatches = !filtRequ.iden_user || (solp.requester === filtRequ.iden_user);
			const requDateStartMatches = !filtRequDateStart || (solp.requested && new Date(solp.requested) >= setFiltDate(filtRequDateStart, 'start'));
			const requDateEndMatches = !filtRequDateEnd || (solp.requested && new Date(solp.requested) <= setFiltDate(filtRequDateEnd, 'end'));
			const apprMatches = !filtAppr.iden_user || (solp.hasLvl1 === filtAppr.iden_user || solp.hasLvl2 === filtAppr.iden_user || solp.hasLvl3 === filtAppr.iden_user);
			const apprDateStartMatches = !filtApprDateStart || (solp.approved && new Date(solp.approved) >= setFiltDate(filtApprDateStart, 'start'));
			const apprDateEndMatches = !filtApprDateEnd || (solp.approved && new Date(solp.approved) <= setFiltDate(filtApprDateEnd, 'end'));
			const urgeMatches = !filtUrge || (solp.urge === (filtUrge ? 1 : 0));

			return submitterMatches && mySolpMatches && mySolpMatchesPend && reqMyApprMatches && myApprMatches &&
				codeMatches && ccMatches && statMatches && requMatches &&
				requDateStartMatches && requDateEndMatches && apprMatches &&
				apprDateStartMatches && apprDateEndMatches && urgeMatches;
		});

		const startIndex = (currentPage - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;

		setPaginatedSolp(filteredData.slice(startIndex, endIndex));
		setFilteredSolped(filteredData);

	}, [solped, currentPage , filtDraf, filtMySolp, filtMySolpPend, filtReqMyAppr, filtMyAppr, filtCode, filtCC, filtStat, filtRequ, 
		filtRequDateStart, filtRequDateEnd, filtAppr, filtApprDateStart, filtApprDateEnd, filtUrge]);

	useEffect(() => {
		setTotalFiltered(filteredSolped.length);
	}, [filteredSolped]);

	useEffect(() => {
		setCurrentPage(1);
	}, [filtDraf, filtMySolp, filtReqMyAppr, filtMyAppr, filtCode, filtCC, filtStat, filtRequ,
		filtRequDateStart, filtRequDateEnd, filtAppr, filtApprDateStart, filtApprDateEnd, filtUrge])


	return (<>
		<form>
			<style jsx="true" > {`.itemTable .ellipsis{ max-width: 500px; }`} </style>
			<main>
				<h1 data-icon='' > Solicitudes de pedido </h1>
				<nav>
					{
						paginatedSolp.map(solped => (
							<article key={solped.iden} 
								onClick={() => onMenuItemClick('listSolpDeta', solped.iden, null, 
									{ 	currentPage,filtDraf,filtMySolp,filtReqMyAppr,filtMyAppr,
										filtCode, filtCC, filtStat, filtUrge, filtRequ, filtAppr,
										filtRequDateStart, filtRequDateEnd, filtApprDateStart,filtApprDateEnd })}  >
								<div className={solped.urge ? 'yel' : ''}>
									<h2>
										<div>{solped.req_name ? solped.req_name : (solped.rejected ? (<><orm-icon className="red" ></orm-icon>{` Rechazado`}</>) : 'Borrador')}</div>
										<div>{solped.cc_name}</div>
										<div>{solped.requested ? format(new Date(solped.requested), 'dd/MM/yyyy') : '--/--/----'}</div>
									</h2>
								</div>
								<table className='requestTable'>
									<tbody>
										<tr>
											<th className={solped.docu ? '' : 'disabled'}> {solped.docu ?? solped.iden} </th>
											<td className={solped.stat === 'Recibido' ? 'done' : ''} >
												<table className='itemTable' >
													<tbody>
														{
															solped.details.map((detail, index) => (
																<tr key={index} >
																	<td>{detail.item_code} </td>
																	<td className='ellipsis' > {detail.item_name} </td>
																	<td>
																		{solped.entry ? detail.pend + '/' : ''}
																		{detail.amou}
																	</td>
																</tr>))
														}
													</tbody>
												</table>
											</td>
											<th> {fCurr(solped.totalValue)} CLP </th>
											<td className={`state ${solped.stat === 'Cerrado' ? 'gra' : (solped.stat === 'Recibido' ? 'blu' : (solped.stat === 'Comprado' ? 'gre' : (solped.stat === 'Comprando' ? 'yel' : (solped.stat === 'Aprobado' ? 'ora' : (solped.stat === 'Borrador' ? '' : 'red')))))}`}>
												<mark>{solped.stat}</mark>
												<time> {
													format(new Date(solped.clos ? solped.clos : solped.approved ? solped.approved : solped.requested ? solped.requested : solped.rejected ? solped.rejected : solped.submitted), 'dd/MM/yyyy')
												}</time>
												<orm-step style={{ marginBottom: '5px' }}> </orm-step>
												<div className='scroll'>
													{
														solped.ordes.map((orde, index) => (
															orde.docu ? <div key={index} >
																<figure className={orde.stat === 'O' ? "red" : "gre"} ></figure> OC {orde.docu}
															</div> : ''
														))
													}
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</article>

						))
					}
				</nav>
				<nav>
					<article><Paginator totalItems={totalFiltered} itemsPerPage={itemsPerPage} currentPage={currentPage} onPageChange={setCurrentPage} /> </article>
				</nav>
			</main>
			<aside>
				{
					autorized.length > 0 && (
						<article>
							<h2>Acciones </h2>
							<fieldset>
								<input type="button" value="Crear solicitud de pedido" onClick={() => setIsCreateDialogOpen(true)} />
							</fieldset>
						</article>
					)
				}
				<article>
					<h2>Atajos</h2>
					<fieldset>
						<a href='#' className={filtDraf ? 'active' : null} onClick={() => { setFiltDraf(!filtDraf); }}> Mis borradores </a>
						<a href='#' className={filtMySolp ? 'active' : null} onClick={() => { setFiltMySolp(!filtMySolp); }}> Mis solicitudes </a>
						<a href='#' className={filtMySolpPend ? 'active' : null} onClick={() => { setFiltMySolpPend(!filtMySolpPend); }}> Mis solicitudes pendientes</a>
						<a href='#' className={filtReqMyAppr ? 'active' : null} onClick={() => { setFiltReqMyAppr(!filtReqMyAppr); }}> Requieren mi aprobación </a>
						<a href='#' className={filtMyAppr ? 'active' : null} onClick={() => { setFiltMyAppr(!filtMyAppr); }}> Aprobadas por mí </a>
					</fieldset>
				</article>
				<article>
					<h2>Filtros </h2>
					<fieldset>
						<legend>Información </legend>
						<input type="search" placeholder="Código" value={filtCode} onChange={e => { setFiltCode(e.target.value); }} />
						<input type="search" placeholder="Centro de costos" list="cc" value={filtCC} onChange={e => { setFiltCC(e.target.value); }} />
						<datalist id="cc"> {cc.map((cc) => (<option key={cc.code}> {cc.name} </option>))} </datalist>
						<select value={filtStat} onChange={e => { setFiltStat(e.target.value); }}>
							<option value=''> Estado </option>
							<option value='Borrador'> Borrador </option>
							<option value='Solicitado'> Solicitado </option>
							<option value='Aprobado'> Aprobado </option>
							<option value='Comprando'> Comprando </option>
							<option value='Comprado'> Comprado </option>
							<option value='Recibido'> Recibido </option>
							<option value='Cerrado'> Cerrado </option>
						</select>
						<input id="urgency" type="checkbox" checked={filtUrge} onChange={e => { setFiltUrge(!filtUrge); }} />
						<label htmlFor="urgency"> Solo urgencias</label>
					</fieldset>

					<fieldset>
						<legend>Solicitud</legend>
						<input type="text" placeholder="Solicitante" value={filtRequ.name_user} className="picker user clear" onClick={() => { setListUserDialog(true); }} readOnly />
						<orm-icon className="red" onClick={() => { setFiltRequ({ iden_user: null, name_user: '' }); }}></orm-icon>
						<input type="date" value={filtRequDateStart} onChange={(e) => { setFiltRequDateStart(e.target.value); }} />
						<input type="date" value={filtRequDateEnd} onChange={(e) => { setFiltRequDateEnd(e.target.value); }} />
					</fieldset>

					<fieldset>
						<legend>Aprobación</legend>
						<input type="text" placeholder="Aprobador" value={filtAppr.name_user} className="picker user clear" onClick={() => { setListUser2Dialog(true); }} readOnly />
						<orm-icon className="red" onClick={() => { setFiltAppr({ iden_user: null, name_user: '' }); }}></orm-icon>
						<input type="date" value={filtApprDateStart} onChange={(e) => { setFiltApprDateStart(e.target.value); }} />
						<input type="date" value={filtApprDateEnd} onChange={(e) => { setFiltApprDateEnd(e.target.value); }} />
					</fieldset>

				</article>
			</aside>
		</form>
		{isCreateDialogOpen && <CreateSolpDialog onMenuItemClick={onMenuItemClick} onUpdateSolpList={loadSolped} listAutorized={autorized} onClose={() => setIsCreateDialogOpen(false)} />}
		{listUserDialog && <ModUserDialog onUpdateCCList={null} onEditCC={null} onClose={() => setListUserDialog(false)} onListUser={null} onSetListUser={null} onSetOneUser={setFiltRequ} />}
		{listUserDialog2 && <ModUserDialog onUpdateCCList={null} onEditCC={null} onClose={() => setListUser2Dialog(false)} onListUser={null} onSetListUser={null} onSetOneUser={setFiltAppr} />}

		</>);
}


export default ListSolp;