import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiurl } from '../../utils/constants';
import { getCurrentDateTime, log } from '../../utils/functions';

function CreateSolpDialog({ onMenuItemClick, onUpdateSolpList, listAutorized, onClose }) {
	
	const [CC, setCC] = useState([]);

	const [cent, setCent] = useState('');
	const { iden, name, mail, sapDB } = useSelector((state) => state.user);
	const [errors, setErrors] = useState({ cod: false, name: false });
	const [isChecked, setIsChecked] = useState(false);
	const [doubleUrge, setDoubleUrge] = useState(0);
	const [data, setData] = useState([]);
	const [sapDB_aux, setSapDB_aux] = useState(0);
	
	const currentDateTime = getCurrentDateTime();
	
	const loadData = async () => {

		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail, table: 'req__cent',order: 'code',field: `code, name`})
			});
			
			if (response.ok) {
				const data = await response.json();
				setCC(data);
			} else {
				console.error('Error al obtener los CC');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
		
	}

	const isDoubleUrge = async (cent) => {
		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					user:mail,
					query: `SELECT iden FROM req__soli WHERE MONTH(submitted) = MONTH(CURDATE()) AND YEAR(submitted) = YEAR(CURDATE()) AND urge=1 AND cent=${cent}`
				})
			});


			if (response.ok) {
				const data = await response.json();
				if(data[0]){ 
					setDoubleUrge(1);
				}else{
					setDoubleUrge(0);
				}
			} else {
				console.error('Error al obtener los CC');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	
	}

	useEffect(() => {
		
		switch (sapDB) {
			case "CLTSTTHORINGENIERIA": setSapDB_aux("Sandbox"); break;
			case "CLPRDTHORINGENIERIA": setSapDB_aux("TIAN"); break;
			case "CLPRDTHORSERVYASESORIAS": setSapDB_aux("TSAAN"); break;
			case "CLPRDTHORTRANSPYLOGISTICA": setSapDB_aux("TLOG"); break;
		}

		loadData();
	}, []);

	useEffect(() => {
		isDoubleUrge(cent);
		console.log('useEffect isDoubleUrge');
	}, [cent])
	

	
	const handleCreateSolp = async () => {
		const newErrors = {};

		if (isNaN(cent) || cent <= 0) newErrors.cent = true;
		setErrors(newErrors);

		if (Object.values(newErrors).some(error => error)) {
			return;
		}

		try {
			const response = await fetch(apiurl+'tableINSE', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail,  
					table: 'req__soli', 
					fiels: `cent,submitted, submitter, urge`, 
					vals: `'${cent}',now(),'${iden}', ${isChecked ? '1' : '0'}`
				}),
			});

			if (response.ok) {
				const responseData = await response.json();
				setData(responseData);
				if (responseData[0].error !== 1) {
					log('add', 'soli', responseData[0].lid, mail, iden );
					onClose();
					onMenuItemClick('listSolpDeta',responseData[0].lid);
				}else console.log(responseData[0].msg);
			} else {
				throw new Error('Error en la solicitud POST');
			}
		} catch (error) {
			console.error('Error al crear el usuario:', error);
		}

	};

	return (
		<form>
			<orm-modal>
			<dialog open>
				<h2>Creando solicitud de pedido</h2>
				<table>
					<tbody>
						<tr>
							<td>Base de datos:</td>
							<td>{sapDB_aux}</td>
						</tr>
						<tr>
							<td>Centro de costo:</td>
							<td>
								<select onChange={(e) => setCent(e.target.value)} className={errors.cent || data[0]?.error === 1 ? 'error' : ''}>
									<option>Centro de costo</option>
									{
										CC.filter((cc) => listAutorized.includes(cc.code)).map((cc) => (
										<option key={cc.code} value={cc.code}>
											{cc.name}
										</option>
										))
									}
								</select>
							</td>
						</tr>
						<tr>
							<td>Ingresado:</td>
							<td> { currentDateTime }</td>
						</tr>
						<tr>
							<td>Ingresante:</td>
							<td>{ name }</td>
						</tr>
						<tr>
							<td>Urgencia:</td>
							<td><input type="checkbox" id="cbx_urge" name="cbx_urge" checked={isChecked} onChange={ () => { setIsChecked(!isChecked); } } /> </td>
						</tr>
					</tbody>
				</table>
						{
							isChecked && (
								<div className={ doubleUrge ? 'red':'yel'}>
									<h2>Advertencia</h2>
									<p data-icon="">Las urgencias deben ser autorizadas por su gerencia.</p>
									<p>{ doubleUrge ? 'Ya hay una urgencia este mes, está solicitud ameritará una no conformidad.':'Dos urgencias al mes ameritan una no conformidad.'}</p>
								</div>
								)
						}
				<table>
					<tfoot>
					<tr>
						<td></td>
						<td>
							<input type="button" value="Cancelar" onClick={onClose} />
							<input type="button" value="Aceptar" onClick={handleCreateSolp}/>
						</td>
					</tr>
					</tfoot>
				</table>
			</dialog>

			</orm-modal>
		</form>
	);

}

export default CreateSolpDialog;