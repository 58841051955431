import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Paginator from '../paginator';
import { apiurl } from '../../utils/constants';
import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown';
import highchartsDrillDown from 'highcharts/modules/drilldown';
import HighchartsReact from 'highcharts-react-official';
drilldown(Highcharts);

function ListStat() {

	const { iden: user, role, mail, sapDB } = useSelector((state) => state.user);
	const [solped, setSolped] = useState([]);

	const [filtRequDateStart,setFiltRequDateStart] = useState(false);
	const [filtRequDateEnd, setFiltRequDateEnd] = useState(false);
	const [selectedState, setSelectedState] = useState(false);
	const [options, setOptions] = useState({
		chart: { type: 'pie' },
		title: {
			text: 'Estado de solicitudes'
		}
	});
	const [paginatedSolped, setPaginatedSolped] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalFilteredItems, setTotalFilteredItems] = useState(0);
	const [filteredSolped, setFilteredSolped] = useState([]);
	const itemsPerPage = 10;

	useEffect(() => {
		loadSolped();
	}, []);

	useEffect(() => {
		if (solped.length > 0) {
			setHighChartOption();
			setCurrentPage(1);
		}
	}, [solped]);

	useEffect(() => {

		let filteredItems = solped;
		if (selectedState) {
			let statesToInclude = [];
			if (selectedState === 'Pendiente') {
				statesToInclude = ['Solicitado', 'Comprado', 'Comprando', 'Aprobado'];
			} else {
				statesToInclude.push(selectedState);
			}

			filteredItems = filteredItems.filter(item => statesToInclude.includes(item.stat));
		}

		let filteredDetails = filteredItems.flatMap(item => item.details);
		filteredDetails = filteredDetails.filter(detail => detail.amou !== null);

		const startIndex = (currentPage - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		setPaginatedSolped(filteredDetails.slice(startIndex, endIndex));
		setFilteredSolped(filteredDetails);


	}, [solped, currentPage, selectedState]);

	useEffect(() => {
		setTotalFilteredItems(filteredSolped.length);
	}, [filteredSolped]);

	useEffect(() => {
		loadSolped();
	}, [filtRequDateStart, filtRequDateEnd]);

	const loadSolped = async () => {
		try {
			let where = '';

			if (role < 3) {
				where = `AND (
							submitter=${user} 
							OR (SELECT COUNT(1) FROM req__assi assi INNER JOIN sys__link link ON assi.posi=link.posi AND link.user=${user} WHERE role='soli' AND assi.cent=soli.cent )
							OR (SELECT COUNT(1) FROM sys__link WHERE user=${user} AND posi=cent.ord1)
							OR (SELECT COUNT(1) FROM sys__link WHERE user=${user} AND posi=cent.ord2)
							OR (SELECT COUNT(1) FROM sys__link WHERE user=${user} AND posi=cent.ord3)
							OR (SELECT COUNT(1) FROM sys__link WHERE user=${user} AND posi=cent.pur1)
							OR (SELECT COUNT(1) FROM sys__link WHERE user=${user} AND posi=cent.pur2)
							OR (SELECT COUNT(1) FROM sys__link WHERE user=${user} AND posi=cent.pur3)
							OR appr.user = ${user}
						)`;
			}

			const response = await fetch(apiurl + 'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					user: mail,
					query: `
						SELECT
						soli.iden,
						JSON_ARRAYAGG(
							JSON_OBJECT('ritem_iden', ritem.iden,'item_code', iitem.code,'item_name', iitem.name,'unit', iitem.unit,'amou', ritem.amou,'pend', ritem.pend,'cost', ritem.cost,'hand', iitem.hand)
						) AS details,
						JSON_ARRAYAGG(
							JSON_OBJECT('entr', orde.entr, 'docu', orde.docu, 'sent', orde.sent, 'stat', orde.stat)
						) AS ordes,
						soli.cent, cent.name cc_name,entry,soli.docu,urge,
						SUM(ritem.amou * ritem.cost) AS totalValue,
						requester,req.name req_name,requested,
						submitter, submitted, sub.name sub_name,
						soli.approver,soli.approved,
						rejected,
						clos,
						(SELECT IF(SUM(ritem.amou * ritem.cost)<=ord1, 'ord1', IF(SUM(ritem.amou * ritem.cost)<=ord2, 'ord2', 'ord3') ) FROM req__tier) AS ord,
						appr.user appr,
						auth.hasLvl1 AS hasLvl1,
						auth.hasLvl2 AS hasLvl2,
						auth.hasLvl3 AS hasLvl3,
						ord1.user AS ord1,
						ord2.user AS ord2,
						ord3.user AS ord3
					FROM req__soli soli
					LEFT JOIN req__item ritem ON soli.iden = ritem.soli
					LEFT JOIN inv__item iitem ON ritem.item = iitem.code
					LEFT JOIN inv__stor stor ON ritem.item = stor.item AND stor.ware = iitem.ware
					LEFT JOIN sys__user req ON soli.requester = req.iden
					LEFT JOIN sys__user sub ON soli.submitter = sub.iden
					LEFT JOIN req__cent cent ON soli.cent = cent.code
					LEFT JOIN req__appr appr ON soli.iden = appr.soli
					LEFT JOIN req__orde orde ON soli.entry = orde.entr
					LEFT JOIN (
						SELECT soli, MAX(IF(level=1,approver,NULL)) hasLvl1, MAX(IF(level=2,approver,NULL)) hasLvl2, MAX(IF(level=3,approver,NULL)) hasLvl3 FROM req__auth GROUP BY soli
					) auth ON soli.iden=auth.soli
					LEFT JOIN (SELECT user, posi FROM sys__link WHERE user=${user}) AS ord1 ON ord1.posi=cent.ord1
					LEFT JOIN (SELECT user, posi FROM sys__link WHERE user=${user}) AS ord2 ON ord2.posi=cent.ord2
					LEFT JOIN (SELECT user, posi FROM sys__link WHERE user=${user}) AS ord3 ON ord3.posi=cent.ord3
					WHERE ( (requester IS NULL AND submitter=${user}) OR requester IS NOT NULL OR submitter=${user})
					${ filtRequDateStart ? `AND requested >= '${filtRequDateStart} 00:00:00'` : '' }
					${ filtRequDateEnd ? `AND requested <= '${filtRequDateEnd} 23:59:59'` : '' }
					GROUP BY soli.iden, soli.cent
					ORDER BY soli.iden DESC;
					`
				})
			});

			if (response.ok) {

				const data = await response.json();
				const result = data.map(row => ({
					iden: row.iden,
					details: JSON.parse(row.details),
					ordes: JSON.parse(row.ordes),
					cent: row.cent,
					cc_name: row.cc_name,
					entry: row.entry,
					docu: row.docu,
					urge: row.urge,
					totalValue: row.totalValue,
					appr: row.appr,
					requester: row.requester,
					req_name: row.req_name,
					requested: row.requested,
					submitter: row.submitter,
					submitted: row.submitted,
					sub_name: row.sub_name,
					approver: row.approver,
					approved: row.approved,
					rejected: row.rejected,
					clos: row.clos,
					ord: row.ord,
					hasLvl1: row.hasLvl1,
					hasLvl2: row.hasLvl2,
					hasLvl3: row.hasLvl3,
					isApprOrd1: row.ord1,
					isApprOrd2: row.ord2,
					isApprOrd3: row.ord3,
				}));

				const solpedWithUniqueDetails = result.map(solp => {
					const uniqueDetailsSet = new Set();
					const uniqueDetails = solp.details.filter(detail => {
						if (!uniqueDetailsSet.has(detail.ritem_iden)) {
							uniqueDetailsSet.add(detail.ritem_iden);
							return true;
						}
						return false;
					});

					return { ...solp, details: uniqueDetails };
				});

				const solpedWithUniqueOrdes = solpedWithUniqueDetails.map(solp => {
					const uniqueDocuSet = new Set();
					const uniqueOrdes = solp.ordes.filter(order => {
						if (!uniqueDocuSet.has(order.docu)) {
							uniqueDocuSet.add(order.docu);
							return true;
						}
						return false;
					});

					return { ...solp, ordes: uniqueOrdes };
				});

				const evalStat = (solp) => {
					if (solp.rejected) return 'Rechazado';
					if (!solp.requester) return 'Borrador';
					else if (solp.clos) return 'Cerrado';
					else if (solp.details.every(detail => detail.pend == 0)) return 'Recibido';
					else if (solp.ordes.some(orde => orde.stat === 'O')) return 'Comprando';
					else if (solp.ordes.every(orde => orde.stat === 'C')) return 'Comprado';
					else if (solp.approver) return 'Aprobado';
					else if (solp.requested) return 'Solicitado';
				};

				const solpedWithEstado = solpedWithUniqueOrdes.map(solp => ({
					...solp,
					stat: evalStat(solp),
				}));
				setSolped(solpedWithEstado);

			}

		} catch (error) {
			console.log('Error en loadSolped ', error);
		}

	};
	const setHighChartOption = () => {
		const estadosPendientes = ['Solicitado', 'Aprobado', 'Comprando', 'Comprado'];
		const otrosEstados = ['Cerrado', 'Recibido'];

		const pendientesMainData = {
			name: "Pendiente",
			y: estadosPendientes.reduce((acc, estado) => {
				const count = solped.filter(solp => solp.stat === estado).length;
				return acc + count;
			}, 0),
			color: '#09F',
			drilldown: 'Pendientes'
		};

		const otrosData = otrosEstados.map(est => ({
			name: est,
			y: solped.filter(solp => solp.stat === est).length,
			color: (est == 'Cerrado' ? '#666' : '#06B')
		}));

		const pendientesDrilldownData = {
			id: "Pendientes",
			name: "Pendientes",
			data: estadosPendientes.map(estado => ({
				name: estado,
				y: solped.filter(solp => solp.stat === estado).length,
				color: (estado == 'Solicitado' ? '#C00' : (estado == 'Aprobado' ? '#F60' : (estado == 'Comprando' ? '#FC0' : '#0A2')))
			})),
			events: {
				click: function (event) {
					setSelectedState(event.point.name);
				}
			}
		};

		const charOptions = {
			chart: { type: 'pie' },
			title: {
				text: 'Estado de solicitudes'
			},
			series: [{
				name: "Estados",
				data: [pendientesMainData, ...otrosData],
				size: '100%',
				events: {
					click: function (event) {
						setSelectedState(event.point.name);
					}
				}
			}],
			drilldown: {
				series: [pendientesDrilldownData],
			},
			tooltip: {
				headerFormat: '',
				pointFormat: '<b>{point.name}</b>: {point.y}<br/>'
			},
			plotOptions: {
				pie: {
					cursor: 'pointer',
					dataLabels: {
						enabled: true,
						format: '{point.name}: {point.y}',
						distance: -50,
						style: {
							color: 'white',
							textOutline: 'none',
							textDecoration: 'none'
						}
					},
					showInLegend: false
				}
			}
		};

		setOptions(charOptions);
	};



	return (
		<>
			<style>{
				`g.highcharts-data-labels.highcharts-series-0.highcharts-pie-series.highcharts-tracker > g.highcharts-label.highcharts-data-label.highcharts-data-label-color-0.highcharts-drilldown-data-label > text {
					color: white !important;
					fill: white !important;
					text-decoration: none !important;
					border: 1px solid black !important;
				
				}
				.highcharts-credits{
					display: none !important;
				}
			`}</style>
			<form>
				<main>
					<h1 data-icon=''>Estadísticas</h1>
					<nav>
						<article>
							{!solped ? ( // Mostrar la capa de carga mientras se están cargando los datos
								<p>Cargando...</p>
							) : (
									options.series && <HighchartsReact highcharts={Highcharts} options={options} />
							)}
						</article>
					</nav>
					{
						options.series && paginatedSolped && (
						<nav>
							<article>
									<h2 style={{ textAlign: 'center', display: 'block' }}>{  (!selectedState ? 'Pendiente' : selectedState) }</h2>
									<table>
										<tbody>
											{
												paginatedSolped.map((detail, index) => (
													<tr key={index} >
														<td>{detail.item_code} </td>
														<td className='ellipsis' > {detail.item_name} </td>
														<td>
															{detail.pend + '/' + detail.amou}
														</td>
													</tr>))
											}
										</tbody>
									</table>
								</article>
								<article><Paginator totalItems={totalFilteredItems} itemsPerPage={itemsPerPage} currentPage={currentPage} onPageChange={setCurrentPage} /> </article>
						</nav>)
					}
				</main>
				<aside>
					<article>
						<h2>Filtros </h2>
						<fieldset>
							<legend>Solicitud</legend>
							<input type="date" value={filtRequDateStart} onChange={(e) => { setFiltRequDateStart(e.target.value); }} />
							<input type="date" value={filtRequDateEnd} onChange={(e) => { setFiltRequDateEnd(e.target.value); }} />
						</fieldset>
					</article>
				</aside>
			</form>
		</>
	);
}

export default ListStat;