import React, { useEffect, useState } from 'react';
import Paginator from '../paginator';
import CreateUserDialog from './creaUser';
import ModifyUserDialog from './modUser';
import { useDispatch, useSelector } from 'react-redux';
import { apiurl } from '../../utils/constants';

function ListUser() {
	const [users, setUsers] = useState([]);
	const [posi, setPosi] = useState([]);
	const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
	const [isModifyDialogOpen, setIsModifyDialogOpen] = useState(false);
	const [editUserMail, setEditUserMail] = useState(null);
	const [editUserId, setEditUserId] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	
	const { mail,role  } = useSelector((state) => state.user);
	
	const [filterEmail, setFilterEmail] = useState('');
	const [filterName, setFilterName] = useState('');
	const [filterRole, setFilterRole] = useState('');
	const [filterYo, setfilterYo] = useState(false);
	const [order, setOrder] = useState('asc'); // Puede ser 'asc' o 'desc'
	const [orderBy, setOrderBy] = useState('name'); // Puede ser 'name' o 'role'
	const [totalFiltered, setTotalFiltered] = useState(0);

	const [sortedAndFilteredUsers, setSortedAndFilteredUsers] = useState([]);
	const [paginatedUsers, setPaginatedUsers] = useState([]);
	const updateUserList = async () => {
		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({table: 'sys__user',order: 'mail', user:mail})
			});
			
			if (response.ok) {
				const data = await response.json();

				const usersWithRole = data.map(user => {
					let roleText, colorCls = '';
					switch (user.role) {
						case -1:
							roleText = 'Inactivo';
							colorCls = '';
							break;
						case 0:
							roleText = 'Pendiente';
							colorCls = 'blu';
							break;
						case 1:
							roleText = 'Usuario';
							colorCls = 'gre';
							break;
						case 2:
							roleText = 'Reportador';
							colorCls = 'yel';
							break;
						case 3:
							roleText = 'Admin';
							colorCls = 'red';
							break;
					}
					return { ...user,roleText: roleText, colorCls: colorCls };
				});

				setUsers(usersWithRole); // Actualizar el estado con los datos recibidos
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	};

	const PosiList = async () => {  
		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ table:'sys__user U', field:' U.iden iden_user,P.name name_posi, C.name name_req ', order:' U.iden', 
				inner:` INNER JOIN sys__link L ON U.iden = L.user INNER JOIN sys__posi P ON L.posi = P.iden 
						INNER JOIN req__cent C ON P.iden = C.ord1 OR P.iden = C.ord2 OR P.iden = C.ord3 OR P.iden = C.pur1 OR P.iden = C.pur2 OR P.iden = C.pur3
						OR L.posi = (SELECT posi FROM req__assi WHERE role='soli' AND req__assi.cent=C.code LIMIT 1)
						`, 
				user:mail})
			});

			if (response.ok) {
				const data = await response.json();

				const dataFilt = data.map(posi => ({
					iden_user: posi.iden_user,
					name_posi: posi.name_posi,
					name_req: posi.name_req
				}));

				setPosi(dataFilt); // Actualizar el estado con los datos recibidos
			} else {
				console.error('Error al obtener los usuarios');
			}

		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	};

	useEffect(() => {
		updateUserList();
		PosiList();
	}, []);

	const openCreateDialog = () => {
		setIsCreateDialogOpen(true);
	};
	
	const closeCreateDialog = () => {
		setIsCreateDialogOpen(false);
	};
	
	const closeModifyDialog = () => {
		setIsModifyDialogOpen(false);
	};

	const handleEditUser = (userMail, userId) => {
		setIsModifyDialogOpen(true);
		setEditUserMail(userMail);
		setEditUserId(userId);
	};

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const sortComparator = (a, b) => {
		if (order === 'asc') {
			return a[orderBy].localeCompare(b[orderBy]);
		} else {
			return b[orderBy].localeCompare(a[orderBy]);
		}
	};

	useEffect(() => {

		const filteredUsers = users.filter(user => {
			const emailMatches = user.mail.toLowerCase().includes(filterEmail.toLowerCase());
			const nameMatches = user.name.toLowerCase().includes(filterName.toLowerCase());
			const roleMatches = filterRole === '' || user.role.toString() === filterRole;
			const userIsYo = filterYo && user.mail.toLowerCase().includes(mail.toLowerCase());
			return emailMatches && nameMatches && roleMatches && (filterYo ? userIsYo : true);
		});

		const startIndex = (currentPage - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;

		setPaginatedUsers(filteredUsers.sort(sortComparator).slice(startIndex, endIndex));
		setSortedAndFilteredUsers(filteredUsers);
		
	}, [users, currentPage, filterEmail, filterName, filterRole, filterYo, order, orderBy]);


	useEffect(() => {
		setTotalFiltered(sortedAndFilteredUsers.length);
	}, [sortedAndFilteredUsers]);

	
	return (
		<>
		<form>
			<main>
				<h1 data-icon=''>Usuarios</h1>
				<nav>
					<article>
						<table>
							<thead>
								<tr>
									<td></td>
									<td onClick={() => handleRequestSort('name')}>
										Datos personales {orderBy === 'name' && (
											<orm-icon>{order === 'asc' ? '':''}</orm-icon>
										)}
									</td>
									<td>Cargos</td>
									<td className='state' onClick={() => handleRequestSort('roleText')}>
										Sistema {orderBy === 'roleText' && (
											<orm-icon>{order === 'asc' ? '':''}</orm-icon>
										)}
									</td>
								</tr>
							</thead>
							<tbody>
								{paginatedUsers.map((user) => {
									
									const userPosiItems = posi.filter((posiItem) => posiItem.iden_user === user.iden);

									return(
										<tr key={user.mail} onClick={() => handleEditUser(user.mail, user.iden)} className={user.role === -1 ? 'disabled' : ''}>
											<td>
												<figure className='avatar' style={{ backgroundImage: `url(${user.avat})` }}></figure>
											</td>
											<td>
												<div>{user.name}</div>
												<a href={`mailto:${user.mail}`} onClick={(event) => event.stopPropagation()}>{user.mail}</a>
											</td>
											<td>{userPosiItems.map((data,index) => (
												<div key={index}>• <b>{data.name_posi}</b> en <b>{data.name_req}</b></div>
												))}</td>
											<td className={`state ${user.colorCls}`}>
												<mark>{user.roleText}</mark>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</article>
				</nav>
				<nav>
					<article><Paginator
						totalItems={totalFiltered}
						itemsPerPage={itemsPerPage}
						currentPage={currentPage}
						onPageChange={setCurrentPage}
					/>
					</article>
				</nav>
			</main>
			<aside>
			{role === 3 && (
				<article>
					<h2>Acciones</h2>
					<fieldset>
						<input type="button" onClick={openCreateDialog}  value="Crear usuario" />
					</fieldset>
				</article> )
			}
				<article>
					<h2>Atajos</h2>
					<fieldset>
							<a onClick={() => { setfilterYo(!filterYo); setCurrentPage(1); } }>Yo</a>
					</fieldset>
				</article>
				<article>
					<h2>Filtros</h2>
					<fieldset>
						<legend>Datos personales</legend>
							<input type="text" placeholder="E-mail" value={filterEmail} onChange={e => { setFilterEmail(e.target.value); setCurrentPage(1); }} />
							<input type="text" placeholder="Nombre" value={filterName} onChange={e => { setFilterName(e.target.value); setCurrentPage(1); }} />
					</fieldset>
					<fieldset>
						<legend>Sistema</legend>
							<select value={filterRole} onChange={e => { setFilterRole(e.target.value); setCurrentPage(1); }}>
							<option value="">Rol</option>
							<option value="-1">Inactivo</option>
							<option value="0">Pendiente</option>
							<option value="1">Usuario</option>
							<option value="2">Reportador</option>
							<option value="3">Admin</option>
						</select>
					</fieldset>
			</article>
			</aside>
		</form>
		{isCreateDialogOpen && <CreateUserDialog onUpdateUserList={updateUserList} onClose={closeCreateDialog} /> }
		{isModifyDialogOpen && <ModifyUserDialog editUserMail={editUserMail} editUserId={editUserId} onUpdateUserList={updateUserList} onClose={closeModifyDialog} /> }
		</>
	);
}

export default ListUser;