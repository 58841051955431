import React from 'react';

function Paginator({ totalItems, itemsPerPage, currentPage, onPageChange }) {

	const totalPages = Math.ceil(totalItems / itemsPerPage);

	const handlePageChange = (newPage) => {
		if (newPage >= 1 && newPage <= totalPages) {
			onPageChange(newPage);
		}
	};

	const pageOptions = Array.from({ length: totalPages }, (_, index) => index + 1);

	return (
		<table>
			<tbody>
			<tr>
				<td>{totalItems} Resultados</td>
				<td>
					<button type="button" onClick={() => handlePageChange(1)}></button>
					<button type="button" onClick={() => handlePageChange(currentPage - 1)}></button>
					<select value={currentPage} onChange={(event) => handlePageChange(Number(event.target.value))}>
						{pageOptions.map((page) => (
							<option key={page} value={page}>
							{page}
							</option>
						))}
					</select>
					<button type="button" onClick={() => handlePageChange(currentPage + 1)}></button>
					<button type="button" onClick={() => handlePageChange(totalPages)}></button>
				</td>
			</tr>
			</tbody>
		</table>
	);
}

export default Paginator;