import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'; // Importa Provider
import store from './store/store'; // Importa tu instancia de store

import App from './App';

ReactDOM.render(
	<Provider store={store}> {/* Envuelve App con Provider y pasa el store */}

		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')

);