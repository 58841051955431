import React, { useEffect,useState } from 'react';
import ModUserDialog from '../cc/editCC';
import { apiurl } from '../../utils/constants';
import { log } from '../../utils/functions';
import { useSelector } from 'react-redux';

function CreaPosi({ onListPosi,onClose }) {

	const [user,setUser] = useState({ userList: [] });
	const [name, setName] = useState('');
	const [errors, setErrors] = useState({ name: false });
	const [listUserDialog,setListUserDialog] = useState(false);
	const { iden: iuser, mail } = useSelector((state) => state.user);

	const handlerDel = (userId) => {
		if(window.confirm("¿Eliminar este usuario del cargo?")){
			const updatedUser = { ...user };
			updatedUser.userList = updatedUser.userList.filter((u) => u.iden !== userId);
			setUser(updatedUser);
		}
	};

	const onCreatePosi = async () => {  

		const newErrors = {};
		if (name === '') newErrors.name = true;  
		setErrors(newErrors);

		if (Object.values(newErrors).some(error => error)) {
			return;
		}

		try{

			const response = await fetch(apiurl+'tableINSE', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ 
					table: 'sys__posi', 
					fiels: `name`, 
					vals: `'${name}'`,
					lid: ` ,iden lid`,
					exist: `name='${name}'`, user:mail
				}),
			});
			if (response.ok) {
				const responseData = await response.json();
				if (responseData[0].error !== 1) {
					
					if(responseData[0].lid && user.userList.length){
						const vals = user.userList.map((user) => `${responseData[0].lid},${user.iden}`).join('),(');
						const secondResponse = await fetch(apiurl+'tableINSE', {
							method: 'POST',
							credentials: 'include',
							headers: {'Content-Type': 'application/json'},
							body: JSON.stringify({ 
								table: 'sys__link', 
								fiels: `posi, user`, 
								vals: vals, user:mail
							}),
						});

						if (secondResponse.ok) {
							log('add', 'posi', responseData[0].lid, mail, iuser );
							onListPosi();
							onClose();
						} else {
							throw new Error('Error en la segunda solicitud POST');
						}
					}else{
						log('add', 'posi', responseData[0].lid, mail, iuser);
						onListPosi();
						onClose();
					}

				}else{ 
					const newErrors = {};
					newErrors.name = true;  
					setErrors(newErrors);
				}
			} else {
				throw new Error('Error en la solicitud POST');
			}

		} catch (error) {
			console.error('Error al crear el cargo:', error);
		}

	}

	return (
		<>
		<form>
			<orm-modal>
			<dialog open>
				<h2>Creando cargo</h2>
				<table>
					<tbody>
						<tr>
							<td>Título:</td>
							<td><input type="text" value={name} onChange={(e) => setName(e.target.value)} className={errors.name ? 'error' : ''}/></td>
						</tr>
					</tbody>
				</table>
				<fieldset>
					<div>Usuarios:</div>
					<div className="scroll">
						<table>
							<tbody>
							{
								user.userList.map((user) => (
									<tr key={user.iden} onClick={() => handlerDel(user.iden)}>
										<td>
											<figure className='avatar' style={{ backgroundImage: `url(${user.avat})` }}></figure>
										</td>
										<td>
											<div className="deletable" >{user.name}</div>
											<a>{user.mail}</a>
										</td>
									</tr>
								))	
							}
							</tbody>
						</table>
					</div>
					<input type="button" value="Agregar usuarios" onClick={ ()=>setListUserDialog(true) } />
				</fieldset>
				<table>
					<tfoot>
						<tr>
							<td colSpan="2">
								<input type="button" value="Cancelar" onClick={onClose} />
								<input type="button" value="Aceptar" onClick={onCreatePosi} />
							</td>
						</tr>
					</tfoot>
				</table>
				
			</dialog>
			</orm-modal>
		</form>
		{ listUserDialog && <ModUserDialog onUpdateCCList={null} onEditCC={null} onClose={() => setListUserDialog(false)} onListUser={user} onSetListUser={setUser} /> }
		</>
	);

}

export default CreaPosi;

