import React, { useEffect, useState } from 'react';
import { apiurl } from '../utils/constants';
import { format } from 'date-fns';
import logoBailac from '../img/logo_bailac_m.png';

function DSP({ dsp, comp }) {

	const [idSolp, setIdSolp] = useState(0);
	const [solped, setSolped] = useState([]);
	const [total,setTotal] = useState(0);
	const [solpedNote, setSolpedNote] = useState([]);

	let DB = 'ormdb';

	switch (comp) {
		case "1": DB='ormdb__tian'; break;
		case "2": DB='ormdb__tsaan'; break;
		case "3": DB='ormdb__tlog'; break;
	
	}


	import('../styles/share.css');

	const loadSolpDeta = async () => {  

		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				headers: {'Content-Type': 'application/json'},
				credentials: 'include',
				body: JSON.stringify({
					user: '',
					comp:comp,
					table: `${DB}.req__item ritem`,
					where: ` and MD5(CONCAT('BailacORM',soli.iden)) = '${dsp}' `,
					inner: `LEFT JOIN ${DB}.inv__item iitem ON ritem.item=iitem.code 
							LEFT JOIN ${DB}.inv__stor store ON iitem.code=store.item AND store.ware=iitem.ware
							LEFT JOIN ${DB}.req__soli soli ON soli.iden=ritem.soli
							LEFT JOIN ${DB}.req__cent cent ON cent.code=soli.cent
							LEFT JOIN ${DB}.sys__user user ON soli.requester=user.iden`,
					order: 'iitem.name', 
					field: ` soli.entry, soli.docu,soli.iden iden_soli,iitem.code, iitem.name name_item, ritem.amou, iitem.unit, 
							 iitem.ware, store.hand store_hand, iitem.hand item_hand, ritem.cost,
							 ritem.iden ritem_iden,soli.approved,soli.approver, soli.requested, soli.requester, cent.name name_cent, 
							 user.name name_user, 
							 (SELECT name FROM sys__user WHERE iden=soli.approver) name_user_approver  `
					})
			});
			
			if (response.ok) {
				const data = await response.json();
				const idenSoliArray = data.map(item => item.iden_soli);

				setIdSolp(idenSoliArray[0]);
				setSolped(data);
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
		
	}

	const loadSolpNote = async () => {
		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					user: '',
					comp:comp,
					table: `${DB}.req__post`,
					order: 'req__post.iden', 
					where: ` and MD5(CONCAT('BailacORM',req__post.soli)) = '${dsp}' AND note=1`,
					inner: `INNER JOIN ${DB}.sys__user user ON req__post.auth=user.iden`,
					field: ` req__post.*, user.name auth_name `})
			});
			if (response.ok) {
				const data = await response.json();
				setSolpedNote(data[0]);
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}

	
	const fCurr = (value) => {
		return new Intl.NumberFormat('es-CL', {
			currency: 'CLP',
		}).format(value);
	};


	useEffect(() => {
		loadSolpDeta();
		loadSolpNote();
	}, []);

	useEffect(() => {
		let calculatedTotalCost = 0;
		solped.forEach((solped) => {
			const itemCost = solped.amou * solped.cost;
		  calculatedTotalCost += itemCost;
		});
		setTotal(calculatedTotalCost);
	}, [solped]);
	

	return (
		<div>
			<main>
				<header>
					<aside>
						<img src={logoBailac} />
						<h2>THOR INGENIERIA EN AHORRO DE NEUM</h2>
						<address>
							<div>Serrano 389, Piso 8</div>
							<div>1100000 IQUIQUE, CHILE</div>
						</address>
						<table>
							<tbody>
								<tr>
									<td>Teléfono</td>
									<td><a href="tel:+56572518301">572 518301</a></td>
								</tr>
								<tr>
									<td>E-mail</td>
									<td><a href="mailto:bailac@bailacthor.com">bailac@bailacthor.com</a></td>
								</tr>
								<tr>
									<td>Sitio web</td>
									<td><a href="http://www.bailac.com/">www.bailac.com</a></td>
								</tr>
							</tbody>
						</table>
					</aside>
					<table>
						<thead>
							<tr>
								<td colSpan='2'>
									<h1>
									<span>Solicitud de {solped?.[0]?.docu?'compra':'pedido'} <span className={solped?.[0]?.docu?'':'disabled'}>{solped?.[0]?.docu??idSolp}</span></span>
									</h1>
								</td>
								
					
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>Fecha de solicitud</th>
								<td>{ solped?.[0]?.requested? format(new Date(solped[0].requested), 'dd/MM/yyyy') : '' }</td>
							</tr>
							<tr>
								<th>Solicitante</th>
								<td>{ solped?.[0]? solped[0].name_user : '' }</td>
							</tr>
							<tr>
								<th>Centro de costos</th>
								<td>{ solped?.[0]? solped[0].name_cent : '' }</td>
							</tr>
							<tr>
								<th>Fecha de aprobación</th>
								<td>
									{solped && solped.length > 0 && solped[0]?.approved
									? format(new Date(solped[0].approved), 'dd/MM/yyyy')
									: ''}</td>
							</tr>
							<tr>
								<th>Aprobador</th>
								<td>{ solped?.[0]? solped[0].name_user_approver : '' }</td>
							</tr>
						</tbody>
					</table>
				</header>
				<section>
					<table>
						<thead>
							<tr>
								<th></th>
								<th>Artículos</th>
								<th>Precio unitario</th>
								<th>Cantidad</th>
								<th>Precio total</th>
							</tr>
						</thead>
						<tbody>
						{
							solped.map(solped => (
								<tr key={solped.ritem_iden}>
									<td>{solped.code}</td>
									<td>{solped.name_item}</td>
									<td>{fCurr(solped.cost)} CLP</td>
									<td>{solped.amou}</td>
									<td>{ fCurr( solped.amou * solped.cost ) } CLP</td>
								</tr>
							) ) 
						}
						</tbody>
						<tfoot>
							<tr>
								<td></td>
								<td></td>
								<th>Total</th>
								<td>{ fCurr(total) } CLP</td>
							</tr>
						</tfoot>
					</table>
				</section>
				<footer>
					<table>
						<tbody>
							<tr><th>Notas</th></tr>
							<tr><td>{ solpedNote? solpedNote.mess:'' }</td></tr>
						</tbody>
					</table>
				</footer>
			</main>
		</div>
	);
}

export default DSP;
