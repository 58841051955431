import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiurl } from '../../utils/constants';
import { log } from '../../utils/functions';

function CreateSolpComm ({ solp, onLoadSolpComm, onClose }){
	
	const [comm, setComm] = useState('');
	const [loadbtn, setLoadbtn] = useState(false);
	const [errors, setErrors] = useState({ comm: false });
	const [data, setData] = useState([]);
	const { iden, mail } = useSelector((state) => state.user);

	const handleCreateComm = async () => {
		setLoadbtn(true);
		const newErrors = {};
		if (comm === '') newErrors.comm = true;

		setErrors(newErrors);

		if (Object.values(newErrors).some(error => error)) {
			return;
		}

		try {
			const response = await fetch(apiurl+'tableINSE', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ 
					user:mail, 
					table: 'req__post', 
					fiels: `note, soli, publ, auth, mess`, 
					vals: `0,'${solp}',now(),'${iden}','${comm}'`,
					exist: `1=0`
				}),
			});

			if (response.ok) {
				const responseData = await response.json();
				setData(responseData);
				if (responseData[0].error !== 1) {
					log('mod', 'soli', solp, mail, iden, `add note ${solp}` );
					onClose();
					onLoadSolpComm(solp); 
				}else{
					console.log(responseData[0].msg);
					setLoadbtn(false);
				}
			} else {
				setLoadbtn(false);
				throw new Error('Error en la solicitud POST');
			}
		} catch (error) {
			setLoadbtn(false);
			console.error('Error al crear Nota:', error);
		}
	}

	return (
		<form>
			<orm-modal>
			<dialog open>
				<h2>Agregando mensaje</h2>
				<fieldset>
					<textarea value={comm} onChange={(e) => setComm(e.target.value)}  className={errors.comm || data[0]?.comm === 1 ? 'error' : ''}></textarea>
				</fieldset>
				<table>
					<tfoot>
						<tr>
							<td></td>
							<td>
								<input type="button" value="Cancelar" onClick={onClose} />
									<input type="button" value="Agregar" onClick={handleCreateComm} disabled={loadbtn} />
							</td>
						</tr>
					</tfoot>
				</table>
			</dialog>
			</orm-modal>
		</form>
	);

}

export default CreateSolpComm;