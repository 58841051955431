import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { apiurl } from '../../utils/constants';
import { sendMail, getCurrentDateTime, log } from '../../utils/functions';


function PublSolp({ solp, onClose, onRefresh, totalCost, missware, serviceCostZero, cent }) {

	const [solpData, setSolpData] = useState([]);
	const [share, setShare] = useState({});
	const { iden, name, mail } = useSelector((state) => state.user);
	const [appr, setAppr] = useState({ iden: '', mail: '' });

	const currentDateTime = getCurrentDateTime();

	const loadSolpData = async() => {
		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					user: mail,
					query: `SELECT inv.name, req.amou, req.cost, req.item, MD5(CONCAT('BailacORM',iden)) share, MD5(CONCAT('orm',${solp})) ormshare
							FROM req__item req
							INNER JOIN inv__item inv ON inv.code=req.item
							WHERE soli=${solp}`
				})
			});

			if (response.ok) {
				const data = await response.json();
				setSolpData(data);
				setShare({ share: data[0].share, ormshare: data[0].ormshare });
				
			} else {
				console.error('Error al obtener los CC');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}

	const handlePublSolp = async () => {

		try {
			const response = await fetch(apiurl + 'tablePUT', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					user: mail,
					table: `req__soli`,
					fields: `requester = ${iden},requested = now(), rejected=null `,
					iden: `iden = ${solp}`
				})
			});

			if (response.ok) {
				log('mod', 'soli', solp, mail, iden, `publ solp ${solp}`);
				searchApprovers(cent.code);
				onClose();
				onRefresh();
			} else {
				console.error('Error al obtener los CC');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}

	const searchApprovers = async (cc) => {
	
		try{
			const response2 = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					user: mail,
					query: `SELECT * FROM ( 
								SELECT md5(user.iden) iden, user.mail
								FROM req__cent cent 
								LEFT JOIN sys__link link ON cent.ord1=link.posi 
								LEFT JOIN sys__user user ON link.user=user.iden
								WHERE cent.code=${cc} AND user.mail IS NOT NULL 
								UNION ALL
								SELECT md5(user.iden) iden, user.mail
								FROM req__appr appr
								INNER JOIN sys__user user ON appr.user=user.iden
								WHERE appr.soli=${solp}) as tbl`
				})
			});

			if (response2.ok) {
				const data2 = await response2.json();
				let to, emailToIdMap;

				if (appr.iden){
					to = [appr.iden];
					emailToIdMap = {
						[appr.mail]: appr.iden,
					};

				}else{ 
					to = data2.map((item) => item.mail);
					emailToIdMap = data2.reduce((accumulator, user) => {
						accumulator[user.mail] = user.iden;
						return accumulator;
					}, {});
				}
				// console.log('to: '+to);
				// console.log(emailToIdMap);
				// console.log('share: '+share.share);
				// console.log('ormshare: ' +share.ormshare);

				const tablaHTML = `
					<table style="border-collapse: collapse; width: 50%;">
						<tbody>
						${solpData.map(item => `
							<tr>
								<td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${item.name}</td>
								<td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${item.amou}</td>
							</tr>
						`).join('')}
						</tbody>
					</table>
				`;
				
				const currentUrl = window.location.origin; // Obtiene la URL actual del navegador

				// Crear el mensaje con enlaces aprobatorios personalizados
				const mensajes = to.map((email) => `
					Se ha generado la solicitud de pedido ${solp} con los siguientes elementos:
					<br><br>
					${tablaHTML}
					<br>
					Puede ver la solicitud completa aquí: ${currentUrl}?sp=${share.share}
					<br><br>
					Puede aprobar esta solicitud <a href=${currentUrl}?approve=${emailToIdMap[email]}&orm=${share.ormshare}>aquí</a>.
					<br><br>
				`);

				// Enviar el correo a cada destinatario
				mensajes.forEach((mensaje, index) => {
					// sendMail([to[index]], `La solicitud de pedido ${solp} espera su aprobación.`, mensaje);
					sendMail([to[index]], `Se ha generado una solicitud por su centro de costo.`, mensaje);

				});

			} else {
				console.error('Error en searchApprovers queryAux');
			}
		} catch (error) {
			console.error('Error en la searchApprovers:', error);
		}
		
	}

	const reqappr = async ()=>{
		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					user: mail,
					query:`SELECT sys__user.iden,mail FROM req__appr INNER JOIN sys__user ON req__appr.user=sys__user.iden WHERE soli=${solp}`
				})
			});

			if (response.ok) {
				const data = await response.json();
				if (data[0]) setAppr({ iden: data[0].iden, mail: data[0].mail });
				loadSolpData();
			} else {
				console.error('Error al obtener los CC');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}


	useEffect(() => {
		reqappr();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<form>
			<orm-modal>
				<dialog open>
					<h2>Publicando solicitud de pedido</h2>
					<table>
						<tbody>
							<tr>
								<td>Centro de costo:</td>
								<td>{cent.name}</td>
							</tr>
							<tr>
								<td>Solicitado:</td>
								<td>{currentDateTime}</td>
							</tr>
							<tr>
								<td>Solicitante:</td>
								<td>{name}</td>
							</tr>
						</tbody>
					</table>
					{
						(missware || serviceCostZero > 0) &&
						<div className="red">
							<h2>Error</h2>
							<p data-icon="">{
								missware 
								? `Hay ${missware} elementos solicitados que no tienen almacén definido.` 
								: `Hay ${serviceCostZero} servicios solicitados que no tienen precio definido.`
							}</p>
							<p>{
								missware
								? 'Seleccione un almacén para todos los elementos solicitados.'
								: 'Ingrese un precio para todos los servicios solicitados.'
							}</p>
						</div>
					}
					<table>
						<tfoot>
							<tr>
								<td></td>
								<td>
									<input type="button" value="Cancelar" onClick={onClose} />
									<input type="button" value="Aceptar" onClick={handlePublSolp} disabled={(missware || serviceCostZero || !share.share) > 0?true:false} />
								</td>
							</tr>
						</tfoot>
					</table>
				</dialog>
			</orm-modal>
		</form>
	);

}

export default PublSolp;