import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
	name: 'user',
	initialState:{
		iden: '',
		mail: '',
		name: '',
		avat: '',
		back: '',
		dark: false,
		role: '',
		comp: '',
		sapDB: '',
	},
	reducers: {
		setUserData: (state, action) => {
			const { iden, mail, name, avat, back, dark, role, comp, sapDB } = action.payload;
			state.iden = iden;
			state.mail = mail;
			state.name = name;
			state.avat = avat;
			state.back = back;
			state.dark = dark;
			state.role = role;
			state.comp = comp;
			state.sapDB = sapDB;
		},
	},
});

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;