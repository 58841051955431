import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiurl } from '../../utils/constants';
import { log } from '../../utils/functions';

function CreateSolpNote ({ solp, onLoadSolpNote, onClose }){
	
	const [note, setNote] = useState('');
	const [loadbtn, setLoadbtn] = useState(false);
	const [errors, setErrors] = useState({ note: false });
	const [data, setData] = useState([]);
	const { iden, mail } = useSelector((state) => state.user);
	const maxCharSolpedComm=255;

	const handleCreateNote = async () => {

		setLoadbtn(true);
		const newErrors = {};
		if (note === '') newErrors.note = true;

		setErrors(newErrors);

		if (Object.values(newErrors).some(error => error)) {
			return;
		}

		try {
			const response = await fetch(apiurl+'tableINSE', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({
					user: mail, 
					table: 'req__post', 
					fiels: `note, soli, publ, auth, mess`, 
					vals: `1,'${solp}',now(),'${iden}','${note}'`,
					exist: `auth=${iden} AND note=1 AND soli=${solp}`
				}),
			});

			if (response.ok) {
				const responseData = await response.json();
				setData(responseData);
				if (responseData[0].error !== 1) {
					log('mod', 'soli', solp, mail, iden, `add comm ${solp}` );
					onClose();
					onLoadSolpNote(solp); 
				}else{ 
					console.log(responseData[0].msg);
					setLoadbtn(false);
				}
			} else {
				setLoadbtn(false);
				throw new Error('Error en la solicitud POST');
			}
		} catch (error) {
			setLoadbtn(false);
			console.error('Error al crear Nota:', error);
		}
	}

	return (
		<form>
			<orm-modal>
			<dialog open>
					<h2>Agregando comentario {`${note ? `(${note.length}/${maxCharSolpedComm})` : ''}`} </h2>
				<fieldset>
					<textarea value={note} 
						onChange={(e) => {
							const newNote = e.target.value;
							if (newNote.length > maxCharSolpedComm) {
								alert(`SAP no permite más de ${maxCharSolpedComm} caracteres.`);
							} else {
								setNote(newNote);
							}
						}}	
						className={errors.note || data[0]?.note === 1 ? 'error' : ''}></textarea>
				</fieldset>
				<table>
					<tfoot>
						<tr>
							<td></td>
							<td>
								<input type="button" value="Cancelar" onClick={onClose} />
								<input type="button" value="Agregar"  onClick={handleCreateNote} disabled={loadbtn}/>
							</td>
						</tr>
					</tfoot>
				</table>
			</dialog>
			</orm-modal>
		</form>
	);

}

export default CreateSolpNote;