
import React, { useEffect, useState } from 'react';
import { apiurl, wsurl } from '../../utils/constants';
import { log } from '../../utils/functions';
import { useSelector } from 'react-redux';

function CreateSolpArtcDialog({ iden, cent, onLoadSolpDeta,onClose }) {
	
	const [article, setArticle] = useState([]);
	const [garticle, setGArticle] = useState([]);
	const [loadBtn, setLoadBtn] = useState(false);
	const { iden: iuser, comp, mail } = useSelector((state) => state.user);

	const [filterCode, setFilterCode] = useState('');
	const [filterGName, setFilterGName] = useState('');
	const [isGArticleLoaded, setIsGArticleLoaded] = useState(false);
	const [debouncedFilterCode, setDebouncedFilterCode] = useState('');
	const [debouncedFilterGName, setDebouncedFilterGName] = useState('');
	const [filterCodeTimeout, setFilterCodeTimeout] = useState(null);
	const [filterGNameTimeout, setFilterGNameTimeout] = useState(null);

	const [quantities, setQuantities] = useState({});

	const listArticle = async () => {
		try {

			let whereClause = '';

			if (debouncedFilterCode) {
				whereClause += ` AND (item.code like '%${debouncedFilterCode}%' OR item.name like '%${debouncedFilterCode}%')`;
			}

			if (debouncedFilterGName && debouncedFilterGName!== "Grupo") {
				whereClause += ` AND grou.grou like '%${debouncedFilterGName}%'`;
			}

			const response = await fetch(apiurl, { 
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail, table: 'inv__item item',order: 'item.name', 
				inner: `INNER JOIN inv__grou grou ON item.grou=grou.numb`,
				field: ` item.code, item.name, grou.grou grou_name, item.hand, item.ware, item.cost,
						(
							SELECT SUM(amou) FROM req__item
							JOIN req__soli ON req__item.soli=req__soli.iden AND req__soli.cent=${cent}
							WHERE take AND item=item.code
						) soli,
						( SELECT COUNT(1) FROM req__item WHERE soli=${iden} AND item=item.code AND LEFT(item, 3) NOT REGEXP '^[A-Za-z]' LIMIT 1 ) exis,
						item.orde, item.comm `,
				where: whereClause,
				limit: ` LIMIT 100`})
			});
			
			if (response.ok) {
				const data = await response.json();
				setArticle(data);
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}

	function nameGroup(articulos) {
		const nombresUnicos = [...new Set(articulos.map((articulo) => articulo.grou_name))];
		return nombresUnicos.sort();
	}

	
	const handleCreate = async () => {
		try {
			setLoadBtn(true);	

			const quantitiesArray = Object.keys(quantities).map((code) => ({
				item: code,x: quantities[code], ware: article[article.findIndex((articleItem) => articleItem.code === code)].ware
			}));
			
			const valuesArray = quantitiesArray.map((item) => `null,${iden},'${item.item}',${item.ware !== null ? `'${item.ware}'` : 'null'},${item.x},1,${item.x}, (SELECT cost FROm inv__item WHERE code='${item.item}')`);
			const valuesString = valuesArray.join('),(');

			const response = await fetch(apiurl+'tableINSE', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ user:mail, 
					table: 'req__item', 
					fiels: `iden, soli, item, ware, amou, take, pend, cost`, 
					vals: valuesString
				})
			});
	  
			if (response.ok) {
				quantitiesArray.forEach((item) => {
					log('mod', 'soli', iden, mail, iuser, `add item ${item.item}` );
				})
				onLoadSolpDeta(iden);
				onClose();

			} else {
				setLoadBtn(false);
				console.error('Error al enviar los artículos');
			}
		} catch (error) {
			setLoadBtn(false);
			console.error('Error en la solicitud:', error);
		}
	}
	const sincItem = async () => {
		const ws = new WebSocket(wsurl + '?db=' + comp);
		const sincButton = document.getElementById('sincButton');
		ws.addEventListener('open', function open() {
			console.log('connected');
			sincButton.disabled = true;
			sincButton.value = 'Sincronizando...';
		});
		ws.addEventListener('message', function(event) {
			console.log('Message from server ', event.data);
			if(event.data === 'start') {
				sincButton.value = 'Sincronización iniciada';
			}else if(event.data === 'done'){
				ws.close();
				sincButton.value = 'Sincronizado';
				setTimeout(() => {
					sincButton.value = 'Sincronizar artículos';
					sincButton.disabled = false;
				}, 3000);
			}else{
				sincButton.value = 'Sincronizando '+event.data+' artículos';
			}
		});
		ws.addEventListener('error', function(event) {
			console.log('Error:', event);
			sincButton.value = 'Error!';
		});
	}

	useEffect(() => {
		listArticle();
	}, [debouncedFilterCode, debouncedFilterGName]);

	useEffect(() => {
		if (!isGArticleLoaded && article.length > 0) {
		  const groups = nameGroup(article);
		  setGArticle(groups);
		  setIsGArticleLoaded(true);
		}
	}, [article, isGArticleLoaded])

	return (
		<form>
		<orm-modal>
		<dialog open>
			<h2>Agregando artículo a {cent}</h2>
			<h2>
				<span>Filtro</span>
				<input type="search" placeholder="Artículo"  value={filterCode} 
				onChange={(e) => {
				setFilterCode(e.target.value);
				clearTimeout(filterCodeTimeout);
				const timeoutId = setTimeout(() => {
				  setDebouncedFilterCode(e.target.value);
				}, 300);
				setFilterCodeTimeout(timeoutId);
			  }} />
				<select id="groups" 
				onChange={(e) => {
				setFilterGName(e.target.value);
				clearTimeout(filterGNameTimeout);
				const timeoutId = setTimeout(() => {
					setDebouncedFilterGName(e.target.value);
				}, 300);
				setFilterGNameTimeout(timeoutId);
				}}>
					<option key=''>Grupo</option>
					{ garticle?.map(garticle => ( <option key={garticle}>{garticle}</option> )) }
				</select>
			</h2>
			<div className="scroll">
			<table>
				<thead>
					<tr>
						<td>Código</td>
						<td>Artículo</td>
						<td className="state">Existencias</td>
						<td>Cantidad</td>
					</tr>
				</thead>
				<tbody>
				{
						article?.map(article => ( 
						<tr key={article.code}>
							<td>{article.code}</td>
							<td>
								<b>{article.name}</b>
								<div>{article.grou_name}</div>
							</td>
							<td className={`state ${article.hand === 0 ? 'red' : ''}`}>
								<time>Actual: {article.hand}</time>
								<time>Solicitado: {article.soli ?? 0}</time>
								<time>Comprado: {article.orde}</time>
								<time>Vendido: {article.comm}</time>
							</td>
							<td>
							
							{	
								article.exis ? (<div>Artículo ya agregado</div>) :
								(<input type="number" min="0" value={quantities[article.code] || ''}
									onChange={(e) => {
										const parsedValue = parseInt(e.target.value, 10);
										if (parsedValue > 0) {
											const updatedQuantities = { ...quantities };
											updatedQuantities[article.code] = parsedValue;
											setQuantities(updatedQuantities);
										} else {
											const updatedQuantities = { ...quantities };
											delete updatedQuantities[article.code];
											setQuantities(updatedQuantities);
										}
									}} />
								)
							
							}	
							</td>
						</tr>
					))
				}
				</tbody>
			</table>
			</div>
			<table>
				<tfoot>
				<tr>
					<td style={{textAlign:'left'}}>
						<input type="button" id='sincButton' value="Sincronizar artículos" onClick={sincItem} />
					</td>
					<td>
						<input type="button" value="Cancelar" onClick={onClose} />
						<input type="button" value="Aceptar" onClick={handleCreate} disabled={loadBtn}/>
					</td>
				</tr>
				</tfoot>
			</table>
		</dialog>
		</orm-modal>
		</form>
	);


}

export default CreateSolpArtcDialog;