
import { apiurl } from './constants';

const setFiltDate = (valDate, type = 'start') =>{
	const newVal = new Date(valDate);
	if (type=='start') newVal.setHours(0, 0, 0, 0);
	else newVal.setHours(23, 59, 59, 999);
	newVal.setDate(newVal.getDate() + 1);
	return newVal;
}

const fetchData = async (endpoint, query, mail) => {
	try {
		const response = await fetch(apiurl + endpoint, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			credentials: 'include',
			body: JSON.stringify({ query, user: mail }),
		});

		if (response.ok) {
			const data = await response.json();
			return data.length > 0 ? data : null;
		} else {
			throw new Error('Error en la solicitud POST');
		}
	} catch (error) {
		console.error('Error:', error);
		return null;
	}
};

const postData = async (endpoint, payload) => {
	try {
		const response = await fetch(apiurl + endpoint, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			credentials: 'include',
			body: JSON.stringify(payload),
		});

		if (response.ok) {
			const data = await response.json();
			return data ? data : null;
		} else {
			throw new Error('Error en la solicitud POST');
		}
	} catch (error) {
		console.error('Error:', error);
		return null;
	}
};

const log = async (acti, modu, affe, user, iuser, data = null, sapDB=null) => {
	try{
		const response = await fetch(apiurl+'log', {
			method: 'POST',
			credentials: 'include',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
				acti:acti,
				modu:modu,
				affe:affe,
				data:data,
				user:user,
				iuser:iuser,
				sapDB:sapDB
			})
		});

		if (response.ok) {
			const data = await response.json();
			return data.length > 0 ? data : null;
		} else {
			return null;
		}

	}catch(error){
		console.error('Error:', error);
		return null;
	}
}

const sendMail = async (to,subject,mensaje) => {
	try {

		const response = await fetch(apiurl + 'sendMail', {
			method: 'POST',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				to:to,
				subject:subject,
				html: mensaje
			})
		});
		if (response.ok) {
			const data = await response.json();
			return data.length > 0 ? data : null;
		} else {
			throw new Error('Error sendMail');
		}
	} catch (error) {
		console.error('Error en la solicitud:', error);
		return null;
	}
}

const fCurr = (value) => {
	return new Intl.NumberFormat('es-CL', {
		currency: 'CLP',
	}).format(value);
};


const isService = (code) => {
	if (!code) return false;
	return /^[A-Za-z]+$/.test(code.substring(0, 2));
};


const getCurrentDateTime = () => {
	const now = new Date();
	const year = now.getFullYear();
	const month = String(now.getMonth() + 1).padStart(2, '0');
	const day = String(now.getDate()).padStart(2, '0');

	return `${day}/${month}/${year}`;
};

const approveSolped = async (solp,iden,mail,sapDB,from='web') => {
	let ordByCost = [];

	const searchApproverForTotalCost = async () => {

		try {
			const response = await fetch(apiurl + 'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					user: mail,
					sapDB: sapDB,
					query: `SELECT 
							(SELECT CASE WHEN (SELECT SUM(cost*amou) FROM req__item WHERE soli=${solp}) < ord1 THEN 1 WHEN (SELECT SUM(cost*amou) FROM req__item WHERE soli=${solp}) < ord2 THEN 2 ELSE 3 END FROM req__tier) ord,
							(SELECT user FROM req__appr WHERE soli=${solp}) as appr,
							(SELECT approver FROM req__auth WHERE soli=${solp} AND level=1) as hasLvl1,
							(SELECT approver FROM req__auth WHERE soli=${solp} AND level=2) as hasLvl2,
							(SELECT approver FROM req__auth WHERE soli=${solp} AND level=3) as hasLvl3,    
							(SELECT mail FROM sys__user WHERE iden = (SELECT user FROM req__appr WHERE soli=${solp}) ) AS appr_mail,
							(SELECT mail FROM sys__user WHERE iden = (SELECT requester FROM req__soli WHERE iden=${solp}) ) AS mail_requ,
							(SELECT GROUP_CONCAT(DISTINCT userOrd1.mail) 
								FROM req__cent cent LEFT JOIN sys__link linkOrd1 ON cent.ord1 = linkOrd1.posi LEFT JOIN sys__user userOrd1 ON linkOrd1.user = userOrd1.iden 
								WHERE code = (SELECT cent FROM req__soli WHERE iden=${solp}) GROUP BY code
							) AS mail,
							(SELECT GROUP_CONCAT(DISTINCT userOrd2.mail) 
								FROM req__cent cent LEFT JOIN sys__link linkOrd2 ON cent.ord2 = linkOrd2.posi LEFT JOIN sys__user userOrd2 ON linkOrd2.user = userOrd2.iden 
								WHERE code = (SELECT cent FROM req__soli WHERE iden=${solp}) GROUP BY code
							) AS mail2,
							(SELECT GROUP_CONCAT(DISTINCT userOrd3.mail) 
								FROM req__cent cent LEFT JOIN sys__link linkOrd3 ON cent.ord3 = linkOrd3.posi LEFT JOIN sys__user userOrd3 ON linkOrd3.user = userOrd3.iden 
								WHERE code = (SELECT cent FROM req__soli WHERE iden=${solp}) GROUP BY code
							) AS mail3, ( SELECT MD5(CONCAT('BailacORM',${solp})) ) share`
				})
			});

			if (response.ok) {
				const data = await response.json();
				ordByCost = data[0];
				let stat = await approverForLevel();
				return stat;
			}

		} catch (error) {
			console.error('Error en la searchApproversForTotalCost:', error);
			return 1;
		}
	}

	const approverForLevel = async () => {
		try {
			if (ordByCost.ord == 1 && !ordByCost.hasLvl1)return await sendAprobSap(1);

			else if (ordByCost.ord == 2) {

				if (!ordByCost.hasLvl1) {

					if (ordByCost.mail2.split(",").includes(mail)) {
						await insertReqAuthByLvl(1, false);
						return await sendAprobSap(2);
					}
					else return await insertReqAuthByLvl(1);
				}
				else if (ordByCost.hasLvl1 && !ordByCost.hasLvl2 && ordByCost.mail2.split(",").includes(mail)){ return await sendAprobSap(2);}
				else return 3;
			} else if (ordByCost.ord == 3) {

				if (!ordByCost.hasLvl1) {

					if (ordByCost.mail2.split(",").includes(mail)) {
						await insertReqAuthByLvl(1, false);
						return await insertReqAuthByLvl(2);
					}
					else return await insertReqAuthByLvl(1);
				}
				else if (!ordByCost.hasLvl2) {
					if (ordByCost.mail3.split(",").includes(mail)) {
						await insertReqAuthByLvl(2, false);
						return await sendAprobSap(3);
					}
					else return await insertReqAuthByLvl(2);
				}
				else if (ordByCost.hasLvl1 && ordByCost.hasLvl2 && !ordByCost.hasLvl3 && ordByCost.mail3.split(",").includes(mail)) return await sendAprobSap(3);
				else return 3;
			}else{ 
				console.log(`Error al aprobar por lvl`);
				return 1;
			}
			
		} catch (error) {
			console.error('Error en la solicitud:', error);
			throw error;

		}
	}

	const sendAprobSap = async (lvl) => {
		try {
			const response = await fetch(apiurl + 'sapRequest', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify({ solp: solp, user: mail, sapDB: sapDB, iuser: iden }),
			});
			if (response.ok) {
				const dataSAP = await response.json();
				if (dataSAP.stat == -1) {
					alert('Error en ORM\n ' + dataSAP.msg);
				} else if (dataSAP.stat) {

					if (dataSAP.msg.indexOf('Date deviates from permissible range') !== -1) {
						alert(dataSAP.msg + '\nError obteniendo respuesta de SAP\nLa fecha de hoy esta fuera de los rangos definidos en SAP.\nsolicite que se amplien los rangos para incluir la fecha de hoy.');
					} else if (dataSAP.msg.indexOf('Update the exchange rate') !== -1) {
						alert(dataSAP.msg + '\nError obteniendo respuesta de SAP\nNo hay tipo de cambio definido en SAP para hoy,\nsolicite que se defina un tipo de cambio.');
					} else if (dataSAP.msg.indexOf('inactive') !== -1) {
						alert(dataSAP.msg + '\nError obteniendo respuesta de SAP\nEste artículo está inactivo,\nsolicite su activación para poder solicitarlo.');
					} else {
						alert(dataSAP.msg + '\nError obteniendo respuesta de SAP');
					}
				} else if (dataSAP.message) {

					try {
						const response = await fetch(apiurl + 'tablePUT', {
							method: 'POST',
							credentials: 'include',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({
								user: mail,
								sapDB: sapDB,
								table: `req__soli`,
								fields: `approver = ${iden},approved = now()`,
								iden: `iden = ${solp}`
							})
						});

						if (response.ok) {
							log('mod', 'soli', solp, mail, iden, `approved via ${from}`, sapDB);
							return await insertReqAuthByLvl(lvl);
						} else {
							console.error('Error al obtener los CC');
						}
					} catch (error) {
						console.error('Error en la solicitud:', error);
						throw error;
					}
				}

			}
			return 1;
		} catch (error) {
			console.error('Error:', error);
			throw error;
		}
		
	}

	const insertReqAuthByLvl = async (lvl, send = true) => {
		try {
			const response = await fetch(apiurl + 'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					user: mail,
					sapDB: sapDB,
					query: `INSERT INTO req__auth (soli, level, approved, approver) VALUES (${solp}, ${lvl}, now(), ${iden})`
				})
			})

			if (response.ok) {
				if (send) sendMailByLvl(lvl);
				return 2;
			} else {
				console.error('Error en insertReqAuthByLvl');
				return 1;
			}
		} catch (error) {
			console.error('Error en insertReqAuthByLvl:', error);
			throw error;
		}
	};

	const sendMailByLvl = async (lvl) => {
		const currentUrl = window.location.origin;
		let to, subj, mess;

		if (lvl !== ordByCost.ord) {
			to = (lvl === 1 ? ordByCost.mail2 : (lvl === 2 ? ordByCost.mail3 : null));
			// subj = `La solicitud de pedido ${solp} requiere su aprobación.`
			subj = `Se ha generado una solicitud por su centro de costo.`
			mess = `La solicitud de pedido ${solp}, requiere su aprobación.<br><br>Puede ver la solicitud aquí: ${currentUrl}?sp=${ordByCost.share}`;
		} else {
			to = (lvl === 1 ? (ordByCost.appr_mail ?? ordByCost.mail) : (lvl === 2 ? ordByCost.mail2 : ordByCost.mail3)) + `, ${ordByCost.mail_requ}`;
			subj = `La solicitud de pedido ${solp} ha sido aprobada.`
			mess = `Se ha aprobado la solicitud de pedido ${solp}.<br><br>Puede ver la solicitud aquí: ${currentUrl}?sp=${ordByCost.share}`;
		}

		if (to) {
			sendMail(to, subj, mess)
		}
	}


	return await searchApproverForTotalCost();


}

const apiTokenSap = async (sapDB) => {
	try {
		const response = await fetch(apiurl + 'sapLoginWeb', {
			method: 'POST',
			credentials: 'include',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ sapDB: sapDB })
		});

		if (response.ok) {
			const data = await response.json();
			return data.token;
		}

	} catch (error) {
		console.error('Error en la solicitud:', error);
		throw error;
	}
}

export { fetchData, postData, log, sendMail, fCurr, isService, getCurrentDateTime, setFiltDate, approveSolped, apiTokenSap };