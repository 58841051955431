import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiurl } from '../../utils/constants';
import { log } from '../../utils/functions';

function ModifyUserDialog({ editUserMail, editUserId, onUpdateUserList, onClose }) {
	
	const { iden: iuser,role,mail } = useSelector((state) => state.user);
	const [formData, setFormData] = useState({
		mail: '',
		name: '',
		role: '-1'
	});

	const findUser = async () => {
		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				headers: {'Content-Type': 'application/json'},
				credentials: 'include',
				body: JSON.stringify({table: 'sys__user', order:' mail', where: `and mail='${editUserMail}'`, user:mail})
			});
			
			if (response.ok) {
				const responseData = await response.json();
				setFormData(responseData[0]); // Asignar el primer objeto de responseData
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	};

	useEffect(() => {
		findUser();
	}, []);

	const handleModUser = async () => {
		try {
			const response = await fetch(apiurl+'tablePUT', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({table: 'sys__user', fields:` role=${formData.role}`, iden: ` mail='${editUserMail}'`, user:mail})
			});
			
			if (response.ok) {
				const responseData = await response.json();
				if(!responseData.error){
					log('mod', 'user', editUserId, mail, iuser, ` role=${formData.role}` );
					onClose();
					onUpdateUserList(); 
				}
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData(prevData => ({
			...prevData,
			[name]: value
		}));
	}

	return (
		<form>
			<orm-modal>
			<dialog open>
				<h2>Modificando usuario rol: {role}</h2>
				<table>
					<tbody>
						<tr>
							<td><label>Email:</label></td>
							<td>{formData.mail}</td>
						</tr>
						<tr>
							<td><label>Nombre:</label></td>
							<td>{formData.name}</td>
						</tr>
						<tr>
							<td><label>Rol:</label></td>
							<td>{role === 3 && formData.role < 3 ? (
								<select name="role" value={formData.role} onChange={handleInputChange}>
									<option value="-1">Inactivo</option>
									<option value="1">Usuario</option>
									<option value="2">Reportador</option>
								</select>
								) : ( formData.role === 1 ? 'Usuario' :
											formData.role === 2 ? 'Reportador' :
											formData.role === 3 ? 'Admin' :
											formData.role === -1 ? 'Inactivo' :
											'') 
								} 
							</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="2">
								<input type="button" value="Cancelar" onClick={onClose} />
								<input type="button" value="Aceptar" onClick={handleModUser} />
							</td>
						</tr>
					</tfoot>
				</table>
			</dialog>
			</orm-modal>
		</form>
	);
}

export default ModifyUserDialog;