import React, { useEffect, useState, useRef } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { apiurl } from '../../utils/constants';


function ListocDeta({ onMenuItemClick, oc, solped }) {


	const [data, setData] = useState([]);
	const [dataBD, setDataBD] = useState([]);
	const [loadSAP, setLoadSAP] = useState('');
	const [stat, setStat] = useState('');
	const [total,setTotal] = useState(0);
	const [unit, setUnit] = useState([]);
	const { iden: iuser, mail, sapDB } = useSelector((state) => state.user);

	const ocData = async () => {
		try {
			setLoadSAP('Obteniendo...');
			const response = await fetch(apiurl + 'sapPostQueryOC', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify({ user:mail, sapDB, oc }),
			});
	
			if (response.ok) {
				const dataSAP = await response.json();
				
				setData(dataSAP[oc]);
			  
				let calculatedTotalCost = 0;
				let calculateStat='';
				dataSAP[oc].forEach((item) => {
					calculatedTotalCost += item.Cost;
					calculateStat = (calculateStat=='O'?'O':item.DocumentStatus);
				});
				setTotal(calculatedTotalCost);
				setStat(calculateStat);
				setLoadSAP('');

			} else {
				console.error('Error sapPostQueryOC');
				setLoadSAP('No se pudo obtener');
			}
		} catch (error) {
			console.error('Error:', error);
			return null;
		}

	}

	const ocDataBD = async () => {
		try {
			const response = await fetch(apiurl + 'queryAux', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				credentials: 'include',
				body: JSON.stringify({ 
					user:mail, 
					query: `SELECT req__orde.*, req__soli.docu, req__soli.requested 
							FROM req__orde 
							INNER JOIN req__soli ON req__soli.entry=req__orde.entr AND req__orde.docu=${oc}` 
				}),
			});

			if (response.ok) {
				const data = await response.json();
				setDataBD(data);
			}
		} catch (error) {
			console.error('Error:', error);
			return null;
		}
	}

	const fCurr = (value) => {
		return new Intl.NumberFormat('es-CL', {
			currency: 'CLP',
		}).format(value);
	};

	useEffect(() => {   
		ocData();
		ocDataBD();
	}
	,[]);


	return (
		<>
		<form>
			<style jsx="true">{`
				.itemTable .ellipsis{
					max-width: 500px;
				}
				.itemTable td:where(:last-child, :nth-child(3)){
					text-align: right;
				}
			`}</style>

			<main>
				<h1 className={ loadSAP ? '' : stat=='O'? 'red':'gre' } data-icon=''>
					<span>Orden de compra <span>{oc}</span></span>
					<mark >{ loadSAP ? loadSAP : stat=='O'? 'Abierta':'Cerrada' }</mark>
				</h1>
				<nav>
				
					<section>
						<h2><div>Ordenado</div><div>{dataBD?.[0]?.sent ? format(new Date(dataBD[0].sent), 'dd/MM/yyyy') : ''}</div></h2>
						{
							<fieldset>
								<legend>Proveedor</legend>
								<a>{ dataBD?.[0]?.card }</a>
							</fieldset>
						}
					</section>
					<section>
						<h2><div>Actualizado</div><div>{dataBD?.[0]?.done ? format(new Date(dataBD[0].done), 'dd/MM/yyyy') : ''}</div></h2>
						{
							<fieldset>
								<legend>Comentario</legend>
								<a>{ dataBD?.[0]?.comm }</a>
							</fieldset>
						}
					</section>
					<section>
						<h2><div>Solicitud de compra</div><div></div></h2>
						{
							<fieldset>
								<table>
									<tbody>
										<tr>
											<td>SC {dataBD?.[0]?.docu}</td>
											<td>{dataBD?.[0]?.requested ? format(new Date(dataBD[0].requested), 'dd/MM/yyyy') : ''}</td>
											<td><a><figure onClick={ ()=>onMenuItemClick('listSolpDeta',solped) }></figure></a></td>
										</tr>
									</tbody>
								</table>
							</fieldset>
						}
					</section>
					
					<article className='scrollx'>
							{
								data.length === 0 ? 
								( <p>No hay artículos.</p> ) : 
								(<table className='itemTable'>
									<thead>
										<tr>
											<td>Código</td>
											<td className='ellipsis'>Descripción</td>
											<td>Precio</td>
											<td>Cantidad</td>
											<td>Almacén</td>
											<td>Costo</td>
										</tr>
									</thead>
									<tbody>
									{
										data.map(item => (
											<tr key={item.ItemCode}>
												<td>{item.ItemCode}</td>
												<td className='ellipsis'>{item.ItemDescription}</td>
												<td>{fCurr(item.Price)} CLP</td>
												<td>{item.Quantity} {item.Unit }</td>
												<td>{item.WarehouseCode}</td>
												<td>{fCurr(item.Cost)} CLP</td>
											</tr>
										))
									}
									</tbody>
								</table>)
							}
					</article>
					<h2>
						<div></div>
						<div>{fCurr(total)} CLP</div>
					</h2>
				</nav>
			</main>
			<aside>
				<article>
					<h2>Acciones</h2>
					<fieldset>
						<input type="button" onClick={ ()=>onMenuItemClick('listSolpDeta',solped,solped) } value="Volver a solped" />
					</fieldset>
				</article>
			</aside>
		</form>
		</>
	);

}


export default ListocDeta;