import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { apiurl } from '../../utils/constants';
import { getCurrentDateTime, approveSolped } from '../../utils/functions';

function AproSolp({ solp, onClose, onRefresh, missware, serviceCostZero }) {

	const [data, setData] = useState([]);
	const [stat, setStat] = useState({0:'Aceptar',1:false});
	const { iden, name, mail, sapDB } = useSelector((state) => state.user);

	const currentDateTime = getCurrentDateTime();

	const loadData = async () => {

		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ 
					user:mail,
					table: 'req__soli soli',
					inner: "LEFT JOIN req__cent cent ON soli.cent = cent.code",
					where: ` AND soli.iden = ${solp}`,
					order: '1',
					field: `cent.name name_cent, cent.code code_cent, MD5(CONCAT('BailacORM',iden)) share`
				})
			});

			if (response.ok) {
				const data = await response.json();
				setData(data);
			} else {
				console.error('Error al obtener los CC');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}

	}


	const handleAproSolp = async () => {
		setStat({0:'Aprobando..',1:true});

		try {
			const stat = await approveSolped(solp,iden,mail,sapDB);
			console.log(stat);
			if(stat === 2){
				onClose();
				onRefresh();
			}else if(stat === 1 ){
				const response = await fetch(apiurl+'queryAux', {
					method: 'POST',
					credentials: 'include',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ 
						user:mail,
						query: `UPDATE req__soli SET approving = null WHERE iden = ${solp}`
					})
				})
				if(response.ok){
					setStat({ 0:'Aceptar',1:false});
				}
			}
		} catch (error) {
			setStat({ 0: 'Aceptar', 1: false });
			console.log('Error al aprobar:', error);
		}
	}
	


	useEffect(() => {
		loadData();
		if (missware || serviceCostZero) {
			setStat({ 0: 'Aceptar', 1: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<form>
			<orm-modal>
				<dialog open>
					<h2>Aprobando solicitud de pedido</h2>
					<table>
						<tbody>
							<tr>
								<td>Centro de costo:</td>
								<td>{data ? data[0]?.name_cent : ''}</td>
							</tr>
							<tr>
								<td>Aprobado:</td>
								<td>{currentDateTime}</td>
							</tr>
							<tr>
								<td>Aprobador:</td>
								<td>{name}</td>
							</tr>
							{
								stat[1] && !missware && !serviceCostZero && (
									<tr>
										<td colSpan="2">
											<div>
												<img style={{ display: 'block', margin: 'auto' }} src="https://i.imgur.com/shx40TO.gif" alt="" />
											</div>
											<div style={{ textAlign: 'center' }}>
												Esperando a SAP...
											</div>
										</td>
									</tr>)
							}
						</tbody>
					</table>
					{
						(missware || serviceCostZero > 0) &&
						<div className="red">
							<h2>Error</h2>
							<p data-icon="">{
								missware
									? `Hay ${missware} elementos solicitados que no tienen almacén definido.`
									: `Hay ${serviceCostZero} servicios solicitados que no tienen precio definido.`
							}</p>
							<p>{
								missware
									? 'Seleccione un almacén para todos los elementos solicitados.'
									: 'Ingrese un precio para todos los servicios solicitados.'
							}</p>
						</div>
					}
					<table>
						<tfoot>
							<tr>
								<td></td>
								<td>
									<input type="button" value="Cancelar" onClick={onClose} />
									<input type="button" value={stat[0]} disabled={stat[1]} onClick={handleAproSolp} />
								</td>
							</tr>
						</tfoot>
					</table>
				</dialog>
			</orm-modal>
		</form>
	);

}

export default AproSolp;