import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Paginator from '../paginator';
import { useSelector } from 'react-redux';
import { apiurl } from '../../utils/constants';
import { setFiltDate } from '../../utils/functions';
import { ExportToExcel } from '../../utils/exportxlsx';
import { Tooltip } from 'react-tooltip'

function ListTrac() {

	const [reqItem, setReqItem] = useState([]);
	const [exportXls, setExportXls] = useState([]);
	const [filtSolped, setFiltSolped] = useState([]);
	const { iden:user,role, mail } = useSelector((state) => state.user);

	//Filtros Solicitud
	const [listCC, setListCC] = useState([]);
	const [filterCC, setFilterCC] = useState('');
	const [filterCode, setFilterCode] = useState('');
	const [startDateSolp, setStartDateSolp] = useState('');
	const [endDateSolp, setEndDateSolp] = useState('');
	const [filterState, setFilterState] = useState('');
	const [filtUrge, setFiltUrge] = useState(false);

	//Filtros Artículo
	const [filterCodeArti, setFilterCodeArti] = useState('');
	const [filterDescArti, setFilterDescArti] = useState('');

	//Filtros Orden de compra
	const [filterCodeOrde, setFilterCodeOrde] = useState('');
	const [startDateOrde, setStartDateOrde] = useState('');
	const [endDateOrde, setEndDateOrde] = useState('');

	//Filtros Entrada de mercancía
	const [filterCodeEntr, setFilterCodeEntr] = useState('');
	const [startDateEntr, setStartDateEntr] = useState('');
	const [endDateEntr, setEndDateEntr] = useState('');

	//Filtros Salida de mercancía
	const [filterCodeSali, setFilterCodeSali] = useState('');
	const [startDateSali, setStartDateSali] = useState('');
	const [endDateSali, setEndDateSali] = useState('');


	const [totalFiltered, setTotalFiltered] = useState(0);
	const [sortedAndFiltered, setSortedAndFiltered] = useState([]);
	const [sortedAndFilteredXLS, setSortedAndFilteredXLS] = useState([]);
	const [paginatedSolp, setPaginatedSolp] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const [order, setOrder] = useState('desc'); // Puede ser 'asc' o 'desc'
	const [orderBy, setOrderBy] = useState('docu'); // Puede ser 'name' o 'role'
	const [orderByXLS, setOrderByXLS] = useState('Solicitud de compra'); // Puede ser 'name' o 'role'


	useEffect(() => {
		filtSolp();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		loadReqItem();
		loadDataForExport();
		listFiltCC();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtSolped])

	const loadReqItem = async() =>{
		const response = await fetch(apiurl + 'queryAux', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				user: mail,
				query: `SELECT cent.name,soli.docu,soli.iden AS soli,soli.requested,iitem.code, soli.urge,
						iitem.name AS namei,ritem.amou, ritem.fixe, ritem.text ,orde_info,mentr_info,mexit_info,
						CASE
							WHEN ritem.pend = 0 THEN 'Recibido'
							WHEN mentr_info.entr IS NOT NULL THEN 'Comprado'
							WHEN orde_info.entr IS NOT NULL THEN 'Comprando'
							WHEN soli.approved IS NOT NULL THEN 'Aprobado'
							ELSE 'Solicitado'
						END AS state
						FROM req__item ritem
						INNER JOIN inv__item iitem ON ritem.item = iitem.code
						INNER JOIN req__soli soli ON ritem.soli = soli.iden
						INNER JOIN req__cent cent ON soli.cent = cent.code
						LEFT JOIN (
							SELECT orde_docu.docu,orde_docu.entr,orde_docu.item, orde_docu.orde,
							JSON_ARRAYAGG(JSON_OBJECT('orde', orde_docu.docu,'orde_date', orde_docu.sent,'prov', req__orde.prov,'card', req__orde.card,'comm', req__orde.comm)) AS orde_info
							FROM req__move orde_docu
							LEFT JOIN req__orde ON orde_docu.docu = req__orde.docu
							WHERE orde_docu.type = 'orde'
							GROUP BY orde_docu.entr, orde_docu.item
						) orde_info ON orde_info.item = ritem.item AND orde_info.entr = soli.entry
						LEFT JOIN (
							SELECT entr_docu.entr,entr_docu.item,JSON_ARRAYAGG(JSON_OBJECT('mentr', entr_docu.docu,'mentr_date', entr_docu.sent)) AS mentr_info
							FROM req__move entr_docu
							WHERE entr_docu.type = 'entr'
							GROUP BY entr_docu.entr, entr_docu.item
						) mentr_info ON mentr_info.item = ritem.item AND mentr_info.entr = orde_info.orde
						LEFT JOIN (
							SELECT exit_docu.entr,exit_docu.item,JSON_ARRAYAGG(JSON_OBJECT('mexit', exit_docu.docu,'mexit_date', exit_docu.sent)) AS mexit_info
							FROM req__move exit_docu
							WHERE exit_docu.type = 'exit'
							GROUP BY exit_docu.entr, exit_docu.item
						) mexit_info ON mexit_info.item = ritem.item AND mexit_info.entr = soli.entry
						WHERE ritem.soli IS NOT NULL
						AND soli.docu IS NOT NULL
						${role < 3 ? 'AND soli.iden IN (' + filtSolped.map(item => item.iden).join(', ') + ')' : ''}
						ORDER BY soli.docu DESC`
			}),
			credentials: 'include',
		});


		if (response.ok) {
			const data = await response.json();

			if (Array.isArray(data)) {

				const result = data.map(row => {

					return {
						name: row.name,
						docu: row.docu,
						soli: row.soli,
						fixe: row.fixe,
						text: row.text,
						requested: row.requested,
						code: row.code,
						namei: row.namei,
						amou: row.amou,
						orde_info: JSON.parse(row.orde_info),
						mentr_info: JSON.parse(row.mentr_info),
						mexit_info: JSON.parse(row.mexit_info),
						state: row.state,
						urge: row.urge
					};
				});
				setReqItem(result);

			}
		}
		
	}

	const loadDataForExport = async () => {
		const response = await fetch(apiurl + 'queryAux', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				user: mail,
				query: `SELECT cent.name,soli.docu,soli.iden AS soli,soli.requested,iitem.code, soli.urge, ritem.fixe, ritem.text,
						iitem.name AS namei,ritem.amou,orde_info,mentr_info,mexit_info,
						CASE
							WHEN ritem.pend = 0 THEN 'Recibido'
							WHEN mentr_info.entr IS NOT NULL THEN 'Comprado'
							WHEN orde_info.entr IS NOT NULL THEN 'Comprando'
							WHEN soli.approved IS NOT NULL THEN 'Aprobado'
							ELSE 'Solicitado'
						END AS state
						FROM req__item ritem
						INNER JOIN inv__item iitem ON ritem.item = iitem.code
						INNER JOIN req__soli soli ON ritem.soli = soli.iden
						INNER JOIN req__cent cent ON soli.cent = cent.code
						LEFT JOIN (
							SELECT orde_docu.docu,orde_docu.entr,orde_docu.item, orde_docu.orde,JSON_ARRAYAGG(JSON_OBJECT('orde', orde_docu.docu,'orde_date', orde_docu.sent, 'prov', req__orde.prov,'card', req__orde.card,'comm', req__orde.comm)) AS orde_info
							FROM req__move orde_docu
							LEFT JOIN req__orde ON orde_docu.docu = req__orde.docu
							WHERE orde_docu.type = 'orde'
							GROUP BY orde_docu.entr, orde_docu.item
						) orde_info ON orde_info.item = ritem.item AND orde_info.entr = soli.entry
						LEFT JOIN (
							SELECT entr_docu.entr,entr_docu.item,JSON_ARRAYAGG(JSON_OBJECT('mentr', entr_docu.docu,'mentr_date', entr_docu.sent)) AS mentr_info
							FROM req__move entr_docu
							WHERE entr_docu.type = 'entr'
							GROUP BY entr_docu.entr, entr_docu.item
						) mentr_info ON mentr_info.item = ritem.item AND mentr_info.entr = orde_info.orde
						LEFT JOIN (
							SELECT exit_docu.entr,exit_docu.item,JSON_ARRAYAGG(JSON_OBJECT('mexit', exit_docu.docu,'mexit_date', exit_docu.sent)) AS mexit_info
							FROM req__move exit_docu
							WHERE exit_docu.type = 'exit'
							GROUP BY exit_docu.entr, exit_docu.item
						) mexit_info ON mexit_info.item = ritem.item AND mexit_info.entr = soli.entry
						WHERE ritem.soli IS NOT NULL
						AND soli.docu IS NOT NULL
						${role < 3 ? 'AND soli.iden IN (' + filtSolped.map(item => item.iden).join(', ') + ')' : ''}
						ORDER BY soli.docu DESC;
						`
			}),
			credentials: 'include',
		});

		if (response.ok) {
			const data = await response.json();
			if (Array.isArray(data)) {
				const result = data.map(row => {

					const ordeInfo = JSON.parse(row.orde_info || '[]');
					const mentrInfo = JSON.parse(row.mentr_info || '[]');
					const mexitInfo = JSON.parse(row.mexit_info || '[]');
					
					const prov = ordeInfo.map(info => info.prov).join('\n');
					const card = ordeInfo.map(info => info.card).join('\n');
					const comm = ordeInfo.map(info => info.comm).join('\n');
					const ordeValues = ordeInfo.map(info => info.orde).join('\n');
					const ordeDateValues = ordeInfo.map(info => info.orde_date.substring(0, 10)).join('\n');
					const mentrValues = mentrInfo.map(info => info.mentr).join('\n');
					const mexitValues = mexitInfo.map(info => info.mexit).join('\n');
					const mentrDateValues = mentrInfo.map(info => info.mentr_date.substring(0, 10)).join('\n');
					const mexitDateValues = mexitInfo.map(info => info.mexit_date.substring(0, 10)).join('\n');


					return {
						'Centro de costo': row.name,
						'Solicitud de compra': row.docu,
						'Solicitado': row?.requested?.substring(0, 10),
						'Código': row.code,
						'Activo fijo': row.fixe,
						'Descripción': row.namei,
						'Texto libre': row.text,
						'Cantidad': row.amou,
						'Número de OC': ordeValues,
						'Fecha de OC': ordeDateValues,
						'Rut de proveedor': prov,
						'Proveedor': card,
						'Comentario': comm,
						'Número de EM': mentrValues,
						'Fecha de EM': mentrDateValues,
						'Número de SM': mexitValues,
						'Fecha de SM': mexitDateValues,
						'Estado': row.state,
						'Urge': row.urge,
					};
				});

				setExportXls(result);
			}

		}
	}

	const filtSolp = async() =>{
		
		const response = await fetch(apiurl + 'queryAux', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				user: mail,
				query: `SELECT soli.iden
						FROM req__soli soli
						INNER JOIN req__assi assi ON soli.cent=assi.cent AND assi.role='soli'
						INNER JOIN req__cent cent ON soli.cent=cent.code
						WHERE 
						assi.posi IN (SELECT posi FROM sys__link WHERE user=${user})
						OR soli.iden IN (SELECT soli FROM req__appr WHERE user=${user})
						OR cent.ord1 IN (SELECT posi FROM sys__link WHERE user=${user})
						OR cent.ord2 IN (SELECT posi FROM sys__link WHERE user=${user})
						OR cent.ord3 IN (SELECT posi FROM sys__link WHERE user=${user})
						OR cent.pur1 IN (SELECT posi FROM sys__link WHERE user=${user})
						OR cent.pur2 IN (SELECT posi FROM sys__link WHERE user=${user})
						OR cent.pur3 IN (SELECT posi FROM sys__link WHERE user=${user})`
			}),
			credentials: 'include',
		});

		if (response.ok) {
			const data = await response.json();
			setFiltSolped(data);
		}
	}

	const listFiltCC = async () => {
		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					table: `req__cent cent`,
					order: ` name`, user: mail
				})
			});

			if (response.ok) {
				const data = await response.json();
				const result = data.map(row => ({
					code: row.code,
					name: row.name,
				}));

				setListCC(result); // Actualizar el estado con los datos recibidos
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleRequestSortXLS = (property) => {
		const isAsc = orderByXLS === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderByXLS(property);
	};

	const sortComparator = (a, b) => {
		const valueA = a[orderBy];
		const valueB = b[orderBy];

		if (typeof valueA === 'string' && typeof valueB === 'string') {
			return order === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
		} else if (typeof valueA === 'number' && typeof valueB === 'number') {
			return order === 'asc' ? valueA - valueB : valueB - valueA;
		} else {
			return typeof valueA.localeCompare === 'function' ? -1 : 1;
		}
	};

	const sortComparatorXLS = (a, b) => {
		const valueA = a[orderByXLS];
		const valueB = b[orderByXLS];

		if (typeof valueA === 'string' && typeof valueB === 'string') {
			return order === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
		} else if (typeof valueA === 'number' && typeof valueB === 'number') {
			return order === 'asc' ? valueA - valueB : valueB - valueA;
		} else {
			return typeof valueA.localeCompare === 'function' ? -1 : 1;
		}
	};

	function getClassForState(state) {
		switch (state) {
			case 'Recibido':
				return 'blu';
			case 'Comprado':
				return 'gre';
			case 'Comprando':
				return 'yel';
			case 'Aprobado':
				return 'ora';
			case 'Solicitado':
				return 'red';
			default:
				return 'red';
		}
	}


	useEffect(() => {

		const filteredReqItem = reqItem.filter((item) => {
			const filterCCCondition = filterCC ? item.name.toLowerCase().includes(filterCC.toLowerCase()) : true;
			const filterCodeCondition = filterCode ? item.docu.toString().toLowerCase().includes(filterCode.toLowerCase()) : true;
			const startDateCondition = startDateSolp ? new Date(item.requested) >= setFiltDate(startDateSolp, 'start') : true;
			const endDateCondition = endDateSolp ? new Date(item.requested) <= setFiltDate(endDateSolp, 'end') : true;
			const filterStateCondition = filterState ? item.state.toLowerCase() === filterState.toLowerCase() : true;
			const filterCodeArtiCondition = filterCodeArti ? item.code.toLowerCase().includes(filterCodeArti.toLowerCase()) : true;
			const filterDescArtiCondition = filterDescArti ? item.namei.toLowerCase().includes(filterDescArti.toLowerCase()) : true;
			const filterCodeOrdeCondition = filterCodeOrde ? item.orde_info && item.orde_info.some(info => info.orde && info.orde.toString().toLowerCase().includes(filterCodeOrde.toLowerCase())) : true;
			const startDateOrdeCondition = startDateOrde ? item.orde_info && item.orde_info.some(info => info.orde_date && new Date(info.orde_date) >= setFiltDate(startDateOrde, 'start')) : true;
			const endDateOrdeCondition = endDateOrde ? item.orde_info && item.orde_info.some(info => info.orde_date && new Date(info.orde_date) <= setFiltDate(endDateOrde, 'end')) : true;
			const filterCodeEntrCondition = filterCodeEntr ? item.mentr_info && item.mentr_info.some(info => info.mentr && info.mentr.toString().toLowerCase().includes(filterCodeEntr.toLowerCase())) : true;
			const startDateEntrCondition = startDateEntr ? item.mentr_info && item.mentr_info.some(info => info.mentr_date && new Date(info.mentr_date) >= setFiltDate(startDateEntr, 'start')) : true;
			const endDateEntrCondition = endDateEntr ? item.mentr_info && item.mentr_info.some(info => info.mentr_date && new Date(info.mentr_date) <= setFiltDate(endDateEntr, 'end')) : true;
			const filterCodeSaliCondition = filterCodeSali ? item.mexit_info && item.mexit_info.some(info => info.mexit && info.mexit.toString().toLowerCase().includes(filterCodeSali.toLowerCase())) : true;
			const startDateSaliCondition = startDateSali ? item.mexit_info && item.mexit_info.some(info => info.mexit_date && new Date(info.mexit_date) >= setFiltDate(startDateSali, 'start')) : true;
			const endDateSaliCondition = endDateSali ? item.mexit_info && item.mexit_info.some(info => info.mexit_date && new Date(info.mexit_date) <= setFiltDate(endDateSali, 'end')) : true;
			const filterUrge = filtUrge ? item.urge === 1 : true;

			// Aplicar todos los filtros
			return filterCCCondition && filterCodeCondition && startDateCondition && endDateCondition && filterStateCondition && filterCodeArtiCondition
				&& filterDescArtiCondition && filterCodeOrdeCondition && startDateOrdeCondition && endDateOrdeCondition && filterCodeEntrCondition && startDateEntrCondition
				&& endDateEntrCondition && filterCodeSaliCondition && startDateSaliCondition && endDateSaliCondition && filterUrge;

		});

		const filteredReqItemXls = exportXls.filter((item) => {
			const filterCCCondition = filterCC ? item['Centro de costo'].toLowerCase().includes(filterCC.toLowerCase()) : true;
			const filterCodeCondition = filterCode ? item['Solicitud de compra'].toString().toLowerCase().includes(filterCode.toLowerCase()) : true;
			const startDateCondition = startDateSolp ? new Date(item['Solicitado']) >= new Date(startDateSolp) : true;
			const endDateCondition = endDateSolp ? new Date(item['Solicitado']) <= new Date(endDateSolp) : true;
			const filterStateCondition = filterState ? item['Estado'].toLowerCase() === filterState.toLowerCase() : true;
			const filterCodeArtiCondition = filterCodeArti ? item['Código'].toLowerCase().includes(filterCodeArti.toLowerCase()) : true;
			const filterDescArtiCondition = filterDescArti ? item['Descripción'].toLowerCase().includes(filterDescArti.toLowerCase()) : true;
			const filterCodeOrdeCondition = filterCodeOrde ? item['Número de OC'] && item['Número de OC'].toString().toLowerCase().includes(filterCodeOrde.toLowerCase()) : true;
			const startDateOrdeCondition = startDateOrde ? item['Fecha de OC'] && new Date(item['Fecha de OC']) >= new Date(startDateOrde) : true;
			const endDateOrdeCondition = endDateOrde ? item['Fecha de OC'] && new Date(item['Fecha de OC']) <= new Date(endDateOrde) : true;
			const filterCodeEntrCondition = filterCodeEntr ? item['Número de EM'] && item['Número de EM'].toString().toLowerCase().includes(filterCodeEntr.toLowerCase()) : true;
			const startDateEntrCondition = startDateEntr ? item['Fecha de EM'] && new Date(item['Fecha de EM']) >= new Date(startDateEntr) : true;
			const endDateEntrCondition = endDateEntr ? item['Fecha de EM'] && new Date(item['Fecha de EM']) <= new Date(endDateEntr) : true;
			const filterCodeSaliCondition = filterCodeSali ? item['Número de SM'] && item['Número de SM'].toString().toLowerCase().includes(filterCodeSali.toLowerCase()) : true;
			const startDateSaliCondition = startDateSali ? item['Fecha de SM'] && new Date(item['Fecha de SM']) >= new Date(startDateSali) : true;
			const endDateSaliCondition = endDateSali ? item['Fecha de SM'] && new Date(item['Fecha de SM']) <= new Date(endDateSali) : true;
			const filterUrge = filtUrge ? item['Urge'] === 1 : true;

			// Aplicar todos los filtros
			return filterCCCondition && filterCodeCondition && startDateCondition && endDateCondition && filterStateCondition && filterCodeArtiCondition
				&& filterDescArtiCondition && filterCodeOrdeCondition && startDateOrdeCondition && endDateOrdeCondition && filterCodeEntrCondition && startDateEntrCondition
				&& endDateEntrCondition && filterCodeSaliCondition && startDateSaliCondition && endDateSaliCondition && filterUrge;
		});

		const startIndex = (currentPage - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;


		setPaginatedSolp(filteredReqItem.sort(sortComparator).slice(startIndex, endIndex));

		setSortedAndFiltered(filteredReqItem);
		setSortedAndFilteredXLS(filteredReqItemXls.sort(sortComparatorXLS));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order, orderBy, reqItem,listCC, currentPage,
		filterCC,
		filterCode,
		startDateSolp,
		endDateSolp,
		filterState,
		filterCodeArti,
		filterDescArti,
		filterCodeOrde,
		startDateOrde,
		endDateOrde,
		filterCodeEntr,
		startDateEntr,
		endDateEntr,
		filterCodeSali,
		startDateSali,
		endDateSali, filtUrge])


	useEffect(() => {
		setTotalFiltered(sortedAndFiltered.length);
	}, [sortedAndFiltered]);

	useEffect(() => {
		setCurrentPage(1);
	}, [filterCC,
		filterCode,
		startDateSolp,
		endDateSolp,
		filterState,
		filterCodeArti,
		filterDescArti,
		filterCodeOrde,
		startDateOrde,
		endDateOrde,
		filterCodeEntr,
		startDateEntr,
		endDateEntr,
		filterCodeSali,
		startDateSali,
		endDateSali])
	

	return (
		<>
		<form>
			<main>
				<h1 data-icon=''>Seguimiento</h1>
				<nav>
					<article>
					<table>
						<thead>
						<tr>
							<td onClick={() => {
								handleRequestSort('name');
								handleRequestSortXLS('Centro de costo');
							}}> Centro de costo {orderBy === 'name' && (<orm-icon>{order === 'asc' ? '' : ''}</orm-icon>)} </td>
							<td onClick={() => { 
								handleRequestSort('docu'); 
								handleRequestSortXLS('Solicitud de compra') 
							}}> Solicitud {orderBy === 'docu' && (<orm-icon>{order === 'asc' ? '' : ''}</orm-icon>)} </td>
							<td>Articulo</td>
							<td>Cantidad</td>
							<td>Orden de C.</td>
							<td>Entrada de M.</td>
							<td>Salida de M.</td>
							<td>Estado</td>
						</tr>
						</thead>
						<tbody>
						{
							paginatedSolp.map((solp) => (
								<tr key={`${solp.soli}_${solp.code}_${Math.floor(Math.random() * 10000)}`} >
									{/* Centro de costo */}
									<td className={`ellipsis border_row ${solp.urge ? 'yel' : ''}`} style={{ maxWidth: 120 }} title={solp.name}>{solp.name}</td>
									{/* Solicitud */}
									<td>
										<b>{solp.docu}</b>
										<div>{solp.requested ? format(new Date(solp.requested), 'dd/MM/yyyy') : '--/--/----'}</div>
									</td>
									{/* Articulo */}
									<td className='ellipsis' style={{ maxWidth: 320 }} title={solp.namei}>
										<b>{solp.code} {solp.fixe && `(${solp.fixe})`}</b>
										<div>{solp.namei}</div>
										{solp.text && <div style={{ fontStyle: 'italic' }} >({solp.text})</div> }
									</td>
									{/* Cantidad */}
									<td>{solp.amou}</td>
									{/* Orden de compra */}
									<td>
										{
											Array.isArray(solp.orde_info) && solp.orde_info.map((ordeItem, index) => (
												<div key={index}>
													<b>{ordeItem.orde} <figure className='blu' data-tooltip-id="Tt1" data-tooltip-html={`<b>Proveedor ${ordeItem.prov}</b><br>${ordeItem.card}`} ></figure> <figure className='blu' data-tooltip-id="Tt1" data-tooltip-html={`<b>Comentario</b><br>${ordeItem.comm}`} ></figure></b>
													<div>{ordeItem.orde_date ? format(new Date(ordeItem.orde_date), 'dd/MM/yyyy') : ''}</div>
												</div>
											))
										}
										<Tooltip id="Tt1" events={['click']} className='tooltip_trac' style={{ 'pointer-events': 'initial' }}/>
									</td>
									{/* Entrada de mercancía */}
									<td>
										{
											Array.isArray(solp.mentr_info) && solp.mentr_info.map((mentrFnfo, index) => (
												<div key={index}>
													<b>{mentrFnfo.mentr}</b>
													<div>{mentrFnfo.mentr_date ? format(new Date(mentrFnfo.mentr_date), 'dd/MM/yyyy') : ''}</div>
												</div>
											))
										}
									</td>
									{/* Salida de mercancía */}
									<td>
										{
											Array.isArray(solp.mexit_info) && solp.mexit_info.map((mexitInfo, index) => (
												<div key={index}>
													<b>{mexitInfo.mexit}</b>
													<div>{mexitInfo.mexit_date ? format(new Date(mexitInfo.mexit_date), 'dd/MM/yyyy') : ''}</div>
												</div>
											))
										}
									</td>
									{/* Estado */}
									<td className={`state ${getClassForState(solp.state)}`}><mark>{ solp.state }</mark></td>
								</tr>
							))
						}
						</tbody>
					</table>
					</article>
				</nav>
				<nav>
						<article><Paginator totalItems={totalFiltered} itemsPerPage={itemsPerPage} currentPage={currentPage} onPageChange={setCurrentPage} /> </article>
				</nav>
				</main>
				<aside>
					<article>
						<h2>Acciones</h2>
						<fieldset>
							<div className="ellipsis">
								<ExportToExcel apiData={sortedAndFilteredXLS} fileName={"Seguimiento"} />
							</div>
						</fieldset>
					</article>
					<article>
						<h2>Filtros</h2>
						<fieldset>
							<legend>Solicitud</legend>
							<input type="search" placeholder="Centro de costos" list="cent" value={filterCC} onChange={e => setFilterCC(e.target.value)} />
							<datalist id="cent">
								{
									listCC.map(cc => (
										<option key={cc.code} value={cc.name} />
									))
								}
							</datalist>
							<input type="search" placeholder="Código" onChange={e => setFilterCode(e.target.value)} />
							<input type="date" value={startDateSolp} onChange={(e) => setStartDateSolp(e.target.value)} />
							<input type="date" value={endDateSolp} onChange={(e) => setEndDateSolp(e.target.value)} />
							<select value={filterState} onChange={e => setFilterState(e.target.value)} >
								<option value=''>Estado</option>
								<option value='Solicitado'>Solicitado</option>
								<option value='Aprobado'>Aprobado</option>
								<option value='Comprando'>Comprando</option>
								<option value='Comprado'>Comprado</option>
								<option value='Recibido'>Recibido</option>
							</select>
							<input id="urgency" type="checkbox" checked={filtUrge} onChange={e => { setFiltUrge(!filtUrge); }} />
							<label htmlFor="urgency"> Solo urgencias</label>

						</fieldset>

						<fieldset>
							<legend>Artículo</legend>
							<input type="search" placeholder="Código" onChange={e => setFilterCodeArti(e.target.value)} />
							<input type="search" placeholder="Descripción" onChange={e => setFilterDescArti(e.target.value)} />
						</fieldset>

						<fieldset>
							<legend>Orden de compra</legend>
							<input type="search" placeholder="Código" onChange={e => setFilterCodeOrde(e.target.value)} />
							<input type="date" value={startDateOrde} onChange={(e) => setStartDateOrde(e.target.value)} />
							<input type="date" value={endDateOrde} onChange={(e) => setEndDateOrde(e.target.value)} />
						</fieldset>

						<fieldset>
							<legend>Entrada de mercancía</legend>
							<input type="search" placeholder="Código" onChange={e => setFilterCodeEntr(e.target.value)} />
							<input type="date" value={startDateEntr} onChange={(e) => setStartDateEntr(e.target.value)} />
							<input type="date" value={endDateEntr} onChange={(e) => setEndDateEntr(e.target.value)} />
						</fieldset>

						<fieldset>
							<legend>Salida de mercancía</legend>
							<input type="search" placeholder="Código" onChange={e => setFilterCodeSali(e.target.value)} />
							<input type="date" value={startDateSali} onChange={(e) => setStartDateSali(e.target.value)} />
							<input type="date" value={endDateSali} onChange={(e) => setEndDateSali(e.target.value)} />
						</fieldset>

					</article>
				</aside>
		</form>
		</>
	)
}

export default ListTrac;

