import React, { useState } from 'react';
import { apiurl } from '../../utils/constants';
import { log } from '../../utils/functions';
import { useSelector } from 'react-redux';

function CreateUserDialog({ onUpdateUserList,onClose }) {
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [role, setRole] = useState('-1');
	const [errors, setErrors] = useState({ email: false, name: false });
	const [data, setData] = useState([]);
	const { iden: iuser, mail } = useSelector((state) => state.user);

	const handleCreateUser = async () => {
		const newErrors = {};
		if (!isValidEmail(email))newErrors.email = true;
		if (name === '') newErrors.name = true;
		setErrors(newErrors);

		if (Object.values(newErrors).some(error => error)) {
			return;
		}

		try {
			const response = await fetch(apiurl+'tableINSE', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ 
					table: 'sys__user', 
					fiels: `mail,role,name,avat,dark,back`, 
					vals: `'${email}',${role},'${name}','','0',''`,
					exist: `mail='${email}'`, user:mail
				}),
			});

			if (response.ok) {
				const responseData = await response.json();
				setData(responseData);
				if (responseData[0].error !== 1) {
					log('add', 'user', responseData[0].lid, mail, iuser );
					onClose();
					onUpdateUserList(); 
				}else console.log(responseData[0].msg);
			} else {
				throw new Error('Error en la solicitud POST');
			}
		} catch (error) {
			console.error('Error al crear el usuario:', error);
		}
	};

	const isValidEmail = (email) => {
		const emailRegex = /^[A-Za-z0-9._%+-]+@bailacthor\.com$/;
		return emailRegex.test(email);
	};
	

	return (
		<form>
			<orm-modal>
			<dialog open>
				<h2>Creando usuario</h2>
					<table>
						<tbody>
							<tr>
								<td><label>Email:</label></td>
								<td>
									<input
										type="text"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										className={errors.email || data[0]?.error === 1 ? 'error' : ''}
									/>
								</td>
							</tr>
							<tr>
								<td><label>Nombre:</label></td>
								<td>
									<input
										type="text"
										value={name}
										onChange={(e) => setName(e.target.value)}
										className={errors.name ? 'error' : ''}
									/>
								</td>
							</tr>
							<tr>
								<td><label>Rol:</label></td>
								<td>
									<select value={role} onChange={(e) => setRole(e.target.value)}>
										<option value="-1">Inactivo</option>
										<option value="0">Pendiente</option>
										<option value="1">Usuario</option>
										<option value="2">Reportador</option>
										<option value="3">Admin</option>
									</select>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td colSpan="2">
									<input type="button" value="Cancelar" onClick={onClose} />
									<input type="button" value="Aceptar" onClick={handleCreateUser} />
								</td>
							</tr>
						</tfoot>
					</table>
			</dialog>
			</orm-modal>
		</form>
	);
}

export default CreateUserDialog;