import React, { useEffect,useState } from 'react';
import Select from 'react-select'
import { apiurl } from '../../utils/constants';
import { log } from '../../utils/functions';
import { useSelector } from 'react-redux';


function EditPosiCC ({ onUpdateCCList, onEditCC ,onClose }) {

	const [posi, setPosi] = useState([]);
	const [cc, setCC] = useState('');
	const { mail, iden: iuser } = useSelector((state) => state.user);

	const listPosi = async () => {

		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail ,table: 'sys__posi',order: 'name'})
			});
			
			if (response.ok) {
				const data = await response.json();
				const returnData = data.map((posi) => {
					return { value: posi.iden, label: posi.name };
				});
				setPosi(returnData);
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}

	}

	const handleEditCC = async () => {

		try {
			const response = await fetch(apiurl+'tablePUT', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail ,table: 'req__cent', fields:`${onEditCC.field}='${cc}'`, iden: ` code='${onEditCC.cc}'`})
			});
			
			if (response.ok) {
				const responseData = await response.json();
				if(!responseData.error){
					log('mod', 'cent', onEditCC.cc, mail, iuser, ( onEditCC.field.includes("ord") ? `add request approver ${onEditCC.field}=${cc}` : `edit order approver ${onEditCC.field}=${cc}`));
					onClose();
					onUpdateCCList(); 
				}
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}


	}

	useEffect(() => {
		listPosi();
	}, [])
	
	const customStyles = {
		control: (provided) => ({
			...provided,
			fontWeight: '500',
			fontSize: '16px',
		}),
		option: (provided, state) => ({
			...provided,
			color: state.isSelected ? 'white' : 'black',
			fontWeight: '500',
			fontSize: '16px',
		}),
	};

	return (
		<>
		<form>
			<orm-modal>
			<dialog open>
			<h2>Seleccione un cargo</h2>

				<table>
					<tbody>
						<tr>
							<td>{ <Select
								options={posi}
								onChange={(selectedOption) => setCC(selectedOption.value)}
								value={cc ? posi.find((posi) => posi.value === cc) : posi.find((posi) => posi.label === onEditCC.defa)}
								styles={customStyles}
							/> }</td>
						</tr>
					</tbody>
					<tfoot>
					<tr>
						<td>
							<input type="button" value="Cancelar" onClick={onClose} />
							<input type="button" value="Aceptar" onClick={handleEditCC}/>
						</td>
					</tr>
					</tfoot>
				</table>
			</dialog>
			</orm-modal>
		</form>
		</>
	);

}

export default EditPosiCC;