import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { apiurl } from '../utils/constants';
 
function HomePage({ setSelectedComponent }) {

	const [data, setData] = useState([]);

	const { iden: user, mail } = useSelector((state) => state.user);

	const loadIndicator = async () => {
		try {
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ 
					user: mail, 
					query: ` SELECT 
								(SELECT COUNT(1) FROM req__soli WHERE submitter=${user} AND requested IS NULL AND rejected IS NULL) draft,
								(SELECT COUNT(1) FROM req__soli WHERE submitter=${user} AND approved IS NULL) publ,
								COUNT(*) AS appr
							FROM (
								SELECT 
									(SELECT IF(SUM(ritem.amou * ritem.cost)<=ord1, 'ord1', IF(SUM(ritem.amou * iitem.cost)<=ord2, 'ord2', 'ord3') ) FROM req__tier) AS ord,
									appr.user AS appr,
									soli.approver,
									auth.hasLvl1 AS hasLvl1,
									auth.hasLvl2 AS hasLvl2,
									auth.hasLvl3 AS hasLvl3,
									ord1.user AS ord1,
									ord2.user AS ord2,
									ord3.user AS ord3
								FROM req__soli soli
								LEFT JOIN req__item ritem ON soli.iden = ritem.soli
								LEFT JOIN inv__item iitem ON ritem.item = iitem.code
								LEFT JOIN inv__stor stor ON ritem.item = stor.item AND stor.ware = iitem.ware
								LEFT JOIN sys__user req ON soli.requester = req.iden
								LEFT JOIN sys__user sub ON soli.submitter = sub.iden
								LEFT JOIN req__cent cent ON soli.cent = cent.code
								LEFT JOIN req__appr appr ON soli.iden = appr.soli
								LEFT JOIN req__orde orde ON soli.entry = orde.entr
								LEFT JOIN (
									SELECT soli, MAX(IF(level=1,approver,NULL)) hasLvl1, MAX(IF(level=2,approver,NULL)) hasLvl2, MAX(IF(level=3,approver,NULL)) hasLvl3 FROM req__auth GROUP BY soli
								) auth ON soli.iden=auth.soli
								LEFT JOIN (SELECT user, posi FROM sys__link WHERE user=${user}) AS ord1 ON ord1.posi=cent.ord1
								LEFT JOIN (SELECT user, posi FROM sys__link WHERE user=${user}) AS ord2 ON ord2.posi=cent.ord2
								LEFT JOIN (SELECT user, posi FROM sys__link WHERE user=${user}) AS ord3 ON ord3.posi=cent.ord3
								WHERE soli.requester IS NOT NULL AND clos IS NULL
								GROUP BY soli.iden, soli.cent
							) AS subquery
							WHERE
								(subquery.ord = 'ord1' AND subquery.hasLvl1 IS NULL AND subquery.approver IS NULL AND (subquery.ord1 = ${user} OR subquery.appr = ${user}))
								OR 
								(subquery.ord = 'ord2' AND subquery.hasLvl2 IS NULL AND subquery.approver IS NULL AND subquery.ord2 = ${user})
								OR 
								(subquery.ord = 'ord3' AND subquery.hasLvl3 IS NULL AND subquery.approver IS NULL AND subquery.ord3 = ${user})`	
				})
			});

			if (response.ok) {
				const data = await response.json();
				setData(data[0]);
			}
		
		} catch (error) {
			console.error('Error en loadIndicator:', error);
		}
	}

	useEffect(() => {
		if (mail)loadIndicator();
		// eslint-disable-next-line
	}, [mail]);

	return (
		<form>
			<style jsx="true">{`
				.homepage orm-icon {
					font-size: 100px;
					margin: 20px auto;
				}
				.homepage section h1 {
					background-color: initial;
					text-align: center;
				}
				.homepage h1:before {
					width: 0;
				}
				meter {
					width: 100%;
					height: 20px;
				}
				meter:before {
					content: attr(value) ' / ' attr(max);
					position: absolute;
					z-index: 1;
					padding: 2px;
				}
				meter::-webkit-meter-inner-element{
					grid-template-rows: 0 [line1] 2fr [line2] 0;
				}
				meter::-webkit-meter-bar {
					border: none;
					border-radius: 0;
				}
			`}</style>

			<main className='homepage'>
				<h1>Bienvenido a BailacORM</h1>
				<nav>
					<section onClick={() =>setSelectedComponent({component:'listUser'})}><fieldset>
						<orm-icon></orm-icon>
						<h1>Usuarios</h1>
					</fieldset></section>
					<section onClick={() =>setSelectedComponent({component:'listInve'})}><fieldset>
						<orm-icon></orm-icon>
						<h1>Inventario</h1>
					</fieldset></section>
					<section onClick={() =>setSelectedComponent({component:'listSolp'})}><fieldset>
						<orm-icon></orm-icon>
						<h1>Solicitudes</h1>
					</fieldset></section>
					<section onClick={() =>setSelectedComponent({component:'listTrac'})}><fieldset>
						<orm-icon></orm-icon>
						<h1>Seguimiento</h1>
					</fieldset></section>

					{ /*Solo si el dominio es ormbenja.bailac.net o ormseba.bailac.net se mostrará esta advertencia */
					(window.location.hostname.includes('ormbenja') || window.location.hostname.includes('ormseba')) &&
					<article className='yel'>
						<h2>advertencia</h2>
						<fieldset>
							<p data-icon=''>
								Este es un sitio en desarrollo para hacer pruebas y podría no funcionar como espera.
							</p>
							<p>
								<span>Si desea hacer solicitudes, por favor hágalas en <a href='https://orm.bailac.net'>orm.bailac.net</a>.</span>
							</p>
						</fieldset>
					</article>
					}
				</nav>
			</main>
			<aside>
				<article>
					<h1>Indicadores</h1>
					<fieldset>
						<legend>Mis borradores</legend>
						<footer onClick={() => setSelectedComponent({ component: 'listSolp', filtr: 'draf' })}><h1 className='link'>{data.draft}</h1></footer>
						<legend>Mis solicitudes pendientes</legend>
						<footer onClick={() => setSelectedComponent({ component: 'listSolp', filtr: 'publ' })}><h1 className='link'>{data.publ}</h1></footer>
						<legend>Requieren mi aprobación</legend>
						<footer onClick={() => setSelectedComponent({ component: 'listSolp', filtr: 'appr' })}><h1 className='link'>{data.appr}</h1></footer>
					</fieldset>
				</article>
			</aside>
		</form>
	);
}
 
export default HomePage;