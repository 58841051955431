import React, { useEffect, useState } from 'react';
import Paginator from '../paginator';
import { useDispatch, useSelector } from 'react-redux';
import CreateCCDialog from './creaCC';
import EditPosiCC from './editPosiCC';
import AddSoliPosi from './addSoliPosi';
import { apiurl } from '../../utils/constants';
import { log } from '../../utils/functions';

function ListCC() {

	const [CC, setCC] = useState([]);
	const [soliPosi, setSoliPosi] = useState([]);
	const [tier, setTier] = useState([]);
	const [posi, setPosi] = useState([]);
	
	const [editCC, setEditCC] = useState({cc: null,field: null, defa: null});
	const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
	const [isModDialogOpen, setIsModDialogOpen] = useState(false);
	const [isAddSoliOpen, setIsAddSoliOpen] = useState([false,0]);
	const { role, mail, iden:iuser } = useSelector((state) => state.user);

	const [filterCode, setFilterCode] = useState('');
	const [filterName, setFilterName] = useState('');
	const [filterSP, setFilterSP] = useState('');
	const [filterOC, setFilterOC] = useState('');
	const [filterSOLI, setFilterSOLI] = useState('');
	const [sortedAndFilteredCC, setSortedAndFilteredCC] = useState([]);
	const [paginatedCC, setPaginatedCC] = useState([]);
	const [totalFiltered, setTotalFiltered] = useState(0);

	
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;

	const [order, setOrder] = useState('asc'); // Puede ser 'asc' o 'desc'
	const [orderBy, setOrderBy] = useState('code'); // Puede ser 'name' o 'role'

	const listPosi = async () => {
		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail, table: 'sys__posi',order: 'name'})
			});
			
			if (response.ok) {
				const data = await response.json();
				const returnData = data.map((posi) => {
					return { value: posi.iden, label: posi.name };
				});
				setPosi(returnData);
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}

	const handleTier = async(event, field) => {
		let val;
		val = event.target.textContent.replace(/[^\d-]/g, '');
		
		setTier({ ...tier, [field]: val });

		event.target.textContent = formatCurrency(val);


		try {
			const response = await fetch(apiurl+'updateTiers', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail, table: 'req__tier', fields:`${field}='${val}'`, iden: ` cent IS NULL `})
			});
			
			
			if (response.ok) {
				const responseData = await response.json();
				// log('mod', 'cent', 1, mail, iuser, ( field.includes("ord") ? `add tier SP ${field}=${val}`: `add tier OC ${field}=${val}`));
			}else console.error('Error handleTier');
			
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	};

	const updateCCList = async () => {
		try {
			
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail, table: 'req__cent cent',order: 'code', 
				inner: `LEFT JOIN sys__posi ord1 ON cent.ord1=ord1.iden
						LEFT JOIN sys__posi ord2 ON cent.ord2=ord2.iden
						LEFT JOIN sys__posi ord3 ON cent.ord3=ord3.iden
						LEFT JOIN sys__posi pur1 ON cent.pur1=pur1.iden
						LEFT JOIN sys__posi pur2 ON cent.pur2=pur2.iden  
						LEFT JOIN sys__posi pur3 ON cent.pur3=pur3.iden
						LEFT JOIN sys__posi soli ON soli.iden = (SELECT posi FROM req__assi WHERE cent.code=req__assi.cent AND req__assi.role='soli' LIMIT 1)`,
				field: ` cent.name namecc,ord1.name ord1_name, ord2.name ord2_name, ord3.name ord3_name, pur1.name pur1_name, pur2.name pur2_name, pur3.name pur3_name, soli.name soli_name`})
			});
			
			if (response.ok) {
				const data = await response.json();

				const filteredData = data.map(user => {
					return {
						mail: user.mail,
						name: user.namecc,
						code: user.code.toString(),
						ord1_name: user.ord1_name,
						ord2_name: user.ord2_name,
						ord3_name: user.ord3_name,
						pur1_name: user.pur1_name,
						pur2_name: user.pur2_name,
						pur3_name: user.pur3_name,
						soli_name: user.soli_name
					};
				});

				setCC(filteredData);
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	};

	const updateTierList = async () => {
		try {
			const response = await fetch(apiurl, {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail, table: 'req__tier cent',order: 'ord1'
				})
			});
			
			if (response.ok) {
				const data = await response.json();
				setTier(data[0]);
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	};

	const listSoliPosi = async () => {
		try {
			
			const response = await fetch(apiurl+'queryAux', {
				method: 'POST',
				credentials: 'include',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ user:mail, 
					query:`SELECT assi.cent, posi.iden, posi.name
							FROM req__assi assi 
							INNER JOIN sys__posi posi ON assi.posi=posi.iden
							WHERE role='soli'` })
			});
			
			if (response.ok) {
				const data = await response.json();
				const soliPosiData = {};
				data.forEach(({ cent, iden, name }) => {
					if (!soliPosiData[cent]) {
						soliPosiData[cent] = {};
					}
					soliPosiData[cent][iden] = name;
				});
				setSoliPosi(soliPosiData);
				
			} else {
				console.error('Error al obtener los usuarios');
			}
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}

	useEffect(() => {
		listSoliPosi();
		updateCCList();
		updateTierList();
		listPosi();
	}, []);

	const handleUserClick = (code, field, defa) => {
		if (role === 3) {
			setIsModDialogOpen(true);
			setEditCC({ cc: code, field: field, defa: defa });
		}
	};
	
	const handleSoliDele = async (cent, posi) => {
		if (role === 3) {
			try {
			
				const response = await fetch(apiurl+'queryAux', {
					method: 'POST',
					credentials: 'include',
					headers: {'Content-Type': 'application/json'},
					body: JSON.stringify({ user:mail, query:`DELETE FROM req__assi WHERE role='soli' AND cent=${cent} AND posi=${posi}` }) });
				
				if (response.ok) {
					log( 'del', 'cent', cent, mail, iuser, `del requester ${posi}`);
					listSoliPosi();
				} else {
					console.error('Error al obtener los usuarios');
				}
			} catch (error) {
				console.error('Error en la solicitud:', error);
			}
		}
	};

	const handleRequestSort = (property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const sortComparator = (a, b) => {
		if (order === 'asc') {
			return a[orderBy].localeCompare(b[orderBy]);
		} else {
			return b[orderBy].localeCompare(a[orderBy]);
		}
	};

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-CL', {
			currency: 'CLP',
		}).format(value);
	};

	const handleCEdit = (field) => {
		if (role === 3) {
			const span = document.getElementById(`${field}Span`);
			if (span) {
				span.contentEditable = 'true';
				span.focus();
			}
		}
	};



	useEffect(() => {
		const filteredCC = CC.filter(cc => {
			const CodeMatches = cc.code.toLowerCase().includes(filterCode.toLowerCase());
			const NameMatches = cc.name.toLowerCase().includes(filterName.toLowerCase());

			const soliMatches =
				filterSOLI.trim() === '' || // Si filterSOLI está vacío, no se aplica este filtro
				(
					soliPosi[cc.code] &&
					Object.values(soliPosi[cc.code]).some(value =>
						value.toLowerCase().includes(filterSOLI.toLowerCase())
					)
				);


			const spMatches =
				filterSP.trim() === '' || // Si filterSP está vacío, no se aplica este filtro
				(
					cc.ord1_name &&
					(
						cc.ord1_name.toLowerCase().includes(filterSP.toLowerCase()) ||
						cc.ord2_name?.toLowerCase().includes(filterSP.toLowerCase()) || // Usar el operador de opción segura (nullish operator)
						cc.ord3_name?.toLowerCase().includes(filterSP.toLowerCase())
					)
				);

			const ocMatches =
				filterOC.trim() === '' || // Si filterOC está vacío, no se aplica este filtro
				(
					cc.pur1_name &&
					(
						cc.pur1_name.toLowerCase().includes(filterOC.toLowerCase()) ||
						cc.pur2_name?.toLowerCase().includes(filterOC.toLowerCase()) || // Usar el operador de opción segura (nullish operator)
						cc.pur3_name?.toLowerCase().includes(filterOC.toLowerCase())
					)
				);

			return CodeMatches && NameMatches && soliMatches && spMatches && ocMatches;
		});

		const startIndex = (currentPage - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;

		setPaginatedCC(filteredCC.sort(sortComparator).slice(startIndex, endIndex));
		setSortedAndFilteredCC(filteredCC);

	}, [CC, filterCode, filterName, filterSP, filterOC, filterSOLI, currentPage, order, orderBy])

	useEffect(() => {
		setTotalFiltered(sortedAndFilteredCC.length);
	}, [sortedAndFilteredCC]);

	useEffect(() => {
		setCurrentPage(1);
	}, [filterCode, filterName, filterSP, filterOC, filterSOLI]);
	

	return (
		<>
		<form>
			<main>
				<h1 data-icon=''>Centros de costo</h1>
				<nav>
				<article>
					<table>
						<thead>
							<tr>
							<td onClick={() => handleRequestSort('code')}> Cod {orderBy === 'code' && ( <orm-icon>{order === 'asc' ? '':''}</orm-icon> )} </td>
							<td onClick={() => handleRequestSort('name')}> Nombre {orderBy === 'name' && ( <orm-icon>{order === 'asc' ? '':''}</orm-icon> )} </td>
							<td>Creación SP</td>
							<td>Autorización SP</td>
							<td>Autorización OC</td>
							<td></td>
							</tr>
						</thead>
						<tbody>
							{paginatedCC.map((user) => ( 
								<tr key={user.code} >
									<th>{user.code}</th>
									<td>{user.name}</td>
									<td>{
										soliPosi[user.code] && Object.entries(soliPosi[user.code]).map(([iden, name]) => (
										<div key={`${user.code}-${iden}`} className={role === 3 ? 'deletable' : ''}
										onClick={() => {
										if (window.confirm('¿Está seguro?')) {
											handleSoliDele(user.code, iden);
											}
										}}> {name} </div>
										))}
										<a onClick={() => setIsAddSoliOpen([true,user.code]) }>+ Agregar</a>
									</td>
									<td>
										<div className={ (role===3?"editable":"") } onClick={() => handleUserClick(user.code,'ord1',user.ord1_name)} ><figure className="gre"></figure> {user.ord1_name}</div>
										<div className={ (role===3?"editable":"") } onClick={() => handleUserClick(user.code,'ord2',user.ord2_name)} ><figure className="yel"></figure> {user.ord2_name}</div>
										<div className={ (role===3?"editable":"") } onClick={() => handleUserClick(user.code,'ord3',user.ord3_name)} ><figure className="red"></figure> {user.ord3_name}</div>
									</td>
									<td>
										<div className={ (role===3?"editable":"") } onClick={() => handleUserClick(user.code,'pur1',user.pur1_name)} ><figure className="gre"></figure> {user.pur1_name}</div>
										<div className={ (role===3?"editable":"") } onClick={() => handleUserClick(user.code,'pur2',user.pur2_name)} ><figure className="yel"></figure> {user.pur2_name}</div>
										<div className={ (role===3?"editable":"") } onClick={() => handleUserClick(user.code,'pur3',user.pur3_name)} ><figure className="red"></figure> {user.pur3_name}</div>
									</td>
								</tr>
							))}
						</tbody>
						<thead>
							<tr>
								<th></th>
								<td>Leyenda</td>
								<td></td>
								<td>Autorización SP</td>
								<td>Autorización OC</td>
								<td></td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th></th>
								<td></td>
								<td></td>
								<td>
									<div className={role === 3 ? 'editable' : ''} onClick={() => handleCEdit('ord1')}>
										<figure className="gre"></figure> &lt;
										<span id={`ord1Span`} translate="no" contentEditable={false} onBlur={(event) => handleTier(event, 'ord1')} suppressContentEditableWarning={true}>
											{tier?.ord1?formatCurrency(tier.ord1):''}
										</span>
									</div>

									<div className={ (role===3?"editable":"") }  onClick={() => handleCEdit('ord2')}>
										<figure className='yel'></figure> &lt;
										<span id={`ord2Span`} translate="no" contentEditable={false} onBlur={(event) => handleTier(event, 'ord2')} suppressContentEditableWarning={true}>
											{tier?.ord2?formatCurrency(tier.ord2):''}
										</span>
									</div>
									<div><figure className='red'></figure> &lt;&infin;</div>
								</td>
								<td>
									<div className={ (role===3?"editable":"") }  onClick={() => handleCEdit('pur1')}>
										<figure className='gre'></figure> &lt; 
										<span id={`pur1Span`} translate="no" contentEditable={false} onBlur={(event) => handleTier(event, 'pur1')} suppressContentEditableWarning={true}>
											{tier?.pur1?formatCurrency(tier.pur1):''}
										</span> 
									</div>
									<div className={ (role===3?"editable":"") }  onClick={() => handleCEdit('pur2')}>
										<figure className='yel'></figure> &lt; 
										<span id={`pur2Span`} translate="no" contentEditable={false} onBlur={(event) => handleTier(event, 'pur2')} suppressContentEditableWarning={true}>
											{tier?.pur2?formatCurrency(tier.pur2):''}
										</span> 
									</div>
									<div><figure className='red'></figure> &lt;&infin;</div>
								</td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</article>
				</nav>
				<nav>
						<article><Paginator totalItems={totalFiltered} itemsPerPage={itemsPerPage} currentPage={currentPage} onPageChange={setCurrentPage}/> </article>
				</nav>
			</main>
			<aside>
			{role === 3 && (
				<article>
					<h2>Acciones</h2>
					<fieldset>
						<input type="button" value="Crear centro de costo" onClick={() => setIsCreateDialogOpen(true)}/>
					</fieldset>
				</article>
			)}
				<article>
					<h2>Filtros</h2>
					<fieldset>
						<legend>Identificación</legend>
						<input type="number" placeholder="Código" value={filterCode} onChange={e => setFilterCode(e.target.value)} />
						<input type="text" placeholder="Nombre" value={filterName} onChange={e => setFilterName(e.target.value)} />
					</fieldset>
					<fieldset>
						<legend>Creación</legend>
						<input type="search" placeholder="SP" list="soli" value={ filterSOLI } onChange={e => setFilterSOLI(e.target.value)} />
						<datalist id="soli"> { posi.map((posi) => ( <option key={posi.value} >{posi.label}</option> )) } </datalist>

					</fieldset>
					<fieldset>
						<legend>Autorización</legend>
						
						<input type="search" placeholder="SP" list="sp" value={filterSP} onChange={ e => setFilterSP(e.target.value)} />
						<datalist id="sp"> { posi.map((posi) => ( <option key={posi.value}>{posi.label}</option> )) } </datalist>
						
						<input type="search" placeholder="OC" list="oc" value={filterOC} onChange={e => setFilterOC(e.target.value)} />
						<datalist id="oc"> { posi.map((posi) => ( <option key={posi.value}>{posi.label}</option> )) } </datalist>

					</fieldset>
				</article>
			</aside>
		</form>
		{isCreateDialogOpen && <CreateCCDialog onUpdateCCList={updateCCList} onClose={() => setIsCreateDialogOpen(false)} /> }
		{isModDialogOpen && <EditPosiCC onUpdateCCList={updateCCList} onEditCC={editCC} onClose={() => setIsModDialogOpen(false)} /> }
		{isAddSoliOpen[0] && <AddSoliPosi cent={isAddSoliOpen[1]} onUpdatePosi={listSoliPosi} onClose={() => setIsAddSoliOpen([false,0])} />}
		</>
	);
}



export default ListCC;