// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/IcoMoon-Ultimate.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Roboto-MediumItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Roboto-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Roboto-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/Roboto-LightItalic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{
  font-family: 'IcoMoon-Ultimate';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}
@font-face{
  font-family: 'Roboto_orm';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-style: italic, oblique;
  font-weight: bold;
}
@font-face{
  font-family: 'Roboto_orm';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  font-weight: bold;
}
@font-face{
  font-family: 'Roboto_orm';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
  font-style: italic, oblique;
  font-weight: 500;
}
@font-face{
  font-family: 'Roboto_orm';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
  font-weight: 500;
}
@font-face{
  font-family: 'Roboto_orm';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
  font-style: italic, oblique;
}
@font-face{
  font-family: 'Roboto_orm';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
}`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,+DAA4D;AAC9D;AACA;EACE,yBAAyB;EACzB,+DAA+D;EAC/D,2BAA2B;EAC3B,iBAAiB;AACnB;AACA;EACE,yBAAyB;EACzB,+DAAyD;EACzD,iBAAiB;AACnB;AACA;EACE,yBAAyB;EACzB,+DAAwD;EACxD,2BAA2B;EAC3B,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,+DAA0D;EAC1D,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,+DAA8D;EAC9D,2BAA2B;AAC7B;AACA;EACE,yBAAyB;EACzB,+DAAwD;AAC1D","sourcesContent":["@font-face{\n  font-family: 'IcoMoon-Ultimate';\n  src: url('../fonts/IcoMoon-Ultimate.ttf') format('truetype');\n}\n@font-face{\n  font-family: 'Roboto_orm';\n  src: url('../fonts/Roboto-MediumItalic.ttf') format('truetype');\n  font-style: italic, oblique;\n  font-weight: bold;\n}\n@font-face{\n  font-family: 'Roboto_orm';\n  src: url('../fonts/Roboto-Medium.ttf') format('truetype');\n  font-weight: bold;\n}\n@font-face{\n  font-family: 'Roboto_orm';\n  src: url('../fonts/Roboto-Light.ttf') format('truetype');\n  font-style: italic, oblique;\n  font-weight: 500;\n}\n@font-face{\n  font-family: 'Roboto_orm';\n  src: url('../fonts/Roboto-Regular.ttf') format('truetype');\n  font-weight: 500;\n}\n@font-face{\n  font-family: 'Roboto_orm';\n  src: url('../fonts/Roboto-LightItalic.ttf') format('truetype');\n  font-style: italic, oblique;\n}\n@font-face{\n  font-family: 'Roboto_orm';\n  src: url('../fonts/Roboto-Light.ttf') format('truetype');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
