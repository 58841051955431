import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AWS from 'aws-sdk';
import { apiurl } from '../../utils/constants';
import { log } from '../../utils/functions';

function CreateSolpLink({ solp, onLoadSolpLink, onClose }) {

	const [selectedFile, setSelectedFile] = useState([]);
	const [loadbtn, setLoadbtn] = useState(false);
	const [link, setLink] = useState('');
	const [selectedOption, setSelectedOption] = useState('file');
	const { iden: iuser, mail, sapDB } = useSelector((state) => state.user);

	const handleFileChange = (event) => {

		setSelectedFile(event.target.files[0]);
	};

	const uploadFileToS3 = (selectedFile) => {
		try {

			const formData = new FormData();
			formData.append('file', selectedFile);
			formData.append('solp', solp);
			formData.append('sapDB', sapDB);


			fetch(apiurl + 'uploadFileToS3', {
				method: 'POST',
				credentials: 'include',
				body: formData
			})
				.then(response => response.json())
				.then(data => {
					console.log(data.url);
					handleCreateLink(data.url);
				})
				.catch((error) => {
					console.error('Error:', error);
					setLoadbtn(false);
				});


		} catch (error) {

		}

	};

	const handleCreateLink = (Location) => {
		try {
			fetch(apiurl + 'tableINSE', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					user: mail,
					dbuser: sapDB,
					table: 'req__link',
					fiels: `soli,publ,auth,file`,
					vals: `${solp},now(),${iuser},'${Location}'`
				})
			})
				.then(response => response.json())
				.then(data => {
					sapAttachments2(Location);
				})
				.catch((error) => {
					console.error('Error:', error);
				});
		} catch (error) {
			console.error('Error en la solicitud:', error);
		}
	}

	const validateURL = () => {
		const regexURL = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
		const isValid = regexURL.test(link);
		return isValid;
	};

	const sapAttachments2 = async (Location) => {
		try {

			const response = await fetch(apiurl + 'sapAttachments2', {
				method: 'POST',
				credentials: 'include',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					user: mail,
					sapDB: sapDB,
					solp: solp,
					fileUrl: Location
				})
			});
			if (response.ok) {
				const data = await response.json();
				if (data.error == 0) {
					log('mod', 'soli', solp, mail, iuser, (selectedOption == 'link' ? `add link ${solp}` : `add file ${solp}`));
					onLoadSolpLink(solp);
					onClose();
				}else{
					console.error('Error en sapAttachments2:', data.msg);
				}
			} else {
				console.log(response);
				console.error('Error en sapAttachments2');
			}
		} catch (error) {
			console.error('Error en sapAttachments2:', error);
		}
	}

	const onSubmit = (event) => {
		setLoadbtn(true);
		if (selectedOption == 'file') {
			if(selectedFile)uploadFileToS3(selectedFile);
		} else {
			let vali = validateURL();
			if (vali) {
				handleCreateLink(link);
			} else{ 
				alert('Error en el enlace');
				setLoadbtn(false);
			}
		}
	}




	return (
		<form>
			<orm-modal>
				<dialog open>
					<h2>Agregando adjunto</h2>
					<fieldset>
						<orm-tab>
							<input type="radio" name="atta" id="file" value="file" defaultChecked={selectedOption == 'file' ? true : false} onChange={() => setSelectedOption('file')} />
							<label htmlFor="file">Archivo</label>
							<div>
								<input type="file" onChange={handleFileChange} placeholder={selectedFile.name ? selectedFile.name : "Seleccione un archivo..."} />
							</div>
							<input type="radio" name="atta" id="link" value="link" defaultChecked={selectedOption == 'link' ? true : false} onChange={() => setSelectedOption('link')} />
							<label htmlFor="link">Enlace</label>
							<div>
								<input type="url" value={link} onChange={(e) => setLink(e.target.value)} />
							</div>
						</orm-tab>
					</fieldset>
					<table>
						<tfoot>
							<tr>
								<td></td>
								<td>
									<input type="button" value="Cancelar" onClick={onClose} />
									<input type="button" value="Agregar" onClick={onSubmit} disabled={loadbtn} />
								</td>
							</tr>
						</tfoot>
					</table>
				</dialog>
			</orm-modal>
		</form>
	)

}



export default CreateSolpLink;

